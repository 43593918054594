import React, { useEffect, useRef, useState } from "react";
import { Oval } from "react-loader-spinner";
import ProgressBar from "../../components/progressBar";
import { useDispatch, useSelector } from "react-redux";
import { setFieldValue } from "../../redux/GlobalSlice";
import { useNavigate } from "react-router-dom";
import { dataService } from "../../services/data.services";
import ErrorMessage from "../../components/ErrorMessage";
import AnimatedPage from "../../components/Animation/AnimatedPage";

export default function Address(){

    const claimData=useSelector(state=>state.claimData)
    const [loading,setLoading]=useState(false);
    const [postalError,setPostalError]=useState("");
    const [open, setIsOpen] = useState(false);
    const [addressEror,setAdressError]=useState("");
    const [address,setAddress]=useState([]);
    const [selectedAddress, setSelectedAddress]=useState(claimData.address)
    const dispatch=useDispatch();
    const Navigate=useNavigate();
    const inputRef = useRef(null);
    const addressRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []); 
    /**
     * The function fetchAddress is an asynchronous function that is triggered when a form is
     * submitted, and it retrieves address suggestions based on a postal code input.
     * @returns The function `fetchAddress` does not have a return statement.
     */
    const fetchAddress=async(e)=>{
        e.preventDefault()
        if(open){
            setIsOpen(false);
            return;
        }
        if(claimData.postalCode===""){
            setPostalError("This field is mandatory")
            return;
        }
        if(claimData.postalCode.length<=4){
            setPostalError("Postal code should be between 5 to 8 characters")
            return;
        }
        setLoading(true);
        const response = await dataService.GetAddress(claimData.postalCode);
        setAddress(response.data.suggestions)
        setLoading(false);
        if(response.data.suggestions.length===0){
            setPostalError("Invalid Postal Code")
        }else{
            setIsOpen(true)
        }
    }

    /**
     * The function `continueHandler` checks if the postal code and address are filled out, and if not,
     * displays an error message. Then it logs the `claimData` object and navigates to the
     * '/claim/quotation' page.
     */
    const continueHandler=(e)=>{
        e.preventDefault();
        if(claimData.postalCode===""){
            setPostalError("This field is mandatory")
        }
        else{
            if(selectedAddress===""){
                setAdressError("This field is mandatory")
            }
        }
        if(claimData.postalCode!=="" && selectedAddress!==""){
            dispatch(setFieldValue({ field: 'address', value: selectedAddress }));
            const addressArray=selectedAddress.split(" ")
            const addressStreet=selectedAddress.split(",")

            dispatch(setFieldValue({ field: 'houseNumber', value: addressArray[0] }));
            dispatch(setFieldValue({ field: 'street', value: addressStreet[0] }));
            dispatch(setFieldValue({ field: 'street2', value: addressStreet[1] }));

            Navigate('/claim/quotation')
        }
    }

    /**
     * The changeHandler function checks if the input value matches a regular expression and dispatches
     * an action to update the field value if it is valid.
     */
    const changeHandler=(e)=>{
        const regex = /^[a-zA-Z0-9 ]+$/;
        const { id, value }=e.target;
        console.log(value);
        if(id==="postalCode"){
            setPostalError("")
            setAdressError("")
        }
        if(id==="address"){
            setAdressError("")
            setSelectedAddress(value);
            dispatch(setFieldValue({ field: 'address', value: value }));
        }
        if(value === '' || regex.test(value)){
            dispatch(setFieldValue({ field: id, value: value }));
        }
    }


    return(
        <AnimatedPage>
            <ProgressBar barWidth="w-[90%]" />
            <form className="mx-auto 2xl:w-[75%] xl:w-[66%] lg:w-[90%] md:w-[95%] w-full" onSubmit={continueHandler} 
                onClick={()=>setIsOpen(false)} >
                <p className="2xl:text-[65px] 2xl:leading-[68px] xl:text-[45px]
                 xl:leading-[55px] lg:text-[41px] sm:tracking-[-0.8px]
                    lg:leading-[46px]  md:text-[35px] md:leading-[40px] 
                    sm:text-[36px] text-[36px] leading-[40px]
                     tracking-[-1.8px] new 
                    text-[#163300] font-[900] text-center sm:my-10 my-20">
                    <span className="md:block hidden">NEXT, WHERE DO <br/> YOU LIVE?</span>
                    <span className="md:hidden block">NEXT, WHERE DO YOU LIVE?</span>
                </p>
                <div className="flex justify-center new1 mt-5 md:px-0 sm:px-10 px-4">
                    <div className="md:w-[60%] w-full">
                        <div className="">
                            <input autoComplete="off" ref={inputRef}
                                type="text" name="Name" placeholder="Postal Code" className={`border
                            w-full px-[21px] sm:px-[35px]  py-2 rounded-[6px] md:rounded-[25px] md:text-[25px] text-[17px] 
                            ${postalError!=='' ?  "border-[#984848] " : 'border-gray-300 '}`} onChange={(e)=>changeHandler(e)} 
                                id="postalCode" value={claimData.postalCode}/>
                        </div>
                        {postalError!=="" && <ErrorMessage message={postalError}
                            className="font-normal pt-1 email-err-msg text-[#984848] text-[17px] 
                        leading-normal">
                        </ErrorMessage>}
                        <div className="relative">
                            {/* <input value={selectedAddress} autoComplete="off"
                                type="text" name="Name" placeholder="Postal Code"
                                className={`flex justify-between relative mt-5 border 
                                ${addressEror!=="" ?  "border-[#984848] " : 'border-gray-300 '}
                              ${claimData.address==="" ?"text-[#1633005C]" : "" }
                        w-full px-[21px] sm:px-[35px] py-3 mb-3 rounded-[6px] md:rounded-[25px] text-[17px] md:text-[25px]`} 
                                //onClick={(e)=>{fetchAddress(e), console.log('click')}}
                                onChange={(e)=>changeHandler(e)}
                            >
                                {loading ? <span className="mt-3" ><Oval
                                    height={30}
                                    width={30}
                                    color="#FFFFFF"
                                    wrapperclassName=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#000000"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                /></span> : <img src="/assets/dropDown.svg" alt="drop down icon"
                                    className="md:mt-4 mt-2 md:w-auto w-5" loading="lazy" /> }
                            </input> */}
                            <input autoComplete="off" ref={addressRef}
                                type="text" name="Name" placeholder="Select address" value={selectedAddress} 
                                id='address'
                                className={`flex justify-between relative mt-5 border 
                                ${addressEror!=="" ?  "border-[#984848] " : 'border-gray-300 '}
                              ${selectedAddress==="" ?"text-[#1633005C]" : "" }
                            w-full px-[21px] sm:px-[35px] py-3 mb-3 rounded-[6px] md:rounded-[25px] text-[17px] md:text-[25px]`} 
                                onChange={(e)=>changeHandler(e)} />
                            {loading ? <span className="mt-3 absolute top-2 right-5" ><Oval
                                height={30}
                                width={30}
                                color="#FFFFFF"
                                wrapperclassName=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#000000"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            /></span> : <img src="/assets/dropDown.svg" alt="drop down icon"
                                className="md:mt-4 mt-2 md:w-auto w-5 absolute top-3 right-7 cursor-pointer" loading="lazy" 
                                onClick={(e)=>{fetchAddress(e)}} /> } 
                            {open && (
                                <ul className="absolute border border-gray-200 text-black w-full max-h-[200px] overflow-auto
                            px-[21px] sm:px-[51px] py-3 mb-3  rounded-[6px] sm:rounded-[15px] text-[17px] sm:text-[25px] bg-white 
                            customminiScrollbar">
                                    {address.map((address)=>
                                        <li  key={address} className="cursor-pointer"
                                            onClick={()=>{setSelectedAddress(address.address),
                                            setAdressError(""),
                                            addressRef.current.focus();
                                            setIsOpen(false)}}
                                        >
                                            {address.address}
                                        </li>)}
                                </ul>
                            )}
                           
                     
                        </div>

                        {/* <input type="text" name="Name" placeholder="Postal address" className="border border-gray-200
                        w-full px-[51px] py-3 mb-3 rounded-[6px] sm:rounded-[25px] text-[30px]" 
                        onChange={(e)=>changeHandler(e)} id="address" /> */}

                        {addressEror!=="" && <ErrorMessage message={addressEror}
                            className="font-normal pt-1 email-err-msg text-[#984848] text-[17px] 
                                        leading-normal">
                        </ErrorMessage>}
                        <div className="justify-center gap-2 handleYes  md::mt-[60px] sm:mt-6 mt-4">
                            <button type="button"
                                className="flex items-center justify-center w-full text-center text-black 
                                bg-[#ECECEC] py-2 sm:py-5 mt-7
                                rounded-[6px] md:rounded-[25px] mb-2 px-10 leading-2  text-[20px] sm:text-[27px] font-bold"
                                onClick={(e)=>{e.preventDefault(); Navigate('/claim/date-of-birth')}} >
                                <span><img src="/assets/leftButton.svg" 
                                    className="mr-[14px]" alt="right arrow" loading="lazy" /></span>
                                Previous
                            </button>
                            <div className="block mx-auto text-center text-sm font-normal text-[#0000006E] sm:hidden">
                                You won&apos;t lose your information
                            </div>

                            <button type="submit"
                                className="flex items-center justify-center w-full text-center text-black 
                            bg-[#9FE870] py-2 sm:py-5 mt-2
                            rounded-[6px] md:rounded-[25px] mb-2 px-10 leading-2  text-[20px] sm:text-[27px] font-bold"
                                onClick={(e)=>continueHandler(e)}>
                                Continue
                                <span><img src="/assets/right-arrow.svg" 
                                    className="ml-[14px]" loading="lazy" alt="right arrow" /></span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </AnimatedPage>
    );
}