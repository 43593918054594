import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Element } from 'react-scroll';
import AnimatedButton from '../../components/Animation/AnimatedButton';
import AnimatedDiv from '../../components/Animation/AnimationDiv';
import { useDispatch, useSelector } from "react-redux";
import { setFieldValue } from "../../redux/GlobalSlice";

function Estimate() {
    const dispatch=useDispatch();
    const Navigate=useNavigate();
    const claimData=useSelector((state)=>state.claimData);



    const vehicleCheckStartHandler=async(e)=>{
        e.preventDefault();
        
        const searchParams = new URLSearchParams(window.location.search);

       
        dispatch(setFieldValue({ field: 'campaignSource', value: {
            utm_source: searchParams.get('utm_source'),
            utm_medium: searchParams.get('utm_medium') ,
            utm_campaign: searchParams.get('utm_campaign') ,
            utm_term: searchParams.get('utm_term'),
            utm_content: searchParams.get('utm_content'),
        } }))

        Navigate('/claim',{state:{isNavigated:true}});
    }

    //fade in and out
    return (
        <div className=''>
            <AnimatedDiv className='flex lg:flex-row flex-col text-left 2xl:p-[120px]
            xl:p-[80px] md:p-[50px] sm:p-10 p-6 bg-[#FFFFFF] w-full fade fadeOut'>
                <div className='lg:w-[45%] w-full mr-2 fade fadeOut'>
                    <p className='xl:text-[50px] xl:leading-[60px] lg:text-[41px] lg:leading-[46px] 
                    md:text-[35px] md:leading-[40px] sm:text-2xl text-xl font-[900] uppercase text-[#163300]
                     md:tracking-[-0.9px] lg:tracking-[-0.8px] tracking-[-0.5px]'>
                        We estimate over 40 Million PEOPLE have  been affected.</p>
                    <p className='xl:text-2xl lg:text-xl lg:w-[85%]
                    sm:text-xl text-base font-medium w-auto lg:pt-[65px] pt-5 text-[#163300] new1'>
                        We specialise in helping people with vehicle claims from Claimeo issues to diesel 
                        emission &quot;cheat&quot; devices and we’re with you every step of the way.</p>
                </div>
                <div className='sm:w-[50%] lg:pt-0 sm:pt-10 pt-5 2xl:pl-5 mx-auto fade fadeOut'>
                    <img src="/assets/img2.png" className='rounded-2xl w-fit h-full mx-auto'  
                        alt="road with vehicles" loading="lazy" /> 
                </div> 
            </AnimatedDiv>
            {/* heading */}
            <div className='2xl:px-[120px] xl:px-[80px] md:px-[50px] sm:px-10 px-6 2xl:pb-[120px] 
            xl:pb-[80px] md:pb-[50px] sm:pb-10 pb-6 fade fadeOut'>
                <p className='xl:text-[55px] xl:leading-[60px] lg:text-[48px] lg:leading-[56px] 
                 md:text-[35px] md:leading-[40px] text-2xl text-[#163300] text-center uppercase font-[900] sm:mb-0 mb-2 '>
                    <span className='lg:block hidden lg:tracking-[-0.8px] '>why choose <br />claimeo</span>
                    <span className='lg:hidden block'>why choose claimeo</span>
                </p>
                <div className='xl:mt-[70px] lg:mt-9 md:mt-6 sm:mt-5 lg:flex justify-between items-center xl:gap-x-7 lg:gap-x-5'>
                    <div className='flex flex-col justify-center item-center'>
                        <div className='flex justify-center'>
                            <img src="/assets/Group 16.png" className='lg:w-[110px] w-[80px]' alt="H image" loading="lazy" />
                        </div>
                        <p className='xl:text-[28px] xl:leading-[34px] lg:text-2xl md:text-xl text-xl xl:mt-5 md:mt-4 mt-4
                         text-[#000] font-extrabold text-center'>Check Any Vehicle</p>
                        <p className='lg:text-2xl sm:text-xl text-base text-[#000] font-normal text-center new1
                         lg:mb-0 md:mb-5 sm:mb-4 mb-3 lg:mt-3 sm:mt-1.5 mt-0'>
                            Just enter registration and we&apos;ll find and check your vehicle.</p>
                    </div>
                    <div className='mx-3 flex flex-col justify-center item-center text-center'>
                        <div className='flex justify-center'>
                            <img src="/assets/Group 17.png" className='lg:w-[110px] w-[80px]' alt="tick mark" loading="lazy" />
                        </div>
                        <p className='xl:text-[28px] lg:text-2xl xl:leading-[34px] md:text-xl text-xl xl:mt-5 md:mt-4 mt-4
                         text-[#000] font-extrabold'>100% No Win, No Fee</p>
                        <p className='lg:text-2xl sm:text-xl text-base text-[#000] font-normal new1
                        lg:mb-0 md:mb-5 sm:mb-4 mb-3 lg:mt-3 sm:mt-1.5 mt-0'>
                            We&apos;re 100% upfront about costs, so you&apos;ll find no hidden charges.</p>
                    </div>
                    <div className=' flex flex-col justify-center item-center text-center'>
                        <div className='flex justify-center'>
                            <img src="/assets/Group 18.png" className='lg:w-[110px] w-[80px]' alt="img" loading="lazy" />
                        </div>
                        <p className='xl:text-[28px] lg:text-2xl xl:leading-[34px] md:text-xl text-xl xl:mt-5 md:mt-4 mt-4
                         text-[#000] font-extrabold'>Decision Online</p>
                        <p className='lg:text-2xl sm:text-xl text-base text-[#000] font-normal new1
                        lg:mb-0 md:mb-5 sm:mb-4 mb-3 lg:mt-3 sm:mt-1.5 mt-0'>
                            Our unique system checks your vehicle in under 30 seconds.</p>
                    </div>

                </div>
            </div>
            {/* issues content */}
            <Element name="issues" className="element">
                <AnimatedDiv className='2xl:px-[120px] 2xl:pt-[120px] 2xl:pb-[80px] xl:px-[80px] xl:pt-[80px] xl:pb-[60px] 
                    md:px-[50px] md:pt-[50px] md:pb-[30px] sm:px-10 sm:pt-10 sm:pb-5 p-3 bg-[#00330B]'>
                    <p className='2xl:pt-6 xl:text-[50px] xl:leading-[60px] lg:text-[44px] lg:leading-[50px] 
                 md:text-[30px] md:leading-[40px] text-2xl text-[#fff] text-center uppercase font-[900] 
                 sm:mb-0 mb-2 md:pt-0 pt-3'>
                        What issues can I claim for?</p>
                    <p className='lg:text-[24px] lg:leading-[30px] md:text-base text-sm font-normal
                     text-[#fff] text-center 2xl:w-[55%] xl:w-[65%] lg:w-[75%] md:w-[85%] w-full mx-auto md:pt-1 pt-0.5 new1'>
                        If you answer yes to any of the below, you may have a claim up to £16,000</p>
                    <div className='flex flex-wrap w-full xl:mt-[74px] lg:mt-[40px] md:mt-6 mt-4 justify-center 
                    items-center h-full'>
                        <div className='2xl:h-[320px] xl:h-[430px] lg:h-[370px] lg:w-[30%] lg:mb-0 mb-3 w-full 
                        bg-opacity-2 lg:mx-[12px] fade fadeOut
                         bg-[#294930] md:p-[36px] sm:p-[30px] p-7 rounded hover:bg-opacity-5 hover:border hover:border-[#91E6B3]'>
                            <img src="/assets/img4.svg" className='pl-4' alt="setting-icon" loading="lazy" />
                            <p className='lg:text-2xl  md:text-xl text-xl
                            lg:mt-3 mt-2.5 text-left font-bold text-[#fff]'>Reduced Engine
                                Performance</p>
                            <p className='xl:text-lg text-base text-left font-normal text-[#fff] lg:mt-2.5 mt-1.5 new1'>
                                A blocked DPF can cause the engine to lose
                                power. You might notice that the vehicle
                                doesn&apos;t accelerate as quickly as it used to,
                                or it might feel sluggish and unresponsive.</p>
                        </div>
                        <div className='2xl:h-[320px] xl:h-[430px] lg:h-[370px] lg:w-[30%] lg:mb-0 mb-3 w-full bg-opacity-2 
                        lg:mx-[12px] bg-[#294930] md:p-[36px] fade fadeOut
                         sm:p-[30px] p-7 rounded  hover:bg-opacity-5 hover:border hover:border-[#91E6B3]'>
                            <img src="/assets/img5.svg" className='pl-4' alt="setting-icon" loading="lazy" />
                            <p className='lg:text-2xl  md:text-xl text-xl
                            lg:mt-3 mt-2.5 text-left font-bold text-[#fff]'>Poor Fuel Efficiency</p>
                            <p className='xl:text-lg text-base text-left font-normal text-[#fff] lg:mt-2.5 mt-1.5 new1'>
                                If the DPF is not working correctly, the
                                engine may have to work harder, which can
                                lead to increased fuel consumption.</p>
                        </div>
                        <div className='2xl:h-[320px] xl:h-[430px] lg:h-[370px] lg:w-[30%] lg:mb-0 mb-3 w-full bg-opacity-2
                         lg:mx-[12px] bg-[#294930] md:p-[36px] fade fadeOut
                          sm:p-[30px] p-7 rounded  hover:bg-opacity-5 hover:border hover:border-[#91E6B3]'>
                            <img src="/assets/img8.svg" className='pl-4' alt="setting-icon" loading="lazy" />
                            <p className='lg:text-2xl  md:text-xl text-xl
                            lg:mt-3 mt-2.5 text-left font-bold text-[#fff]'>Warning Lights</p>
                            <p className='xl:text-lg text-base text-left font-normal text-[#fff] lg:mt-2.5 mt-1.5 new1'>
                                Most modern vehicles have a warning light
                                on the dashboard that will illuminate if
                                there&apos;s a problem with the DPF. This is often
                                the first sign drivers notice when there&apos;s an
                                issue.</p>
                        </div>
                        <div className='2xl:h-[320px] xl:h-[430px] lg:h-[490px] lg:w-[30%] lg:mb-0 mb-3 w-full bg-opacity-2
                         lg:mx-[12px] bg-[#294930] md:p-[36px] sm:p-[30px] p-7 lg:mt-[28px] rounded
                          hover:bg-opacity-5 hover:border hover:border-[#91E6B3] fade fadeOut'>
                            <img src="/assets/img7.svg" className='pl-4' alt="setting-icon" loading="lazy" />
                            <p className='lg:text-2xl  md:text-xl text-xl
                            lg:mt-3 mt-2.5 text-left font-bold text-[#fff]'>Frequent Regeneration</p>
                            <p className='xl:text-lg text-base text-left font-normal text-[#fff] lg:mt-2.5 mt-1.5 new1'>
                                If the DPF is faulty, the vehicle might attempt
                                to regenerate the filter more frequently than
                                normal. This can lead to increased fuel
                                consumption and can cause the vehicle to
                                behave differently during these periods.</p>
                        </div>
                        <div className='2xl:h-[320px] xl:h-[430px] lg:h-[490px] lg:w-[30%] lg:mb-0 mb-3 w-full bg-opacity-2 
                        lg:mx-[12px] bg-[#294930] md:p-[36px] sm:p-[30px] p-7 lg:mt-[28px] rounded
                            hover:bg-opacity-5 hover:border hover:border-[#91E6B3] fade fadeOut'>
                            <img src="/assets/img8.svg" className='pl-4' alt="setting-icon" loading="lazy"/>
                            <p className='lg:text-2xl  md:text-xl text-xl
                            lg:mt-3 mt-2.5 text-left font-bold text-[#fff]'>Engine Damage</p>
                            <p className='xl:text-lg text-base text-left font-normal text-[#fff] lg:mt-2.5 mt-1.5 new1'>
                                In severe cases, a blocked DPF can cause
                                back pressure in the exhaust system, which
                                can lead to engine damage. This can be a
                                very costly problem to fix.</p>
                        </div>
                        <div className='2xl:h-[320px] xl:h-[430px] lg:h-[490px] relative lg:w-[30%] lg:mb-0 mb-3
                         w-full bg-opacity-2 lg:mx-[12px] bg-[#294930] md:p-[36px] sm:p-[30px] p-7 lg:mt-[28px] rounded
                            hover:bg-opacity-5 hover:border hover:border-[#91E6B3] fade fadeOut'>
                            <button className='bg-[#9FE870] text-[13px] absolute top-0 right-0 rounded-xl 
                    text-[#0D3D3F] px-[13px] py-[6px] m-4'>Most common</button>
                            <img src="/assets/img9.svg" className='pl-4' alt="setting-icon" loading="lazy" />
                            <p className='lg:text-2xl  md:text-xl text-xl
                            lg:mt-3 mt-2.5 text-left font-bold text-[#fff]'>Forced into &apos;Limp Mode&apos;</p>
                            <p className='xl:text-lg text-base text-left font-normal text-[#fff] lg:mt-2.5 mt-1.5 new1'>
                                Some vehicles will go into a safety mode
                                known as &apos;limp mode&apos; if the DPF issue is
                                severe. This mode limits the performance of
                                the vehicle to prevent further damage,
                                allowing you to drive to a mechanic or
                                service center.</p>
                        </div>
            
                    </div>
                    <AnimatedDiv className='flex justify-center xl:pb-3 lg:pb-2 pb-1
                     items-center flex-col lg:mt-[80px] md:mt-[53px] sm:mt-8 mt-5'>
                        <AnimatedButton handleClick={(e) => vehicleCheckStartHandler(e)} buttonName="Start Your Claim"
                            className='bg-[#9FE870] rounded-[40px] hover:bg-[#aafc74] new1
                         lg:text-[23px] lg:leading-8 sm:text-xl text-base font-bold text-[#0D3D3F] lg:w-[350px] 
                         md:w-auto px-[32px] py-2.5'
                        >
                        </AnimatedButton>
                        <p className='text-[17px] pt-3 new1 text-left font-semibold text-[#fff]'>
                            (Only takes 30 seconds to check)</p>
                    </AnimatedDiv>
                </AnimatedDiv>
            </Element>
        </div>
    )
}

export default Estimate;