/* eslint-disable security/detect-object-injection */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFieldValue } from "../../redux/GlobalSlice";
import ProgressBar from "../../components/progressBar";
import AnimatedPage from "../../components/Animation/AnimatedPage";
import ErrorMessage from "../../components/ErrorMessage";
import { isValid, parseISO } from 'date-fns';
import moment from 'moment';
import { enUS } from 'date-fns/locale'; 

export default function DateOfBirth(){
    const claimData=useSelector(state=>state.claimData);
    const [dob, setDob] = useState(claimData.dateOfBirth);
    const [date,setDate]=useState(dob.split("-")[2]===undefined ? "" : dob.split("-")[2]);
    const [month,setMonth]=useState(dob.split("-")[1]===undefined ? "" : dob.split("-")[1]);
    const [year,setYear]=useState(dob.split("-")[0]===undefined ? "" : dob.split("-")[0]);
    const [dobError,setDobError]=useState("");
    const [startDate, setStartDate] = useState(null);
    
    const Navigate=useNavigate();
    const dispatch=useDispatch();

    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    /**
     * The function `inputfocus` handles keyboard events and focuses on the next input element based on
     * certain conditions.
     * @returns nothing (undefined) in most cases. However, if the condition
     * `if(elmnt.target.value.length!==0)` is true, then the function will return immediately without
     * executing the rest of the code.
     */
    const inputfocus = (elmnt) => {
        if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
            if(elmnt.target.value.length!==0){
                return;
            }
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus();
            }
        } else {
            if(elmnt.target.id==="digit-1" || elmnt.target.id==="digit-2"){
                if(elmnt.target.value.length<2){
                    return;
                }
            }if(elmnt.target.id==="digit-3" && elmnt.target.value.length<4){
                return;
            }
            const next = elmnt.target.tabIndex;
            if (next < 3) {
                elmnt?.target?.form?.elements[next]?.focus();
            }
        }
    };

    /**
     * The changeHandler function is used to handle changes in input fields and validate the date of
     * birth input.
     */
    const changeHandler=(e)=>{
        let { id, value }=e.target;
        setDobError("");
        value = value.replace(/[A-Za-z\s]/g, "");
        const today=new Date();
        if(id==="digit-2"){
            if(value>12){
                setMonth("12")
            }else{
                setMonth(value)
            }
        }
        if(id==="digit-1"){
            if(value>31){
                setDate("31")
            }else{
                setDate(value)
            }
        }
        if(id==="digit-3"){
            if(value>today.getFullYear()){
                setYear(today.getFullYear())
            }else{
                setYear(value);
            }
        }
    }

    const handleDateChange = (e) => {
        // Get the input value
        setDobError("");
        let value = e.target.value
        if(value.length>10){
            return;
        }
        setDob(value);
        const enteredDateObject = new Date(value);
        const today=new Date();
        if(enteredDateObject.getFullYear()>today.getFullYear()){
            const date=value.split('-');
            dispatch(setFieldValue({ field: "dateOfBirth", value: `${today.getFullYear()}-${date[1]}-${date[2]}` }))
        }else{
            dispatch(setFieldValue({ field: "dateOfBirth", value: value }))
        }
        setStartDate(value)
    
    };

    /**
     * The continueHandler function checks if the entered date of birth is valid and navigates to the
     * next step if it is.
     * @returns In the code snippet provided, the `continueHandler` function is not explicitly
     * returning anything.
     */
    const continueHandler=(e)=>{
        e.preventDefault();
        const enteredDateObject = new Date(month+"/"+date+"/"+year);
        const today=new Date();
        const dateStr = `${month.toString().padStart(2, '0')}/${date.toString().padStart(2, '0')}/${year}`;
        //const parsedDate = parseISO(dateStr, { locale: 'en-US' });
        const dateValid = moment(dateStr, 'MM/DD/YYYY', true);
        //const parsedDate = moment(inputDate, 'YYYY-MM-DD', true);

        if(date==="" && month==="" && year===""){
            setDobError("This field is mandatory");
            return;
        }else if(!dateValid.isValid()){
            setDobError("Invalid Date Of Birth");
        }else if(dateValid>=today || year<=1900){
            setDobError("Invalid Date Of Birth");
        }else if(dobError===""){
            dispatch(setFieldValue({ field: "dateOfBirth", value: year+"-"+month+"-"+date }))
            Navigate('/claim/address')
        }
        //Navigate('/claim/address')
    }

    return (
        <AnimatedPage>
            <form className="" onSubmit={continueHandler} >
                <ProgressBar barWidth="w-[90%]" />
                <div className="mx-auto md:px-16 sm:px-10 px-4 md:pt-10 2xl:w-[65%] xl:w-[51%] w-full">
                    <p className="2xl:text-[60px] 2xl:leading-[65px] xl:text-[45px] xl:leading-[55px] lg:text-[41px] new
                    lg:leading-[46px]  md:text-[35px] md:leading-[40px] sm:text-[36px] text-[36px] leading-[36px] md:tracking-[-0.8px]
                    text-[#163300] font-[900] text-center my-20 sm:my-5">OK, <span className="uppercase" >{claimData.firstName}</span> WHAT IS YOUR DATE OF BIRTH?</p> 
                </div>
                <div className="flex justify-center new1 md:px-0 px-4 mx-auto 2xl:w-[75%] xl:w-[80%] lg:w-[90%] md:w-[95%] w-full">
                    <div className="w-full mt-5">
                        <div className="md:w-[55%] w-auto mx-auto">
                            <div className="mt-4">
                                <div className="w-[280px] mx-auto">
                                    <div className={`flex relative mt-[-1.7rem] sm:mt-8 sm:mb-0 lg:border-3 border  mx-auto
                                        border-[#163300] rounded-[6px] md:rounded-[25px] ${dobError!=='' ?  "border-[#984848] " : 'border-[#163300] '}`}>
                                        <label  className="font-bold lg:text-[24px] md:text-[22px] text-xs text-[#224F00] 
                                    absolute md:-top-[7px] top-[-6px] md:left-[63.87px] left-[22px] bg-[#FFFFFF] px-1 z-[9] new1">
                                            Date Of Birth <span className="text-[#F72C1D]"></span></label>
                                        {/* <input
                                        type="date"
                                        id="birthdate"
                                        name="birthdate"
                                        className={`w-full px-[27px] md:px-[51px] text-[23px] py-3 leading-tight mb-3
                                        rounded-[6px] md:rounded-[25px] focus:outline-non lg:text-[35px] md:text-[35px] lg:border-3 border
                                            border-[#163300] ${dobError!=='' ?  "border-[#984848] " : 'border-[#163300] '} `}
                                        value={claimData.dateOfBirth}
                                        placeholder="DD/MM/YYYY"
                                        onChange={(e)=>{handleDateChange(e)}}/> */}
                                        <label htmlFor="digit-1" className="text-[23px] pl-[22px] sm:pl-[50px]" >
                                            <input type="text" placeholder="DD" autoComplete="off" inputMode="decimal"
                                                onKeyUp={(e) => inputfocus(e)}
                                                ref={inputRef}
                                                style={{border: "none"}}
                                                name="digit-1"
                                                id="digit-1"
                                                data-next="digit-2"
                                                tabIndex="1"
                                                onChange={(e)=>changeHandler(e)} value={date} maxLength="2"
                                                className="w-10 text-[23px] py-3 leading-tight mr-2"
                                            //onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ''); }} 
                                            />/</label>
                                        <label htmlFor="digit-2" className="text-[23px] sm:px-2" >
                                            <input type="text" placeholder="MM" autoComplete="off" inputMode="decimal"
                                                onKeyUp={(e) => inputfocus(e)}
                                                id="digit-2"
                                                style={{border: "none"}}
                                                data-next="digit-3"
                                                data-previous="digit-1"
                                                tabIndex="2"
                                                className="w-10 text-[23px] py-3 leading-tight"
                                                onChange={(e)=>changeHandler(e)} value={month} maxLength="2" 
                                            />/</label>
                                        <label htmlFor="digit-3" />
                                        <input type="text" placeholder="YYYY" autoComplete="off" inputMode="decimal"
                                            onKeyUp={(e) => inputfocus(e)}
                                            id="digit-3"
                                            style={{border: "none"}}
                                            //data-next="digit-4"
                                            data-previous="digit-2"
                                            tabIndex="3"
                                            className="w-20 text-[23px] py-3 leading-tight"
                                            onChange={(e)=>changeHandler(e)} value={year} maxLength="4" />
                                    </div>
                                    {dobError!=='' && <ErrorMessage message={dobError}
                                        className="font-normal pt-[10px] email-err-msg text-[#984848] text-[17px] text-left
                                        leading-normal">
                                    </ErrorMessage>}
                                </div>
                            
                                <div className="justify-center new1 gap-2 handleYes  md::mt-[60px] sm:mt-6 mt-20" >
                        
                                    <button type='button'
                                        className="flex items-center justify-center w-full text-center text-black 
                                        bg-[#ECECEC] py-2 sm:py-5 mt-7 rounded-[6px] md:rounded-[25px] mb-2 px-10 leading-2  text-[20px] sm:text-[27px] font-bold"
                                        onClick={(e)=>{ e.preventDefault(); Navigate('/claim/person-details')}}  >
                                        <span><img src="/assets/leftButton.svg" className="mr-[14px]" alt="right arrow" loading="lazy" /></span>
                                        Previous
                                    </button>
                                    <div className="block mx-auto text-center text-sm font-normal text-[#0000006E] sm:hidden">
                                        You won&apos;t lose your information
                                    </div>
                                    <button type="submit"
                                        className="flex items-center justify-center w-full text-center text-black 
                                        bg-[#9FE870] py-2 sm:py-5 mt-2
                                        rounded-[6px] md:rounded-[25px] mb-2 px-10 leading-2  text-[20px] sm:text-[27px] font-bold"
                                    >
                                        Continue
                                        <span><img src="/assets/right-arrow.svg" className="ml-[14px]" alt="right arrow" loading="lazy" /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </AnimatedPage>
    );
}
