import React from "react";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "../../components/Animation/AnimatedPage";
import { useDispatch, useSelector } from "react-redux";
import { clearData } from "../../redux/GlobalSlice";
import { dataService } from "../../services/data.services";

export default function Success(){

    const Navigate=useNavigate();
    const dispatch=useDispatch();
    const claimData=useSelector(state=>state.claimData);
    
    const finalSubmitHandler=async(e)=>{
        e.preventDefault();
        const body= 
            {
                "clientReference": claimData.clientReference,
                "title": claimData.title,
                "app": import.meta.env.VITE_APP_NAME,
                "firstName": claimData.firstName,
                "middleName": null,
                "response": claimData.response,
                "lastName": claimData.lastName,
                "mobile": claimData.phoneNumber,
                "vehicleType": claimData.vehicle,
                "email": claimData.emailAddress,
                "dateOfBirth": claimData.dateOfBirth,
                "currentAddress": {
                    "flat": "",
                    "houseName": "",
                    "houseNumber": claimData.houseNumber,
                    "street": claimData.street,
                    "street2": claimData.street2,
                    "district": "",
                    "postTown": "",
                    "county": "",
                    "postCode": claimData.postalCode,
                },

                "campaign_source": claimData.campaignSource,
                "previousAddress": null,
                "previousPreviousAddress": null,
                "creditCheckJson": claimData.credit,
                "claimType": "pcp",
                "signature": claimData.signatureUrl,
                "vehicleInformation": claimData.lenderInfo,
                "proofOfIdentification":claimData.proofOfIdentification,
            }

        const response = await dataService.SubmitInformation(body);
        Navigate("/claim/completion")

       
        
    }


    /**
     * The submitHandler function prevents the default form submission behavior and navigates to the
     * specified route.
     */
    const submitHandler=(e)=>{
        e.preventDefault();
        console.log(claimData);
        dispatch(clearData());
        Navigate('/claim/completion')
    }

    return(
        <AnimatedPage>
            <div className="max-h-screen min-h-screen overflow-auto md:pb-10 sm:pb-8 py-4 md:px-14 sm:px-10 px-4 bg-[#9FE87054]">
                <div className="flex justify-center flex-col">
                    <div className="flex justify-center">
                        <img src="/assets/logo.png" alt="Claeimo logo" 
                            className="mx-auto h-[25px] md:mt-2" loading="lazy" />
                    </div>
                    <div className="md:mt-10 mt-5">
                        <div className="text-center xl:text-[85px] xl:leading-[87px] lg:text-[48px] lg:leading-[53px] 
                        md:text-[35px] md:leading-[40px] tracking-[-1.8px]  sm:tracking-[-0.8px] 
                         sm:text-2xl text-[27px] text-[#163300] font-[900] uppercase new " >
                         
                            GREAT NEWS!
                        </div>
                        <div className="w-full mx-auto 2xl:w-[50%] xl:w-[66%] new1 lg:w-[90%] md:w-[95%]">
                            <p className="mt-5 text-center leading-[25.1px] sm:leading-[44px] sm:px-0 px-[40px]  md:text-[35px] 
                        text-[18px] font-medium">
                                Your proof of identification is submitted successfully.

                            </p>
                        </div>
                   
                    </div>
                    <div className=" md:mb-[45px] mb-6 flex justify-center mt-9">
                
                        <img src="/assets/star.png" className="md:w-[160px] sm:w-[130px] w-[90px] md:h-[160px] 
                        sm:h-[130px] h-[90px]" alt="Star image" loading="lazy" />
            
                    </div>
                    <div className="xl:w-[60%] md:w-[80%] mx-auto w-full new1">
                        {/* <button 
                            className="flex justify-center items-center w-full text-center text-[#FFF] bg-[#163300] md:py-3 py-2.5
                        rounded-[6px] sm:rounded-[25px] mb-2 px-10 leading-2 text-[22px] font-bold" 
                            onClick={(e)=>submitHandler(e)}>
                            Next
                        </button> */}
                        <button 
                            className="flex items-center justify-center w-full text-center text-[#FFF] 
                            bg-[#163300] py-2 sm:py-5 mt-2
                            rounded-[6px] md:rounded-[25px] mb-2 px-10 leading-2  text-[20px] sm:text-[27px] font-bold "
                            onClick={(e)=>finalSubmitHandler(e)}>
                            Next
                            <span><img src="/assets/whiteArrow.svg" 
                                className="ml-[14px]" loading="lazy" alt="right arrow" /></span>
                        </button>
                    </div>
                    <div className="flex justify-center pt-[24px] sm:pt-[25px] pb-[103px]">
                        <img src="/assets/trustpilot.png" alt="trustPilot logo" loading="lazy" />
                    </div>
                </div>
            </div>
        </AnimatedPage>
    );
}
