import React from "react"
import { useNavigate } from "react-router-dom"

export default function NoLenders(){

    const Navigate=useNavigate();

    const continueHandler=(e)=>{
        e.preventDefault();
        Navigate('/claim/other-lenders');
    }

    return(
        <div className="" >
            <div className=" md:px-[30%] px-[40px]  sm:px-[30%]   lg:px-[20%] ">
                <p className="xl:text-[70px] xl:leading-[75px] lg:text-[60px] sm:flex flex-col hidden
                    lg:leading-[52px]  md:text-[50px] sm:tracking-[-0.80px]  md:leading-[55px] sm:text-[46px] 
                    sm:leading-[42px]  new 
                    text-[#163300] font-[900] uppercase text-center mt-[60px]">
                    <span>
                        SORRY, WE HAVE <br/> BEEN UNABLE TO FIND <br/> ANY LENDERS
                    </span>
                </p> 
                <p className="text-[36px] font-[800] leading-[38px] tracking-[-1.8px]  new
                flex flex-col uppercase text-center sm:hidden mt-10  ">
                       
                    <span className="sm:hidden block">  SORRY,</span>
                    <span> WE HAVE  BEEN</span>
                    <span>
                        UNABLE TO FIND ANY LENDERS
                    </span>
                </p>
        
          
                <div className="flex justify-center  mt-12 mb-[80px]">
                    <img src="/assets/Exclaimatary.svg" className="w-[150px] sm:w-[200px]" alt="Exclaimatory mark" />
                </div>

                <div className="flex justify-center new1 md:mt-10 sm:mt-8 mt-4">
                    <button type="submit"signature
                        className=" sticky  flex items-center justify-center w-full
                        sm:text-[27px] text-xs1
                    font-bold sm:h-[67px] h-[40px] text-center text-[#163300] bg-[#9FE870] 
                    rounded-[6px] lg:rounded-[25px] mb-2 px-10 leading-2" data-testid="continue-btn" 
                        onClick={(e)=>continueHandler(e)}>
                        Add Manually
                        <span>
                            <img src="/assets/right-arrow.svg" 
                                className="ml-[14px] w-[20px] sm:w-[30px]" alt="right arrow" /></span>
                    </button>
                </div>
            </div>
        </div>
    )
}