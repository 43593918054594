import React, { useState } from "react"
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { Link, Element } from 'react-scroll';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AnimatedButton from "../../components/Animation/AnimatedButton";
import { setFieldValue } from "../../redux/GlobalSlice";

export default function Header(){

    const [isOpen,setIsOpen]=useState(false);
    const Navigate= useNavigate()
    const dispatch= useDispatch()

    window.onscroll = function() {
        scrollFunction();
    };
      
    function scrollFunction() {
        var header = document.getElementById("header");
        
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {          
            // Check screen width and set padding accordingly
            if (window.innerWidth < 576) { // Extra small screens
                header.style.padding = "10px 16px";
            } else if (window.innerWidth < 768) { // Small screens
                header.style.padding = "10px 24px";
            } else if (window.innerWidth < 992) { // Medium screens
                header.style.padding = "12px 24px";
            } else if (window.innerWidth < 1200) { // Large screens
                header.style.padding = "14px 26px";
            }else if (window.innerWidth < 1400) { // Large screens
                header.style.padding = "18px 56px";
            }  else { // Extra large screens
                header.style.padding = "20px 80px";
            }
        } else {          
            // Check screen width and set padding accordingly
            if (window.innerWidth < 576) { // Extra small screens
                header.style.padding = "20px 16px";
            } else if (window.innerWidth < 768) { // Small screens
                header.style.padding = "24px 24px";
            } else if (window.innerWidth < 992) { // Medium screens
                header.style.padding = "24px 24px";
            } else if (window.innerWidth < 1200) { // Large screens
                header.style.padding = "24px 26px";
            } 
            else if (window.innerWidth < 1400) { // Large screens
                header.style.padding = "32px 56px";
            } else { // Extra large screens
                header.style.padding = "40px 82px";
            }
        }
    }

    const clickHandler=(e)=>{
        e.preventDefault()
        const searchParams = new URLSearchParams(window.location.search);

        dispatch(setFieldValue({ field: 'campaignSource', value: {
            utm_source: searchParams.get('utm_source'),
            utm_medium: searchParams.get('utm_medium') ,
            utm_campaign: searchParams.get('utm_campaign') ,
            utm_term: searchParams.get('utm_term'),
            utm_content: searchParams.get('utm_content'),
        } }))
        Navigate('/claim');
    }
      
      
    return(
        <>
            <header id="header" className="sticky z-[99] top-0 left-0 py-3 bg-white flex justify-between 
            items-center xl:px-[109px] lg:px-[70px] px-[38px] w-full">
                <div className="flex items-center justify-between w-full lg:w-fit">
                    <img src="/assets/Group (1).png" onClick={()=>setIsOpen((prevState) => !prevState)} 
                        className="lg:hidden block" loading="lazy" />
                    <img src='/assets/logo.png' alt="logo-icon" 
                        className="w-[120px] h-[50px]" loading="lazy"/>
                    <img src="/assets/Group.png" className="lg:hidden block" loading="lazy" />
                </div>
                <ul className="lg:flex items-center w-full hidden justify-end">
                    <li className="cursor-pointer">
                        <Link
                            to="about"
                            spy={true}
                            smooth={true}
                            duration={500}
                            className="font-bold 2xl:text-2xl text-xl text-[#000000]"
                        >
                            About
                        </Link>
                    </li>
                    <li className="2xl:ml-[56px] ml-6 cursor-pointer hover:opacity-[60%]">
                        <Link
                            to="howToClaim"
                            spy={true}
                            smooth={true}
                            duration={500}
                            className="font-bold 2xl:text-2xl text-xl text-[#000000]"
                        >
                            How To Claim
                        </Link>
                    </li>
                    <li className="2xl:ml-[56px] ml-6 cursor-pointer hover:opacity-[60%]">
                        <Link
                            to="issues"
                            spy={true}
                            smooth={true}
                            duration={500}
                            className="font-bold 2xl:text-2xl text-xl text-[#000000]"
                        >
                            Am I Eligible
                        </Link>
                    </li>
                    <li className="2xl:ml-[56px] ml-6 cursor-pointer hover:opacity-[60%]">
                        <Link
                            to="faq"
                            spy={true}
                            smooth={true}
                            duration={500}
                            className="font-bold 2xl:text-2xl text-xl text-[#000000]"
                        >
                            FAQs
                        </Link>
                    </li>
                    <li className="2xl:ml-[53px] ml-6 cursor-pointer">
                        <button onClick={(e)=>clickHandler(e)}
                            className="px-[18px] py-2 bg-[#163300] rounded-lg text-white
                        2xl:text-2xl text-base font-bold hover:bg-[#527034]" >Start Claim
                        </button>
                    </li>
                </ul>
            </header>
            <Drawer
                open={isOpen}
                onClose={()=>setIsOpen((prevState) => !prevState)}
                direction='left'
            >
                <div className="flex items-center justify-end p-4">
                    <button type="button"
                        onClick={()=>setIsOpen((prevState) => !prevState)}
                        className="mb-0 font-normal text-[15px] pr-4 leading-normal text-[#131212]"
                        id="offcanvasRightLabel">
                        Close
                    </button>
                    <button
                        type="button"
                        onClick={()=>setIsOpen((prevState) => !prevState)}
                        className="box-content rounded-none border-none hover:no-underline 
                                focus:shadow-none focus:outline-none "
                        data-te-offcanvas-dismiss>
                        <span
                            className="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none 
                                        disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none 
                                        [&.disabled]:opacity-25 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" 
                                fill="none">
                                <rect x="1.04103" width="11.7715" height="1.47144" transform="rotate(45 1.04103 0)" 
                                    fill="black"/>
                                <rect x="9.36427" y="1.04004" width="11.7715" height="1.47144" 
                                    transform="rotate(135 9.36427 1.04004)" fill="black"/>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="offcanvas-body flex-grow overflow-y-auto p-2">
                    <div className="flex flex-col justify-between">
                        <ul className="mt-10">
                            <li className="px-4 cursor-pointer mb-3">
                                <span className="text-[#000000] text-start text-[16px] font-bold pl-3.5 ">
                                    <Link
                                        to="about"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        className="text-base font-semibold text-[#000000]"
                                    >
                                        About
                                    </Link></span>
                            </li>
                            <li className="px-4 cursor-pointer mb-3">
                                <span className="text-[#000000] text-start text-[16px] font-bold pl-3.5">
                                    <Link
                                        to="howToClaim"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        className="text-base font-semibold text-[#000000]"
                                    >
                                        How To Claim
                                    </Link></span>
                            </li>
                            <li className="px-4 cursor-pointer mb-3">
                                <span className="text-[#000000] text-start text-[16px] font-bold pl-3.5">
                                    <Link
                                        to="issues"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        className="text-base font-semibold text-[#000000]"
                                    >
                                        Am I Eligible
                                    </Link></span>
                            </li>
                            <li className="px-4 cursor-pointer mb-3">
                                <span className="text-[#000000] text-start text-[16px] font-bold pl-3.5">
                                    <Link
                                        to="faq"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        className="text-base font-semibold text-[#000000]"
                                    >
                                        FAQs
                                    </Link></span>
                            </li>
                            
                        </ul>
                    </div>
                </div>
    
            </Drawer>
        </>
    )
}
