import React from "react";

export default function Footer(){
    return(
        <div className="bg-[#E8E8E8] pt-[50px] sm:pt-[90px] pb-5 text-[17px] sm:text-[22px] sm:font-[400] leading-[21px] 
        sm:leading-[26px] " >
            <div className="flex justify-center px-[5%] sm:px-[25%]">
                <p className="pt-3 sm:mb-3 mb-1 sm:text-center flex justify-center">
                    This site is owned and operated by Fountain Finances Limited which is registered in 
                    England and Wales. 
                    My Claim Group All rights reserved. My Claim Group is a Trading name of The Claims 
                    Protection Agency Limited, registered in England and Wales Registration Number: 08467014, 
                    address is Unit 8a, Ashbrook business park, Wythenshawe, Manchester M22 5LB.
                    The Claims Protection Agency Limited is Authorised and Regulated by the Financial 
                    Conduct Authority: FCA No: 836470
                </p>
            </div>
            <div className="px-2">
                <p className="sm:text-center flex justify-center pt-4 sm:mb-3 mb-1">
                    *You can claim for free, without using a CMC, first to your lender or to the FOS/FSCS.
                </p>
                <p>
                    <p className=" sm:flex sm:text-center px-3
                 sm:px-[25%] flex justify-center pt-4 sm:mb-3 mb-1">
                        <a href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-
                        final-findings.pdf">
                            *  <u className="text-[#3A34D1]"> https://www.fca.org.uk/publication/multi-firm-reviews/our-
                                work-on-motor-finance-
                                final-findings.pdf</u> 
                        </a>
                    
                    </p>
                    <p className="sm:text-center px-3 sm:px-[25%] flex justify-center pt-4 sm:mb-3 mb-1">
                        <a href=" https://www.fca.org.uk/news/press-releases/fca-ban-motor-finance-discretionary-commissi
                        on-models"> 
                            * 
                            <u className="text-[#3A34D1]">  
                                https://www.fca.org.uk/news/press-releases/fca-ban-motor-finance-discretionary-commission-
                                models </u>
                        </a>
                    </p>
              
                </p>
            </div>
 
        </div>
    )
}