import React from "react";
import ProgressBar from "../../components/progressBar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFieldValue } from "../../redux/GlobalSlice";
import AnimatedPage from "../../components/Animation/AnimatedPage";

export default function VehicleImage(){

    const Navigate=useNavigate();
    const dispatch=useDispatch();
    const claimData=useSelector(state=>state.claimData)

    const submitHandler=(e)=>{
        e.preventDefault()
        dispatch(setFieldValue({field: 'index', value: claimData.index+1}))
        if(claimData.index+1===claimData.lenders.length){
            Navigate('/claim/signature-confirmation')
        }else{
            Navigate('/claim/vehicle-details')
        }
        
    }

    return(
        <AnimatedPage>
            <div className="pt-[40px]">
                <ProgressBar barWidth="w-[100%]"  />

                {/* <div className="w-[80%] mx-auto  mt-[50px]  ">
                <div className=" sms:flex sms:justify-between justify-center sms:w-full ">
                    <div className="sms:w-[50%]  sms:mr-[10px]">
                        <img src="/assets/Car1.jpg" className="w-full" alt="Car" />
                        <div className="flex gap-4">
                            <div className="w-1/3">
                                <img src="/assets/Car2.jpg" className="w-full" alt="sideviewofcar" />
                            </div>
                            <div className="w-1/3">
                                <img src="/assets/Car3.jpg" className="w-full" alt="car" />
                            </div>
                            <div className="w-1/3">
                                <img src="/assets/Car4.jpg" className="w-full" alt="Car" />
                            </div>

                        </div>

                    </div>
                    <div className="sms:w-[45%] sms:mt-0 sms:pt-[30px] mt-5">
                        <p className="text-[60px] leading-[72px] tracking-[-3.15px] font-[900] text-[#163300]">
                            2019 MERCEDES-<br/>BENZ C-CLASS
                        </p>
                        <p className="text-[33px] font-[600]  leading-[34px] tracking-[-0.65px]
                         text-[#163300]">C220d AMG Line 4dr
                        </p>
                        <div className="relative md:w-[45%] w-full mt-[30px]">
                            <img src="/assets/reg1.svg" alt="register" class="absolute left-[-3px] top-[49.5%] h-[57px]
                        transform -translate-y-1/2 
                        " />
                            <input autoComplete="off"
                                type="text" name="Name" placeholder="Vehicle Number" 
                                className={`border 
                                w-full text-xs1 md:text-[18px] px-[21px] pl-[90px] md:pr-[30px] py-3
                                 rounded-[6px] md:rounded-[6px] bg-[#FFCD26]  border-custom-black
                                `} />
                        </div>

                        <div className="sms:w-[50%] mt-[20px] sms:pt-[20px]">
                            <p className="flex justify-between border-b border-[#8B8B8B] mb-3"> 
                                <p className="xl:text-[25px] text-[20px] text-[#163300] 
                                font-[500] tracking-[-0.125px] mb-3">  Colour</p>
                                <p className="xl:text-[25px] text-[20px] text-[#163300] font-[800] 
                                tracking-[-0.125px] mb-3">Silver</p>
                            </p>
                            <p className="flex justify-between mb-3"> 
                                <p  className="xl:text-[25px] text-[20px] font-[500] tracking-[-0.125px]"> Year</p>
                                <p className="xl:text-[25px] text-[20px] text-[#163300] font-[800] tracking-[-0.125px]">2019</p>
                            </p>
                        </div>
                        

                        <div className="sms:w-[50%] mt-[10px] sms:pt-[20px]">
                            <p className="text-[25px] xl:text-[31px] tracking-[-0.155px] font-[800] ">Ownership Details</p>
                            <p className="flex justify-between border-b border-[#8B8B8B] mb-3 pt-[20px]"> 
                                <p  className="  xl:text-[25px] text-[20px] text-[#163300]
                                 font-[500] tracking-[-0.125px] mb-3">  Current Owner</p>
                                <p className="xl:text-[25px] text-[20px] text-[#163300] font-[800]
                                 tracking-[-0.125px] mb-3">Test</p>
                            </p>
                           
                            <p className="flex justify-between mb-3"> 
                                <p  className="xl:text-[25px] text-[20px] text-[#163300] font-[500] tracking-[-0.125px]"> 
                                    Registration Status</p>
                                <p className="xl:text-[25px] text-[20px] text-[#163300] font-[800] tracking-[-0.125px]">Active</p>
                            </p>
                        </div>
                        <div className="text-custom-text tracking-[-0.09px] leading-[20px] pt-[20px]
                         text-[18px] sms:w-[70%]   font-[400] flex justify-normal">
                            We&apos;ve successfully retrieved the details for your vehicle. 
                            Please review the information below to ensure accuracy.
                            If everything looks good, you can proceed to the next step in the process.
                        </div>

                            
                    </div>

                </div>
                <div className="flex justify-center md:pt-[55px] sm:mt-8 mt-4">
                    <button 
                        className="  flex items-center justify-center w-full xl:w-[60%]
                        lg:text-[27px] text-[20px]
                    font-bold sm:h-[67px] h-[40px] text-center text-[#163300] bg-[#9FE870] 
                    rounded-[6px] lg:rounded-[25px] mb-2 px-10 leading-2" data-testid="continue-btn" 
                        onClick={(e)=>{submitHandler(e)}}>
                        Submit
                        <span><img src="/assets/right-arrow.svg" className="ml-[14px]" alt="right arrow" /></span>
                    </button>
                </div>
                
            </div> */}
            

                {/* next division */}

                <div className=" 2xl:w-[45%] xl:w-[57%] lg:w-[60%] md:w-[65%] md:px-0 px-8  mx-auto mt-[60px] ">
                    <div className=" flex justify-center w-full ">
                   
                        <div className="">
                            <p className=" text-[30px] leading-[35px]  sm:text-[60px] text-center
                         sm:leading-[72px] sm:tracking-[-3.15px]  xl:text-[85px] xl:leading-[90px] 
                         tracking-[-1.8px] new
                        font-[900] text-[#163300]">
                                {claimData.lenderInfo[claimData.index].vehicleMake==="" ? "-" 
                                    : claimData.lenderInfo[claimData.index].vehicleMake}
                            </p>
                            <p className="text-[20px] leading-[25px] sm:text-[37px] sm:leading-[38px] tracking-[-0.185px]
                         font-[600] text-center pt-[20px] new1
                         text-[#163300]">{claimData.lenderInfo[claimData.index].vehicleModel==="" ? "-"   
                                    : claimData.lenderInfo[claimData.index].vehicleModel}
                            </p>
                            <div className="flex justify-center">
                                <div className="relative  md:w-[48%] w-full mt-5">
                                    <img src="/assets/reg1.svg" alt="register" class="absolute left-[-5px] top-[51.5%] sm:h-[79px]
                                     h-[58px]
                        transform -translate-y-1/2 
                        " loading="lazy" />
                                    <input autoComplete="off"
                                        type="text" name="Name" placeholder="Vehicle Number" 
                                        value={claimData.lenderInfo[claimData.index].vehicleNumber}
                                        className={`border text-center font-[700] uppercase
                                w-full text-xs1 md:text-[28px] px-[21px] pl-[90px] md:pr-[30px] py-3
                                 rounded-[6px] md:rounded-[6px] bg-[#FFCD26] border-custom-black
                                `} />
                                </div>
                            </div>

                            <div className=" flex-wrap  xl:flex justify-center xl:items-center w-full 
                         mt-[90px]">
                                <div className=" text-center xl:w-1/2 px-[20px] new1">
                                    <p className="text-[31px] font-bold mb-4">Vehicle Details</p>
                                    <p className="flex justify-between border-b border-[#8B8B8B] mb-3"> 
                                        <p className="xl:text-[25px] text-[20px] 
                                     text-[#163300] font-[500] mb-3">  Colour</p>
                                        <p className="xl:text-[25px] text-[20px] text-[#163300]
                                     font-[800]">{claimData.lenderInfo[claimData.index].colour==="" ? "-" 
                                                : claimData.lenderInfo[claimData.index].colour}</p>
                                    </p>
                                   
                                    <p className="flex justify-between mb-3"> 
                                        <p  className="xl:text-[25px] text-[20px] text-[#163300]
                                     font-[500]"> Year</p>
                                        <p className="xl:text-[25px] text-[20px] text-[#163300]
                                     font-[800]">{claimData.lenderInfo[claimData.index].vehicleYear==="" ? "-" 
                                                : claimData.lenderInfo[claimData.index].vehicleYear}</p>
                                    </p>
                                </div>
                        
                                
                            </div>
                            <div className="text-custom-text text-[16px] leading-[21px] new1 tracking-[-0.1px]
                             mt-[40px]  font-[400] flex justify-center text-center">
                                We&apos;ve successfully retrieved the details for your vehicle. 
                                Please review the information below to ensure accuracy.
                                If everything looks good, you can proceed to the next step in the process.
                            </div>

                            
                        </div>

                    </div>
                    <div className="flex justify-center md:mt-10 sm:mt-8 mt-4">
                        <button 
                            className=" sticky  flex items-center justify-center w-full
                        lg:text-[27px] text-[20px] new1
                    font-bold sm:h-[67px] h-[40px] text-center text-[#163300] bg-[#9FE870] 
                    rounded-[6px] lg:rounded-[25px] mb-2 px-10 leading-2" data-testid="continue-btn" 
                            onClick={(e)=>{submitHandler(e)}}>
                            Submit
                            <span><img src="/assets/right-arrow.svg" className="ml-[14px]" 
                                alt="right arrow" loading="lazy" /></span>
                        </button>
                    </div>
                </div>

            </div>
        </AnimatedPage>
    );
}