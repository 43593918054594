import { configureStore } from "@reduxjs/toolkit";
import GlobalSlice from "./GlobalSlice";
import { persistReducer,persistStore } from "redux-persist";
import storage from 'localforage'

const persistConfig = {
    key: 'root', // key for the storage
    storage, // storage engine to use
};
const persistedReducer = persistReducer(persistConfig, GlobalSlice);

export const store = configureStore({
    reducer: { 
        claimData: persistedReducer
    },
})

export const persistor=persistStore(store);