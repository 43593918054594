/* eslint-disable security/detect-object-injection */
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion'
import SignatureCanvas from 'react-signature-canvas';
import { Oval } from "react-loader-spinner";
import { setFieldValue } from '../../redux/GlobalSlice';
import { useNavigate } from 'react-router-dom';
import AnimatedPage from '../../components/Animation/AnimatedPage';
import { dataService } from '../../services/data.services';
import { pushProfilePhotoToS3 } from '../../redux/helper';
import Processing from '../confirmation/processing';
import { lendersapiKey } from '../../config';

export default function Signature(){

    
    function flattenArray(arr) {
        return arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(flattenArray(val)) : acc.concat(val), []);
    }
    

    const signatureRef = useRef(null);
    const dispatch=useDispatch();
    const Navigate=useNavigate();
    const [error,setError]=useState("");
    const [loading,setloading]=useState(false);
    const claimData=useSelector(state=>state.claimData)

    /**
     * The function `clearSignature` is a JavaScript React function that prevents the default behavior
     * of an event and clears a reference to a signature.
     */
    const clearSignature = (e) => {
        e.preventDefault()
        signatureRef.current.clear();
    };

    const changeHandler=(e)=>{
        //((prevError) => ({ ...prevError, termsAccepted: "" }));
        dispatch(setFieldValue({ field: "termsAccepted", value: e.target.checked }));
    }
    
    
    /**
     * The `saveSignature` function is used to save a signature image, convert it to a file, and submit
     * it along with other data to a server.
     */
    const saveSignature = async(e) => {
        e.preventDefault()
        setloading(true);
        const signatureImage = signatureRef.current.toDataURL();
        dispatch(setFieldValue({ field: "signature", value: signatureImage }));
        if (signatureRef.current.isEmpty()){
            setError("This field is mandatory")
            setloading(false);
        }else{
            const url = signatureImage;
            fetch(url)
                .then(res => res.blob())
                .then(async(blob) => {
                    const file = new File([blob], `signature-${claimData.clientReference}`,{ type: "image/png" });
                    const body={
                        "email_address": claimData.emailAddress,
                        "id": claimData.clientReference,
                        "signature_file":{
                            "file_name": file.name,
                            "file_path": `signature/${claimData.clientReference}`
                        },
                        "content_type": "image/png",
                        "title": claimData.title,
                        "app": import.meta.env.VITE_APP_NAME,
                        "firstName": claimData.firstName,
                        "lastName": claimData.lastName,
                        "mobile": claimData.phoneNumber,
                        "email": claimData.emailAddress,
                        "dateOfBirth": claimData.dateOfBirth,
                        "address" : claimData.address,
                    }
                    const response=await dataService.SubmitSignature(body)
                    const url = response.data.get_url
                    dispatch(setFieldValue({ field: "signatureUrl", value: url }));

                    const res = await pushProfilePhotoToS3(response.data.url, file);
                    if(res.status === 200){
                        const payload= 
                        {
                            "api_key": "832d55d6-36e2-46aa-a428-43bc0acc074b",
                            "IncludeHtmlReport": false,
                            "IncludeJsonReport": true,
                            "IncludePdfReport": true,
                            "Username": claimData.emailAddress,
                            "ClientReference": claimData.clientReference,
                            "Title": claimData.title,
                            "Forename": claimData.firstName,
                            "MiddleName": null,
                            "Surname": claimData.lastName,
                            "DateOfBirth": claimData.dateOfBirth+"T00:00:00",
                            "CurrentAddress": {
                                "Flat": null,
                                "HouseName": null,
                                "HouseNumber": claimData.houseNumber,
                                "Street": claimData.street,
                                "Street2": claimData.street2,
                                "PostTown": "",
                                "County": "",
                                "Country": "United Kingdom",
                                "Postcode": claimData.postalCode
                            },
                            "PreviousAddress": null,
                            "PreviousPreviousAddress": null,
                            "claimType": "pcp",
                            "CampaignSource":claimData.campaignSource,
                            "signatureUrl": url
                        }
                        const response = await dataService.GetLenders(payload);
                        setloading(false);
                        if(response.error){
                            Navigate('/claim/select-lenders')
                        }else if(response.data.result.jsonReport.output.fullConsumerData.consumerData.cais){
                            const data=response.data.result.jsonReport.output.fullConsumerData.consumerData.cais;
                            const filteredList = data.flatMap((item) =>
                                item.caisDetails.filter(
                                    (lender) =>
                                        ['02'].includes(lender.companyType) &&
                                        ['01'].includes(lender.accountType)
                                )
                            );
                            // console.log("filteredlist",filteredList)
                            dispatch(setFieldValue({field:'credit', value: filteredList}))
                            console.log("filteredlist",filteredList[0].supplyCompanyName)

                            const supplyCompanyNames = filteredList.map((lender) => lender.supplyCompanyName);
                            const flatArray = flattenArray(supplyCompanyNames);
                            const uniqueArray = [...new Set(flatArray)];
                            console.log(uniqueArray);
                            dispatch(setFieldValue({field: 'lenders', value: uniqueArray}))
                            Navigate('/claim/select-lenders')
                        }else{
                            Navigate('/claim/select-lenders')
                        }
                    }
                })
            
            
        }
    };

    return(
        <AnimatedPage>
            {loading ? <Processing/> : <div>
                <div className="" >
                    <img src="/assets/logo.png" alt="Claimeo logo" loading="lazy"
                        className="mx-auto w-[150px] h-[50px] md:mt-5 mt-3 md:mb-[40px] mb-6" />
                </div>
                <form className="mt-5">
                    <div className="text-center h-[13px] bg-[#EAEAEA] relative lg:w-[72%] sm:hidden w-full mx-auto">
                        <div className={`bg-[#9FE870] h-[13px] rounded-tr-[20px] rounded-br-[20px] w-[100%] sm:hidden
        absolute top-0 left-0`} style={{backgroundColor:"#9FE870"}}></div>
                    </div>
                    <div className="mt-7 md:px-14 sm:px-10 px-4 2xl:w-[75%] xl:w-[66%] lg:w-[90%] md:w-[95%] w-full mx-auto">
                        <div className="xl:text-[45px] xl:leading-[55px] lg:text-[41px] lg:leading-[46px]
                         md:text-[35px] 
                    md:leading-[40px] sm:text-[36px] text-[36px] leading-[40px] text-[#163300] 
                    font-[900] text-center md:mb-10 mb-4 
                    sm:tracking-[-0.8px]  tracking-[-1.8px] new">
                            DRAW YOUR  SIGNATURE HERE
                        </div>
                        <div className="relative md:w-[70%] mx-auto new1">
                            <label  className=" text-[#224F00] md:font-bold font-semibold md:text-[30px] text-xs
                         absolute md:-top-[4px] top-[-6px] md:left-[88px] left-[41px] bg-[#FFFFFF] px-3">
                                Signature <span className="text-[#F72C1D]"></span></label>
                            <div type="textarea" id="dname" className=" outline-0 md:border-4 border 
                            border-[#224F00] bg-[#FFFFFF] 
                        w-full  sm:h-[135px] h-[100px] md:h-[202px]  rounded-[6px] sm:rounded-[25px] text-xs font-normal 
                        text-[#000103] sm:px-4" >
                                <SignatureCanvas
                                    ref={signatureRef}
                                    penColor="black"
                                />
                            </div>
                            {error!=="" && <motion.p 
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                className="font-normal pt-1 email-err-msg text-[#984848] text-[17px] new1
                                        leading-normal">{error}
                            </motion.p>}
                   
                        </div>
                        <div className="mt-5 w-full  mx-auto  items-start sm:hidden flex justify-center new1" >
                            <input type="checkbox" className="sm:w-4 sm:h-4 w-20 cursor-pointer" id="termsAccepted" 
                                onChange={(e)=>changeHandler(e)} 
                                checked={claimData.termsAccepted} />
                            <label className="text-[#9b9a9a] ml-3   text-[9px] tracking-[-0.06px]
                             cursor-pointer" htmlFor="termsAccepted">
                                I agree that I have read, understood and accept My Claims Group’s <a 
                                    className="text-[#008261] underline font-semibold" href='/terms-and-conditions' 
                                    target='_blank' rel="noreferrer" >Terms of Business  </a> and that the check is free. 
                                I also agree to instruct My Claims Group to send a data subject access request
                                (DSAR) to find out if my PCP and/or HP agreements were mis-sold. 
                                We will carry out a soft credit check to validate your case as well as verify your
                                identity and prevent money laundering.
                                This will not impact your credit rating in
                                any way and only data relevant to Vehicle finance will be gathered from your file
                            </label>
                        </div>

                        <div className='md:w-[70%] mx-auto new1'>
                            <button className="flex items-center justify-center w-full text-center text-black 
                                bg-[#ECECEC] py-2 sm:py-5 mt-7 rounded-[6px] md:rounded-[25px] mb-2 px-10 
                                leading-2 sm:text-[27px] text-[20px] font-bold" onClick={(e)=>clearSignature(e)}>
                                Reset
                            </button>
                            <button className="flex items-center justify-center w-full text-center text-black 
                                bg-[#9FE870] py-2 sm:py-5 mt-2 rounded-[6px] md:rounded-[25px] mb-2 px-10 
                                leading-2 text-[20px] sm:text-[27px] font-bold" onClick={(e)=>saveSignature(e)} >
                                Next
                                {/* {loading ? <span className="ml-[14px]" ><Oval
                                height={30}
                                width={30}
                                color="#FFFFFF"
                                wrapperclassName=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#000000"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            /></span> :  */}
                                <span>
                                    <img src="/assets/right-arrow.svg" className="ml-[14px] h-[17px]" 
                                        alt="right arrow" loading="lazy" /></span>
                            </button>
                        </div>


                        <div className="mt-5 w-[70%] mx-auto sm:flex items-start new1  justify-center  hidden " >
                            <input type="checkbox" className="sm:w-4 sm:h-4 mt-0.5 cursor-pointer" id="termsAccepted" 
                                onChange={(e)=>changeHandler(e)} 
                                checked={claimData.termsAccepted} />
                            <label className="text-[#9b9a9a] ml-5 
                               text-[10px] leading-[15px] tracking-[-0.06px]
                             cursor-pointer" htmlFor="termsAccepted">
                                I agree that I have read, understood and accept My Claims Group’s <a 
                                    className="text-[#008261] underline font-semibold" href='/terms-and-conditions' 
                                    target='_blank' rel="noreferrer" >Terms of Business  </a> 
                                and that the check is free. 
                                I also agree to instruct My Claims Group to send a data subject access request
                                (DSAR) to find out if my PCP and/or HP agreements were mis-sold. 
                                We will carry out a soft credit check to validate your case as well as verify your
                                identity and prevent money laundering.
                                This will not impact your credit rating in
                                any way and only data relevant to Vehicle finance will be gathered from your file
                            </label>
                        </div>
                    </div>
                </form>
                <img src="/assets/trustpilot.png" alt="Claimeo logo" loading="lazy" className="flex justify-center 
                    items-center md:my-5 my-4 mx-auto sm:w-auto w-[200px]" />
            </div>}
        </AnimatedPage>
    );
}
