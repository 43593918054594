import React from "react"
import Header from "./header"
import Footer from "./footer"

export default function Complaint(){
    return(
        <form className="overflow-hidden bg-[#f4f4f4]">
            <Header/>
            <div className="px-[5%] overflow-hidden mt-3 sm:mt-6 sm:px-[20%]">
                <div className="">
                    <h2 className=" text-[24px] sm:text-[36px] font-bold">Complaints 
                        <span className="text-[#f9556d] pl-2">Procedure</span></h2>
                    <hr className=" w-[97%] sm:w-[35%] bg-[#f9556d] h-[2px] mb-[2px]"></hr>
                    <hr className=" w-[80%] sm:w-[25%] bg-[#f9556d]  h-[2px]  mb-[2px]"></hr>
                    <hr className=" w-[30%] sm:w-[15%] bg-[#f9556d] h-[2px] mb-[2px]"></hr>
                </div>
                <div className="text-[16px] sm:text-[19px]">
                    <p className="pt-3 sm:mb-3 mb-1">
                        If you have a complaint, we need to know about it and we would ask that you inform us as soon as 
                        possible so that the issue can be addressed. This can be done via email, telephone, in 
                        writing or in person. The address for such communications should be:
                    </p>
                    <ul>
                        <li className="text-[19px] sm:text-[24px] font-bold pt-2">
                            Address: Unit 8a, Ashbrook business park, Wythenshawe, Manchester M22 5LB
                        </li>
                        <li className="pt-2">
                            Email Address: sam@myclaimgroup.co.uk
                        </li>
                        <li className="pt-2">Phone Number: 0800 756 3986</li>
                        <li className="pt-2">
                            Or in person by visiting our premises (address detailed above).
                        </li>
                    </ul>
                    <div>
                        <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px]"> 
                            We will promptly, send you a written or electronic acknowledgement upon receipt of your complaint, 
                            giving the name or job title of the individual handling the complaint,
                            together with details of our internal complaints handling procedures.
                        </p>
                        <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px]">
                            Alternatively, if we are able to resolve your complaint to your satisfaction within 3 working days, 
                            we will send you a summary resolution letter which details your complaint, how we intend to resolve 
                            it and how you can escalate your complaint to the Financial 
                            Ombudsman Service in the future, should you decide that you are unhappy with our resolution.
                        </p>
                        <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px]">
                            If we are not able to resolve your complaint within 3 working days, we will then investigate the 
                            matter and by the end of 8 weeks 
                            from receipt of the complaint, send you:
                        </p>
                        <p className=" pl-[40px] text-[16px] sm:text-[19px]">
                            <ol>
                                <li className="list-disc">
                                    a final response which will also inform you of your referral rights to 
                                    complain to the Financial 
                                    Ombudsman Service, the timeframe for doing so, full contact details for the 
                                    Financial Ombudsman 
                                    Service and the Financial Ombudsman Service&aops;s consumer leaflet; or a response which:
                                    <p className="pl-[20px] sm:pl-[70px] pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px]">
                                        <ul className="list-disc">
                                            <li>
                                                explains that we are still not in a position to make a final response, 
                                                giving reasons for 
                                                the further delay and indicating when we expect to be able to provide a 
                                                final response; 
                                                and
                                            </li>
                                            <li>
                                                informing you in writing that you can complain to the Financial 
                                                Ombudsman Service, 
                                                the timeframe
                                                for doing so, full contact details for the Financial Ombudsman Service and the 
                                                Financial Ombudsman Service’s consumer leaflet.
                                            </li>
                                        </ul>
                                    </p>
                                </li>
                            </ol>
                        </p>
                        <div>
                            <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px]">
                                If we are unable to resolve your complaint to your satisfaction or 
                                we are unable to provide you with a final response within the prescribed timescales,
                                you can have the complaint independently looked at by the Financial Ombudsman Service.</p>
                            <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px]">
                                The Financial Ombudsman Service can investigate complaints up to six years from the date of the 
                                problem happening or within three years
                                of when you found out about the problem.
                            </p>
                            <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px]">
                                If you wish to refer your complaint to the Financial Ombudsman Service this should be done 
                                within 6 months of
                                the date of our final response or summary resolution letter.
                            </p>
                            <p className="text-[16px] sm:text-[19px]">
                                <h5>
                                    You can contact the FOS at:
                                </h5>
                                <div className="text-left flex justify-start font-bold pr-[80%]"> 
                                    <p>
                                        The Financial Ombudsman Service,Exchange Tower Harbour Exchange Square London E14 9SR
                                    </p>
                                </div>
                                <ul>
 
                                    <li className="pt-2 text-[16px] sm:text-[19px]">
                                        <b>Email Address:</b> sam@myclaimgroup.co.uk
                                    </li>
                                    <li className="pt-2"><b>Phone Number: </b>0800 756 3986</li>
                                    <li className="pt-2">
 
                                    </li>
                                </ul>
                            </p>
 
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </form>
    )
}