/* eslint-disable max-len */
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFieldValue } from "../../redux/GlobalSlice";
import ProgressBar from "../../components/progressBar";
import AnimatedPage from "../../components/Animation/AnimatedPage";
import Carousel from "react-multi-carousel";

export default function Claim(){

    const dispatch=useDispatch();
    const claimData=useSelector((state)=>state.claimData);
    const deviceType = window.innerWidth > 1786 ? 'desktop' : window.innerWidth > 1392 ? 'tablet' : 'mobile';
    const carouselRef = useRef(null);
    const Navigate=useNavigate();
    console.log(claimData);

    /**
     * The moveCarouselRight function prevents the default behavior of an event and calls the next
     * method on a carouselRef object.
     */
    const moveCarouselRight = (e) => {
        e.preventDefault();
        carouselRef.current.next();
    };
    
    /**
     * The moveCarouselLeft function prevents the default behavior of an event and moves the carousel
     * to the previous item.
     */
    const moveCarouselLeft = (e) => {
        e.preventDefault();
        carouselRef.current.previous();
    };


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1786 },
            items: 5,
            paritialVisibilityGutter: 60
        },
        tablet: {
            breakpoint: { max: 1786, min: 1392 },
            items: 4,
            paritialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 1392, min: 0 },
            items: 2,
            paritialVisibilityGutter: 30
        }
    };

    return(
        <AnimatedPage>
            <form className="">
                <div className="" >
                    <img src="/assets/logo.png" alt="Claimeo logo"  loading="lazy" className="mx-auto w-[150px] h-[50px] md:mt-5 mt-3 md:mb-[40px] mb-6" />
                </div>
                <ProgressBar barWidth="w-[5%]" />
                <div className="md:px-16 sm:px-10 px-4">
                    <div className="text-center mx-auto sm:mt-7 mt-3 lg:w-[60%] md:w-[95%] w-full
                    ">
                        <p className="2xl:text-[65px] 2xl:leading-[70px] xl:text-[45px] xl:leading-[55px] lg:text-[41px]
                        lg:leading-[46px] sm:tracking-[-0.8px]
                        md:text-[35px] md:leading-[40px] sm:text-2xl text-[36px] leading-[36px] text-[#163300] 
                        font-[900] tracking-[-1.8px] new"> 
                            <span className="lg:block hidden">HAVE YOU USED FINANCE TO PURCHASE A VEHICLE <br /> IN THE PAST 10 YEARS?</span>
                            <span className="lg:hidden block">HAVE YOU USED FINANCE TO PURCHASE A VEHICLE IN THE PAST 10 YEARS?</span>
                        </p>
                        <div className="sm:flex justify-center new1 items-center sm:gap-4 gap-3 handleYes xl:mx-20 md:mx-10 mx-0 mt-8">
                            <button 
                                className="w-full sm:h-[40px] h-10 text-center text-[#163300] font-semibold text-xl bg-[#9FE870] 
                    rounded-[6px] mb-2 px-2" data-testid="yes-btn"
                                onClick={()=>{Navigate('/claim/choose-vehicle-type'); dispatch(setFieldValue({ field: "response", value: 'yes' })),
                                dispatch(setFieldValue({ field: "clientReference", value: uuid() }))}}>
                                Yes
                            </button>
                            <button 
                                className="w-full sm:h-[40px] h-10 text-center text-[#163300] font-semibold text-xl bg-[#9FE870] 
                                rounded-[6px] mb-2 px-2" data-testid="no-btn"
                                onClick={()=>{Navigate('/claim/choose-vehicle-type'); dispatch(setFieldValue({ field: "response", value: 'no' })),
                                dispatch(setFieldValue({ field: "clientReference", value: uuid() }))}} >
                                No
                            </button>
                        </div>
                    </div>
                    <img src="/assets/trustpilot.png" alt="Trustpilot logo" loading="lazy" className="mx-auto my-[29px]" />
                    <div className="justify-evenly sm:flex hidden xl:w-[90%] mx-auto">
                        <div className="trustpilot-card w-full">
                            <div className="trustpilot-widget" 
                                data-locale="en-GB" 
                                data-template-id="539ad0ffdec7e10e686debd7" 
                                data-businessunit-id="653a6faf1f4a8a67f3c99536"
                                data-style-height="350px" 
                                data-style-width="100%"
                                data-theme="light" 
                                data-stars="4,5" 
                                data-review-languages="en">
                                <a href="https://uk.trustpilot.com/review/claimeo.co.uk" target="_blank" 
                                    rel="noopener noreferrer">Trustpilot</a>
                            </div>
                        
                           
                        </div> 
                    </div> 
                </div>
                
            </form>
            
        </AnimatedPage>
    );
}
