import React from "react";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "../../components/Animation/AnimatedPage";

export default function Confirmation(){

    const Navigate=useNavigate();

    return(
        <AnimatedPage>
            <div className="md:px-14 sm:px-10 px-4 md:pb-10 sm:pb-8 py-4 bg-[#9FE87054] min-h-screen">
                <div className="flex justify-center flex-col">
                    <div className="flex justify-center md:pb-4 ">
                        <img src="/assets/logo.png" alt="Claeimo logo" 
                            loading="lazy" className="h-[25px] md:mt-2 mx-auto" />
                    </div>
                    <div className="text-center mx-auto mt-5 2xl:w-[75%] xl:w-[66%] lg:w-[90%] md:w-[95%] w-full">
                        <p className="2xl:text-[65px] 2xl:leading-[70px] xl:text-[45px] xl:leading-[55px] 
                            lg:text-[41px] lg:leading-[46px] md:text-[35px] md:leading-[40px] sm:text-2xl 
                            text-xl text-[#163300] font-[900] new">
                            <span className="md:block hidden">YOU HAVE SUCCESSFULLY 
                                <br/>SUBMITTED YOUR<br/> PERSONAL DETAILS</span>
                            <span className="md:hidden block">YOU HAVE SUCCESSFULLY 
                                <br/>SUBMITTED YOUR<br/> PERSONAL DETAILS</span>
                        </p> 
                    </div>
                    <div className="sm:mb-[45px] mb-[20px] flex justify-center mt-3">
                    
                        <img src="/assets/star.png" loading="lazy"
                            className="md:w-[200px] sm:w-[130px] w-[90px] lg:w-[170px] lg:h-[170px] md:h-[200px] 
                            sm:h-[130px] h-[90px]" alt="Star image" />
                
                    </div>
                    <div className="mx-auto new1 xl:w-[60%] lg:w-[50%] md:w-[70%] sm:w-[50%] w-full ">
                        <button 
                            className="flex justify-center items-center w-full text-center text-[#FFF] 
                            bg-[#163300] sm:py-3.5 py-2.5
                        rounded-[6px] sm:rounded-[25px] mb-2 px-10 leading-2 text-[20px] font-bold" 
                            onClick={()=>Navigate('/claim/signature')}>
                            Next
                            <span><img src="/assets/whiteArrow.svg" className="ml-[14px]" 
                                alt="right arrow" loading="lazy" /></span>
                        </button>
                    </div>
                    <div className="flex justify-center md:mt-10 sm:mt-7 mt-4">
                        <img src="/assets/trustpilot.png" alt="trustPilot logo" loading="lazy" />
                    </div>
                </div>
            </div>
        </AnimatedPage>
    );
}
