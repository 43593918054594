import React from 'react';
import { motion } from "framer-motion";
import { Link, useNavigate } from 'react-router-dom';

const AnimatedButton = ({ buttonName, className, handleClick,link }) => {
    const location=useNavigate()
    return (
        <Link to={link} >
            <motion.button
                type='button'
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                className={className}
                onClick={(e) => {
                    if(link){
                        location(link)
                    }else{
                        handleClick(e)
                    }}}
                id={buttonName}
            >
                {buttonName}
            </motion.button>
        </Link>
    );
}

export default AnimatedButton;
