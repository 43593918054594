import React from "react";
import { Link, Element } from 'react-scroll';
import AnimatedButton from "../../components/Animation/AnimatedButton";
import AnimatedDiv from "../../components/Animation/AnimationDiv";
import { useDispatch, useSelector } from "react-redux";
import { setFieldValue } from "../../redux/GlobalSlice";
import { useNavigate } from 'react-router-dom';

export default function Faq(){
    const dispatch=useDispatch();
    const Navigate=useNavigate();
    const claimData=useSelector((state)=>state.claimData);

    const vehicleCheckHandler=async(e)=>{
        e.preventDefault();
        
        const searchParams = new URLSearchParams(window.location.search);

       
        dispatch(setFieldValue({ field: 'campaignSource', value: {
            utm_source: searchParams.get('utm_source'),
            utm_medium: searchParams.get('utm_medium') ,
            utm_campaign: searchParams.get('utm_campaign') ,
            utm_term: searchParams.get('utm_term'),
            utm_content: searchParams.get('utm_content'),
        } }))

        

        Navigate('/claim/choose-vehicle-type',{state:{isNavigated:true}});
    }

    return(
        <Element name="faq" className="element">
          
            <AnimatedDiv className="2xl:px-[120px] 2xl:pb-[120px] 2xl:pt-[40px] xl:px-[80px] xl:pb-[80px] 
            xl:pt-[30px] md:px-[50px] md:pb-[50px] md:pt-3 sm:px-10 sm:pb-10 sm:pt-2 px-6 pb-5 pt-0 
            flex lg:flex-row flex-col w-full gap-7 bg-[#F9FAFB] text-[#0D3D3F] fade fadeOut">
                <div className="lg:px-[36px] md:px-[29px] px-3 lg:py-[36px] py-[29px] bg-white lg:w-1/2 w-full 
                    border-b-4 border-[#9FE870]">
                    <div className="">
                        <div className="fade fadeOut">
                            <h1 className="font-bold 2xl:text-3xl xl:text-2xl text-xl text-[#0D3D3F]">Why should I Claim?</h1>
                        </div>
                        <p className="font-bold md:text-xl text-lg sm:mt-4 mt-3 text-[#0D3D3F] new1 fade fadeOut">
                            Purchasing a vehicle is a significant investment, and as a
                            buyer, you have the right to expect a certain level of
                            performance, safety, and reliability from your vehicle. If your
                            vehicle is equipped with a faulty or badly designed Diesel
                            Particulate Filter (DPF) system, it can negatively impact these
                            expectations and lead to a host of issues which you may end
                            up paying for.
                        </p>
                        <p className="md:text-[17px] md:leading-6 text-[15px] leading-[18px]font-normal text-[#0D3D3F] new1
                         sm:mt-4 mt-3">
                            A faulty or poorly designed DPF can lead to reduced engine
                            performance, increased fuel consumption, potential safety risks, and
                            even costly repairs or replacements. These are not just inconveniences;
                            they can also lead to unexpected financial burdens and safety concerns.
                        </p>
                        <p className="md:text-[15px] leading-[18px] text-sm font-normal text-[#0D3D3F] sm:mt-4 mt-3 new1" >
                            By making a claim, you can seek compensation for these unexpected costs and
                            inconveniences. More importantly, you can help hold the manufacturer
                            accountable for the quality and performance of their vehicles.
                        </p>
                        <div className="xl:mt-7 mt-4 md:mb-[79px]">
                            <AnimatedButton handleClick={(e) => vehicleCheckHandler(e)} buttonName='Get Started'
                                className="bg-[#163300] hover:bg-[#3c522f] text-white new1
                               sm:text-xl text-base font-semibold px-[32px] py-2.5 
                               rounded-full mb-3" ></AnimatedButton>
                        </div>

                    </div>
                      
                </div>
                <div className=" lg:px-[36px] md:px-[29px] px-3 lg:py-[36px] py-[29px] bg-white lg:w-1/2 w-full 
                border-b-4 border-[#9FE870]">
                    <div className="mb-3 ">
                        <div className="fade fadeOut">
                            <h1 className="font-bold 2xl:text-3xl xl:text-2xl text-xl text-[#0D3D3F]">Can you relate?</h1>
                        </div>
                        <p className="font-normal text-[#0D3D3F] lg:text-xl sm:text-lg text-base sm:mt-4 mt-3 new1 fade fadeOut"> 
                            If you answer yes to any of the following, then you may have a
                            claim:-
                        </p>
                        <p className="lg:mt-6 md:mt-5 sm:mt-4 font-normal sm:text-[17px] 
                        sm:leading-[26px] text-[15px] leading-[18px] new1
                         text-[#0D3D3F]">
                            Have you noticed an <span className="font-bold">increase in fuel consumption </span>
                            in your vehicle?</p>
                        <p className="mt-2 font-normal sm:text-[17px] sm:leading-[26px] text-[15px] leading-[18px] new1
                         text-[#0D3D3F]">
                            Has your vehicle been <span className="font-bold">emitting excessive smoke </span>
                            from the exhaust?</p>
                        <p className="mt-2 font-normal sm:text-[17px] sm:leading-[26px] text-[15px] leading-[18px] new1
                         text-[#0D3D3F]"> 
                            Have you experienced a <span className="font-bold">loss of power </span>
                            or acceleration while driving?</p>
                        <p className="mt-2 font-normal sm:text-[17px] sm:leading-[26px] text-[15px] leading-[18px] new1
                         text-[#0D3D3F]">
                            Has your vehicle&apos;s <span className="font-bold">engine warning light</span>
                            come on?</p>
                        <p className="mt-2 font-normal sm:text-[17px] sm:leading-[26px] text-[15px] leading-[18px] new1
                         text-[#0D3D3F]">
                            Have you noticed a <span className="font-bold">strange smell , </span>
                            similar to burning, coming from your vehicle?</p>
                        <p className="mt-2 font-normal sm:text-[17px] sm:leading-[26px] text-[15px] leading-[18px] new1
                         text-[#0D3D3F]">
                            Has your vehicle been requiring more <span className="font-bold">
                                frequent oil changes?</span></p>
                        <p className="mt-2 font-normal sm:text-[17px] sm:leading-[26px] text-[15px] leading-[18px] new1
                         text-[#0D3D3F]">
                            Have you noticed your vehicle&apos;s engine making a <span className="font-bold">
                                continuous running sound</span>, even after you&apos;ve stopped driving?</p>
                        <p className="mt-2 font-normal sm:text-[17px] sm:leading-[26px] text-[15px] leading-[18px] new1
                         text-[#0D3D3F]">
                            Has your vehicle been requiring more <span className="font-bold">frequent active regenerations </span>
                            (i.e., driving at a certain speed for a certain amount of time)?</p>
                        <p className="mt-2 font-normal sm:text-[17px] sm:leading-[26px] text-[15px] leading-[18px] new1
                         text-[#0D3D3F]">
                            Have you had to <span className="font-bold">refill the Diesel Exhaust Fluid </span> 
                            (DEF) more frequentlythan usual?</p>
                        <p className="mt-2 font-normal sm:text-[17px] sm:leading-[26px] text-[15px] leading-[18px] new1
                         text-[#0D3D3F]">
                            Have you noticed any <span className="font-bold">unusual issues </span> 
                            with your vehicle that started occurring after you purchased it?</p>
                        <p className="mt-2 font-normal sm:text-[17px] sm:leading-[26px] text-[15px] leading-[18px] new1
                         text-[#0D3D3F]">Has your
                            <span className="font-bold"> mechanic mentioned anything about potential issues </span>
                            with the DPF?</p>
                        
                    </div>
                    
                </div>
            </AnimatedDiv>
          
        </Element>
    );
}