/* eslint-disable max-len */
import Carousel from "react-multi-carousel";
import { Link, Element } from 'react-scroll';
import "react-multi-carousel/lib/styles.css";
import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom";
import { dataService } from "../../services/data.services";
import { useDispatch, useSelector } from "react-redux";
import { setFieldValue } from "../../redux/GlobalSlice";

export default function About(){

    const deviceType = window.innerWidth > 1786 ? 'desktop' : window.innerWidth > 1250 ? 'tablet' : 'mobile';
    const carouselRef = useRef(null);
    const claimData=useSelector((state)=>state.claimData);
    const dispatch=useDispatch();
    const Navigate=useNavigate();
    //fade in and out
    const observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5
    };

    function observerCallback(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // fade in 
                entry.target.classList.replace('fadeOut', 'fadeIn');
            } else {
                // fade out 
                entry.target.classList.replace('fadeIn', 'fadeOut');
            }
        });
    }

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    const fadeElms = document.querySelectorAll('.fade');
    fadeElms.forEach(el => observer.observe(el));
   
    

    const moveCarouselRight = (e) => {
        e.preventDefault();
        carouselRef.current.next();
    };
    
    const moveCarouselLeft = (e) => {
        e.preventDefault();
        carouselRef.current.previous();
    };


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1786 },
            items: 3,
            paritialVisibilityGutter: 60
        },
        laptop: {
            breakpoint: { max: 1786, min: 1250 },
            items: 2,
            paritialVisibilityGutter: 50
        },
        tablet: {
            breakpoint: { max: 1250, min: 995 },
            items: 2,
            paritialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 995, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30
        }
    };

    const data=[
        {
            info: "Name",
            img: '',
            name: "Review"
        },
        {
            info: "Name",
            img: '',
            name: "Review"
        },
        {
            info: "Name",
            img: '',
            name: "Review"
        },
        {
            info: "Name",
            img: '',
            name: "Review"
        },
        {
            info: "Name",
            img: '',
            name: "Review"
        },
        {
            info: "Name",
            img: '',
            name: "Review"
        }
    ]


    const vehicleCheckHandler=async(e)=>{
        e.preventDefault();
        
        const searchParams = new URLSearchParams(window.location.search);

       
        dispatch(setFieldValue({ field: 'campaignSource', value: {
            utm_source: searchParams.get('utm_source'),
            utm_medium: searchParams.get('utm_medium') ,
            utm_campaign: searchParams.get('utm_campaign') ,
            utm_term: searchParams.get('utm_term'),
            utm_content: searchParams.get('utm_content'),
        } }))

        

        Navigate('/claim/choose-vehicle-type',{state:{isNavigated:true}});
        
    }

    return(
        <Element name="about" className="element">
            <div className="text-center w-full lg:md-[170px] sm:mt-0 2xl:pt-[140px] xl:pt-[140px] lg:pt-[130px] sm:pt-[110px] pt-[80px]">
                <div className="sm:mx-0 px-4">
                    <div className="xl:px-[20%] lg:px-[15%] sm:px-[10%] px-0 fade fadeOut">
                        <p className="xl:text-[60px] xl:leading-[65px] lg:text-[45px] lg:leading-[50px] lg:tracking-[-0.8px] sm:tracking-tight tracking-[-0.55px]	
                            md:text-[40px] md:leading-[45px] sm:text-2xl text-[30px] leading-[32px] text-[#163300] font-[900] " style={{fontWeight:'900 !important'}}>
                            <span className="md:block hidden fade fadeOut">OWNED A VEHICLE IN <br /> THE LAST 10 YEARS THAT <br /> YOU BOUGHT ON FINANCE?</span>
                            <span className="md:hidden block fade fadeOut">OWNED A VEHICLE IN THE LAST 10 YEARS THAT YOU BOUGHT ON FINANCE?</span>
                        </p>
                        <p className="text-black lg:text-xl sm:text-base text-sm  font-semibold sm:mt-2 mt-2">
                            If so, you may have been mis-sold</p>
                        <p className="text-center text-black lg:text-xl font-medium md:mt-5 sm:mt-4 mt-3 new1">
                            <span className="md:block hidden">Even if you have no problems with your vehicles, the sales agent may have mis-sold you
                                <br /> with finance that you took. You could be entitled up to £3,000 in compensation.</span>
                            <span className="md:hidden block">Even if you have no problems with your vehicles, the salesman may have mis-sold you
                                <br /> with finance that you took. You could be entitled up to £3,000 in compensation.</span>
                            
                        </p>
                    </div>
                </div>
                <div className="absolute left-1/2 -translate-x-[50%] 2xl:w-[800px] lg:w-[792px] new1 w-max 2xl:p-[65px] xl:p-[40px] sm:p-[28px] p-5
                 bg-white sm:rounded-[51px] rounded-[25px] shadow-lg shadow-black sm:mt-[57px] mt-[40px] fade fadeOut">
                    <div className="flex lg:flex-row flex-col justify-between" >
                        <div className="text-black xl:text-[22px] sm:text-xl text-base 
                        font-normal">
                            To check, start our 30-second form</div>
                        <div className="flex items-center justify-center md:text-right text-[#008261] text-[17px] font-bold
                        leading-[30px]">
                            <img src='/assets/Img.png' className="w-[11.67px] h-[16.67px]" loading="lazy" />
                            100% online • No calls</div>
                    </div>
                    <button data-testid="check-btn"
                        className="w-full py-3 bg-[#9FE870] hover:bg-[#aafc74] rounded-[13px] md:mt-5 xl:mt-7 mt-3 xl:text-2xl md:text-lg text-base font-semibold sm:leading-[30px]"
                        onClick={(e)=>vehicleCheckHandler(e)} >Yes, Check My Vehicle</button>
                </div>
                <div className="w-full 2xl:h-[454px] xl:h-[405px] lg:h-[390px] sm:h-[430px] h-[345px] mt-[161px] bg-[#9FE870] new1" >
                    <p className="text-[#0D3D3F] xl:pt-[117px] 2xl:pt-[170px] lg:pt-[120px] md:pt-[127px] sm:pt-[120px] pt-[60px] font-normal md:text-[23px] sm:text-lg text-base">Over 40 Million Drivers Affected • Up to £16k Compensation</p>
                    <div className="flex items-center w-fit mx-auto sm:mt-[37px] mt-5"> 
                        <img src='/assets/trustpilot-new 1.png' className="w-fit" loading="lazy" alt="trustpilot logo" />
                        <img src="/assets/greener.png" className="w-fit ml-4" loading="lazy" alt="Greener logo" />
                    </div>


                    <div className="flex flex-wrap justify-evenly lg:px-[130px] pt-[37px] lg:pb-[38px] pb-[35px] md:px-[50px] sm:px-10 px-5 fade fadeOut">
                        {/* <!-- TrustBox widget - Slider --> */}
                        <div className="trustpilot-card w-full">
                            <div className="trustpilot-widget" 
                                data-locale="en-GB" 
                                data-template-id="539ad0ffdec7e10e686debd7" 
                                data-businessunit-id="653a6faf1f4a8a67f3c99536"
                                data-style-height="350px" 
                                data-style-width="100%"
                                data-theme="light" 
                                data-stars="4,5" 
                                data-review-languages="en">
                                <a href="https://uk.trustpilot.com/review/claimeo.co.uk" target="_blank" 
                                    rel="noopener noreferrer">Trustpilot</a>
                            </div>
                        </div>
                       
                    </div>
                   

                </div>
           
            
          
            </div>
        </Element>

    )
}