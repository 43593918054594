import React from "react";
import Header from "./header";
import Footer from "./footer";

export default function TermsAndConditions(){
    return(
        <>
            <form className="overflow-hidden bg-[#f4f4f4]">
                <Header/>
                <div className="px-[5%] overflow-hidden mt-3 sm:mt-6 sm:px-[20%]">
                    <div className="sm:mt-6 mt-2"> 
                        <h2 className="text-[24px] sm:text-[36px] font-bold">
                            Terms <span className="text-red-400">&amp;Conditions</span></h2>
                        <hr className=" w-[62%] sm:w-[30%] bg-[#f9556d] h-[2px] mb-[2px]"></hr>
                        <hr className=" w-[50%] sm:w-[20%] bg-[#f9556d]  h-[2px]  mb-[2px]"></hr>
                        <hr className=" w-[30%] sm:w-[10%] bg-[#f9556d] h-[2px] mb-[2px]"></hr>
                    </div>
                    <div>
                        <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px] flex justify-normal">
                            This website is provided by Fountain Finances Limited. Access to and use of this website is
                            subject to the following terms and conditions. By accessing this website, you confirm your 
                            acceptance of these terms and conditions of use. Fountain Finances Limited reserves the 
                            right to change these terms and conditions at any time and will not be obliged to notify 
                            you of any such changes. You are therefore advised to check these terms and conditions 
                            regularly and that your continued use of the website 
                            following any such change indicates your acceptance of the new terms.
                        </p>
                        <p className="pt-3 sm:mb-3 mb-1">
                            <h1 className="text-[16px] sm:text-[19px] font-bold">
                                Private use limitation
                            </h1>
                            <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px] flex justify-normal">
                                This website is for your own private, non-commercial use only. You are specifically 
                                prohibited from modifying, copying, distributing, transmitting, displaying, 
                                performing, reproducing, publishing, licensing, creating derivative works, transferring
                                or selling any information, software, products or services contained here.
                            </p>
                            <p className="pt-3 sm:mb-3 mb-1 text-[19px] flex justify-normal">
                                You are solely responsible for evaluating the accuracy and completeness of the 
                                information, services, systems, 
                                articles and data published (“content”). The content is intended for general 
                                information only and should not be construed as advice nor should it be relied 
                                on in any individual circumstances.
                            </p>
                        </p>
                        <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px] "> 
                            <h1 className="text-[16px] sm:text-[19px] font-bold mb-3">
                                Limitation of liability and disclaimer
                            </h1>
                            Nothing in this website constitutes legal advice and is only intended as general 
                            guidance. The information and opinions expressed on this website should not be relied 
                            on or used as a substitute for legal advice
                            <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px] flex justify-normal">
                                All reasonable endeavours have been taken to ensure that content is accurate. 
                                However, Fountain Finances Limited do not represent or make any warranty in 
                                respect of the accuracy or completeness of any of the information.
                            </p>
                            <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px] flex justify-normal">
                                Furthermore, Fountain Finances Limited do not warrant, represent or endorse 
                                the accuracy or completeness of any information provided by third parties and 
                                shall not be liable for any information displayed on this 
                                site that has been provided by a third party.
                            </p>
                            <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px] flex justify-normal">
                                Fountain Finances Limited website is designed for use by UK residents and the 
                                information contained within it
                                relates to the laws of England and Wales.
                            </p>
                            <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px] flex justify-normal">
                                This website is provided by Fountain Finances Limited on an ‘as is’ basis.
                                Fountain Finances Limited expressly disclaims any and all warranties, 
                                expressed or implied, to the fullest extent permitted by law, including, 
                                without limitation, any warranties for the information, data services, 
                                interrupted use, errors, omissions, delays, termination of service, loss of data for any reason,
                                fitness for purpose and infringement of third parties’ property rights.
                            </p>
                            <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px] flex justify-normal">
                                To the fullest extent permitted by applicable law, and except in respect of 
                                death or personal injury arising from Fountain Finances Limited negligence, 
                                Fountain Finances Limited hereby excludes liability for any claims, 
                                loss, demands or damages or any kind whatsoever with respect to this website, 
                                including, without limitation, any direct, 
                                indirect or consequential loss or damages, whether arising from 
                                loss of profits, loss of revenue, loss of data, loss of use or 
                                otherwise, the foregoing will apply whether such claims, loss or damages arise in tort, contract,
                                negligence under statute or otherwise. If you are a consumer, your 
                                statutory rights are not affected.
                            </p>
                        </p>
                        <p className="pt-3 sm:mb-3 mb-1"> 
                            <h1 className="text-[16px] sm:text-[19px] font-bold">
                                Terms of use
                            </h1>
                            <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px] flex justify-normal"> 
                                Fountain Finances Limited may provide an opportunity for you to contribute your ideas, 
                                comments, questions and other communications within message boards, chat rooms, email and 
                                other features. You may not use these systems to perform illegal or immoral activities.
                                The following activities are not permitted on, or in connection with, this website:
                            </p>
                            <ul className="list-disc pl-[20px] sm:pl-[50px] text-[16px] sm:text-[19px]">
                                <li>
                                    Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights 
                                    (such as rights of privacy and publicity) of others.
                                </li>
                                <li className="">
                                    Transmit any material that contains computer code designed to interrupt, 
                                    destroy or limit the functionality of any computer 
                                    software or hardware or telecommunications equipment, e.g. software viruses.
                                </li>
                                <li className="f">
                                    Post or transmit any unlawful, fraudulent, libellous, defamatory, obscene,
                                    pornographic, profane, threatening, abusive, hateful, offensive or otherwise
                                    objectionable information of any kind, including, 
                                    without limitation, any transmissions constituting or encouraging conduct 
                                    that would constitute a 
                                    criminal offence, give rise to civil liability or otherwise violate any law.
                                </li>
                                <li className=" list-disc ">
                                    Impersonate anyone or any entity falsely stating or otherwise misrepresenting 
                                    your affiliation with the person or entity. Post or transmit any advertisements
                                    , solicitations, chain letters, pyramid schemes,
                                    investment opportunities or schemes, or other unsolicited commercial messages.
                                </li>
                                <li className="list-disc">
                                    You acknowledge that personal information disclosed by you on any community is 
                                    disclosed at your own risk and may be used by others.
                                </li>
                                <li className="list-disc">
                                    Fountain Finances Limited reserves the right to terminate your access to this 
                                    website or its systems without notice for any reason whatsoever.
                                </li>
                                <li className=" list-disc ">
                                    Fountain Finances Limited reserves the right to pass on your details together 
                                    with copies of any postings in the event that it is required to do so by any 
                                    statutory authority or you breach any of these terms and conditions.
                                </li>
                                <li className=" list-disc ">
                                    Fountain Finances Limited may at any time monitor any such message boards, 
                                    chat rooms and so forth but does not do so on a continuous basis and cannot therefore be held
                                    liable for any postings made. Fountain Finances Limited may at any time 
                                    remove any posting that it believes is inappropriate or infringes any of the regulations 
                                    above. If you see any postings which you
                                    consider to be inappropriate, please contact Fountain Finances Limited to report 
                                    the post and Fountain Finances Limited will take appropriate action.
                                </li>
                            </ul>
                        </p>
                        <p className="pt-3 sm:mb-3 mb-1">
                            <h1 className="text-[16px] sm:text-[19px] font-bold">
                                Privacy policy </h1>
                            <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px] flex justify-normal" >
                                Fountain Finances Limited is committed to protecting your privacy. We will, on written 
                                request, disclose to you any customer information that we have gathered about you. 
                                We will give you the opportunity to correct any information we hold.
                            </p>
                        </p>
                        <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px]">
                            <h1 className="text-[16px] sm:text-[19px] font-bold"> 
                                Personal information collected </h1>
                            <p className="flex justify-normal">
                                No personal information is collected when browsing web pages or using systems within 
                                this site unless you specifically require us to contact you and you decided to provide 
                                your information via an application which requires personal information to be entered.
                            </p>
                        </p>
                        <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px]">
                            <h1 className="text-[16px] sm:text-[19px] font-bold"> 
                                Email
                            </h1>
                            <p className="flex justify-normal">
                                Email addresses are required in order to send enquiries or register for features such 
                                as newsletters. All emails contain instructions to unsubscribe where appropriate
                            </p>
                        </p>
                        <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px]">
                            <h1 className="text-[16px] sm:text-[19px] font-bold"> 
                                Disclosure to third parties
                            </h1>
                            <p className="flex justify-normal">
                                We will not disclose your email address, name, postal address or any data that 
                                could identify you to a third party without first receiving your permission
                            </p>
                        </p>
                        <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px]">
                            <h1 className="text-[16px] sm:text-[19px] font-bold"> 
                                Security
                            </h1>
                            <p className="flex justify-normal">
                                Fountain Finances Limited takes steps to ensure that your information is 
                                treated securely. However, 
                                the Internet is an open
                                system and we cannot guarantee that personal information you have entered will not be intercepted
                                by others and will not be liable in the event this does occur. Any information you transmit 
                                to us is done so at your own risk. Once we receive your transmission,
                                we will use our best efforts 
                                to ensure its security on our systems.
                            </p>
                        </p>
                        <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px]">
                            <h1 className="text-[16px] sm:text-[19px] font-bold mb-3"> 
                                Right to prevent processing for the purposes of direct marketing
                            </h1>
                            <p className="flex justify-normal">
                                Users who subscribe to Fountain Finances Limited information, which may contain direct marketing 
                                material, can cancel their subscription to these services. Options to unsubscribe are 
                                provided within all communications.
                            </p>
                        </p>
                        <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px]">
                            <h1 className="text-[16px] sm:text-[19px] font-bold mb-3"> 
                                Links to third party Websites
                            </h1>
                            <p className="flex justify-normal">
                                Links from this website to third party websites are provided for convenience only. 
                                Fountain Finances Limited is not responsible for the content, accuracy or availability of
                                the information provided on a third party site, nor is Fountain Finances Limited liable in 
                                the event any link fails to operate. Inclusion of such links does not imply an endorsement or 
                                recommendation by Fountain Finances Limited of 
                                the content on such sites or any association with their operators.
                            </p>
                        </p>
                        <p className="pt-3 sm:mb-3 mb-1 text-[16px] sm:text-[19px]">
                            <h1 className="text-[16px] sm:text-[19px] font-bold mb-3"> 
                                Copyright and trademark notices
                            </h1>
                            <p className="pt-3 sm:mb-3 mb-1 flex justify-normal">
                                Unless otherwise stated, Fountain Finances Limited own the copyright in this website 
                                and its contents. You may print information contained in this website for your personal 
                                use only. No part of this website may be published, transmitted, reproduced or stored on 
                                another website or in any other electronic form without obtaining prior permission from 
                                Fountain Finances Limited. 
                                Fountain Finances Limited should be acknowledged as the source of the material in all cases.
                            </p>
                            <p className="pt-3 sm:mb-3 mb-1 flex justify-normal">
                                Fountain Finances Limited reserve the right to prohibit any link from another website
                                to materials or information on this website. Additionally, you may not reverse engineer, 
                                disassemble, decompile, alter, amend or
                                adapt any part of this website or any software contained within it.
                            </p>
                        </p>
                        <p className="pt-3 sm:mb-3 pb-[40px]  mb-1 text-[16px] sm:text-[19px]">
                            <h1 className="text-[16px] sm:text-[19px] font-bold mb-3"> 
                                General
                            </h1>
                            <p className="flex justify-normal">
                                These terms of service are governed by the laws of England. You hereby consent to the exclusive 
                                jurisdiction of the English courts. These terms of service constitute the entire 
                                agreement between you
                                and Fountain Finances Limited and govern your use of this website. Any failure 
                                by Fountain Finances
                                Limited to exercise or enforce any rights shall not constitute a waiver of such rights. 
                                If any provision of these terms of service are found by a court to be invalid, you nevertheless 
                                agree that the court should
                                endeavour to give effect to the intentions of the parties as reflected in the terms 
                                and the other provisions of these terms shall remain in full force and effect.
                            </p>
                        </p>
                    </div>
                </div>
                <Footer/>
 
            </form>

        </>
        
    );
}