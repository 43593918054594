import React, { useEffect, useState } from "react";
import ProgressBar from "../../components/progressBar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFieldValue } from "../../redux/GlobalSlice";
import ErrorMessage from "../../components/ErrorMessage";
import { dataService } from "../../services/data.services";
import AnimatedPage from "../../components/Animation/AnimatedPage";
import NoLenders from "./noLenders";

export default function Lender(){
    const Navigate=useNavigate();
    const claimData=useSelector(state=>state.claimData)
    console.log(claimData);
    const [error,setError]=useState(false);
    const dispatch=useDispatch();



    /**
     * The function `continueHandler` checks if the `lenders` array is empty, and if not, it updates
     * the `lenders` field in `claimData` and navigates to the `/claim/vehicle-details` page.
     */
    const continueHandler=async(e)=>{
        e.preventDefault();
        //dispatch(setFieldValue({field: 'lenders',value: [...claimData.lenders,... lenders] }))
        Navigate('/claim/vehicle-details')
        
    }

    return(
        <AnimatedPage>
            <ProgressBar barWidth="w-[100%]" />
            {claimData.lenders.length>0 ? 
                <form className="md:px-10 px-[40px]  sm:px-0 lg:px-[25%] " onSubmit={continueHandler} >
                    <p className="xl:text-[55px] xl:leading-[60px] lg:text-[60px] hidden sm:flex flex-col
                    lg:leading-[52px]  md:text-[55px] sm:tracking-[-0.8px]  md:leading-[47px] sm:text-[46px] 
                    sm:leading-[42px] new
                    text-[#163300] font-[900] uppercase text-center mt-[60px]">
                        <span className=" ">Congratulations!<br/>
                    
                            we&apos;ve found a claim 
                            against these lenders</span>
                    </p> 
                    <p className="text-[36px] font-[900] leading-[30px] tracking-[-1.8px] 
                flex flex-col uppercase text-center sm:hidden mt-10  new">
                        <span className="sm:block hidden">Congratulations!</span>
                        <span className="sm:hidden block">CONGRATS!</span>
                        we&apos;ve found a claim against these lenders
                    </p>
                    <p className="xl:text-[30px] xl:leading-[35px] lg:text-[25px] new1
                    lg:leading-[39px]  md:text-[20px] md:leading-[18px] sm:text-[17px] 
                    text-[15px] text-[#163300] font-[500] text-center mt-4 font-rregular
                    tracking-[-0.19px]"
                
                    >If you think you have another lender add it below.</p> 
                    <div class="flex flex-col lg:px-0 justify-center mx-auto gap-2 handleYes mt-6 ">
                        <div className="2xl:w-[90%] xl:w-[70%] lg:w-[90%] md:w-[95%] w-full mx-auto px-0">
                            <div className="customh overflow-auto overflow-x-hidden customScrollbar overflow-y-auto 
                                items-center" >
                                <div className="flex mb-0.5 flex-wrap lg:-mx-3 -mx-1.5 px-2">

                                    {claimData.lenders.map((lender,key)=><div key={key} 
                                        className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                        <label  className="w-full" 
                                        >
                                            <div className={`w-full xl:w-full h-[120px] flex justify-center 
                                                items-center  sm:h-[120px] bg-[#ECECEC] text-center px-5
                                                mx-auto lg:rounded-[25px] rounded-[6px] xl:mr-7 mr-[15px] cursor-pointer
                                            `}>
                                                {lender}
                                                {/* <img src="/assets/vwfs.jpg" 
                                                    className="xl:w-[85px] lg:w-[90px] w-[90px] mx-auto" alt="vwfs" /> */}
                                            </div>
                                            <input type="checkbox" className="hidden" name="lender" value={lender}
                                            />
                                        </label>
                                        
                                    </div>)}
                                   
                                    <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                        <label className="w-full">
                                            <div className={`w-full xl:w-full  h-[120px] flex justify-center 
                                        items-center  sm:h-[120px] bg-[#9FE870] lg:px-[70px]
                                            mx-auto lg:rounded-[25px] rounded-[6px] xl:mr-7 mr-0 cursor-pointer
                                        `}>
                                                <button className="text-center text-[18px] flex flex-col font-[800]
                                        text-[#163300]" onClick={()=>Navigate('/claim/other-lenders')} >+ Another Lender</button>
                                                <input type="radio" className="hidden" name="" />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {error && <ErrorMessage message="This field is mandatory" 
                                className="font-normal pt-1 email-err-msg text-[#984848] text-[17px] new1
                        leading-normal" ></ErrorMessage>}
                            <div className="flex justify-center md:mt-10 sm:mt-8 mt-4">
                                <button type="submit"
                                    className=" sticky  flex items-center justify-center w-full
                        sm:text-[27px] text-[20px] new1
                    font-bold sm:h-[67px] h-[40px] text-center text-[#163300] bg-[#9FE870] 
                    rounded-[6px] lg:rounded-[25px] mb-2 px-10 leading-2" data-testid="continue-btn" 
                                    onClick={(e)=>continueHandler(e)}>
                                    Continue
                                    <span><img src="/assets/right-arrow.svg" className="ml-[14px]" alt="right arrow" /></span>
                                </button>
                            </div>
                        </div>
                    
                    </div>
                </form>: <NoLenders/>}
        </AnimatedPage>
    );
}