import React from "react";
import { Outlet } from "react-router-dom";


export default function Layout(props) {
    return (
        <div >
            <div className="" >
                <img src="/assets/logo.png" alt="Claimeo logo" loading="lazy"
                    className="mx-auto w-[150px] h-[50px] md:mt-5 mt-3 md:mb-[40px] mb-6" />
            </div>
            <Outlet/>
            <img src="/assets/trustpilot.png" alt="Trustpilot logo" loading="lazy" className="flex justify-center 
            items-center md:my-5 my-4 mx-auto sm:w-auto w-[200px]" />
        </div>
    );
}
