import React, { useState } from "react";
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from "react-redux";
import uuid from 'react-uuid';
import { useNavigate } from "react-router-dom";
import { setFieldValue } from "../../redux/GlobalSlice";
import ProgressBar from "../../components/progressBar";
import AnimatedPage from "../../components/Animation/AnimatedPage";
import ErrorMessage from "../../components/ErrorMessage";

export default function Vehicle(){

    const Navigate=useNavigate();
    const claimData=useSelector(state=>state.claimData)
    const [error,setError]=useState(false);
    const dispatch=useDispatch();
    const [vehicle,setVehicle]=useState(claimData.vehicle)

    /**
     * The function `continueHandler` is used to handle the continue action in a form, validating the
     * vehicle field and navigating to the next step if valid.
     * @returns nothing (undefined).
     */
    const continueHandler=(e)=>{
        e.preventDefault();
        if(vehicle===""){
            setError(true);
            return;
        }
        dispatch(setFieldValue({ field: "clientReference", value: uuid() }))
        dispatch(setFieldValue({ field: "vehicle", value: vehicle }))
        Navigate('/claim/person-details')
    }

    /**
     * The clickVehicle function sets the field values for clientReference and vehicle, and then
     * navigates to the person-details page.
     */
    const clickVehicle=(e,vehicleName)=>{
        e.preventDefault();
        dispatch(setFieldValue({ field: "clientReference", value: uuid() }))
        dispatch(setFieldValue({ field: "vehicle", value: vehicleName }))
        Navigate('/claim/person-details')
    }

    return(
        <AnimatedPage>
            <ProgressBar barWidth="w-[25%]" />
            <form className="md:px-16 sm:px-10 px-4 py-3 mx-auto 2xl:w-[75%] xl:w-[70%] lg:w-[90%] md:w-[95%] w-full">
                <div className="text-center mx-auto sm:mt-7 mt-3" style={{fontWeight:"900"}}>
                    <p className="2xl:text-[65px] 2xl:leading-[70px] xl:text-[45px] xl:leading-[55px] lg:text-[41px]
                        lg:leading-[46px] sm:tracking-[-0.8px]
                        md:text-[35px] md:leading-[40px] sm:text-2xl text-[36px] leading-[36px] text-[#163300] 
                        font-[900] tracking-[-1.8px] new">
                        CHOOSE THE TYPE <br /> OF VEHICLE
                    </p> 
                </div>
                <div class="flex flex-col new1 justify-center mx-auto gap-2 handleYes mt-6">
                    <div className=" w-[70%] mx-auto">
                        <div className="flex mb-0.5 flex-wrap lg:-mx-3 -mx-1.5">
                            <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                <label onClick={(e)=>{setVehicle("Car"), setError(false),clickVehicle(e,'Car')}} 
                                    data-testid="car" className="w-full">
                                    <div className={`w-full h-[120px] flex justify-center flex-col 
                                        items-center  sm:h-[192px] 
                                        mx-auto lg:rounded-[25px] rounded-[6px] xl:mr-7 mr-[15px] cursor-pointer
                                        ${vehicle=="Car" ? "bg-[#9FE870]" : "bg-[#ECECEC]"  }`}>
                                        <img src="/assets/car.svg" loading="lazy"
                                            className="xl:w-[85px] lg:w-[90px] w-[60px] mx-auto" alt="car" />
                                        <p className="lg:mt-6 mt-[14px] text-center text-lg font-semibold text-[#163300]">Car</p>
                                    </div>
                                    <input type="radio" className="hidden" name="vehicle" checked={vehicle === "Car"} />
                                </label>
                            </div>
                            <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                <label onClick={(e)=>{setVehicle("Bike"), setError(false),clickVehicle(e,'Bike')}} 
                                    data-testid="bike" className="w-full">
                                    <div className={`w-full h-[120px] flex justify-center flex-col items-center 
                                    sm:h-[192px]
                                mx-auto lg:rounded-[25px] rounded-[6px] xl:mr-7 mr-0 cursor-pointer
                                ${vehicle=="Bike" ? "bg-[#9FE870]" : "bg-[#ECECEC]"  }`}>
                                        <img src="/assets/scooty.svg" loading="lazy"
                                            className="xl:w-[85px] lg:w-[90px] w-[60px] mx-auto" alt="Bike" />
                                        <p className="lg:mt-6 mt-[14px] text-center text-lg font-semibold text-[#163300]">Bike</p>
                                    </div>
                                    <input type="radio" className="hidden" name="vehicle" checked={vehicle === "Bike"} />
                                </label>
                            </div>
                            <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                <label onClick={(e)=>{setVehicle("Motorhome"), setError(false),clickVehicle(e,'Motorhome')}} 
                                    data-testid="motorhome" className="w-full">
                                    <div className={`w-full h-[120px] flex justify-center flex-col 
                                        items-center  sm:h-[192px]
                            mx-auto lg:rounded-[25px] rounded-[6px] xl:mr-7 mr-[15px] cursor-pointer
                            ${vehicle=="Motorhome" ? "bg-[#9FE870]" : "bg-[#ECECEC]"  }`}>
                                        <img src="/assets/bus.svg" loading="lazy"
                                            className="xl:w-[85px] lg:w-[90px] w-[60px] mx-auto " alt="Motorhome" />
                                        <p className="lg:mt-6 mt-[14px] text-center text-lg font-semibold text-[#163300]">
                                            Motorhome</p>
                                    </div>
                                    <input type="radio" className="hidden" name="vehicle" checked={vehicle === "Motorhome"} />
                                </label>
                            </div>
                            <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                <label onClick={(e)=>{setVehicle("Van"), setError(false),clickVehicle(e,'Van')}} 
                                    data-testid="van" className="w-full">
                                    <div className={`w-full h-[120px] flex justify-center flex-col 
                                        items-center  sm:h-[192px] 
                            mx-auto lg:rounded-[25px] rounded-[6px] xl:mr-7 mr-0 cursor-pointer
                            ${vehicle=="Van" ? "bg-[#9FE870]" : "bg-[#ECECEC]"  }`}>
                                        <img src="/assets/van.svg" loading="lazy" 
                                            className="xl:w-[85px] lg:w-[90px] w-[60px]" alt="Van" />
                                        <p className="lg:mt-6 mt-[14px] text-center text-lg font-semibold text-[#163300]">Van</p>
                                        <input type="radio" className="hidden" name="vehicle" checked={vehicle === "Van"} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        
                    </div>
                    {error && <ErrorMessage message="This field is mandatory"
                        className="font-normal pt-1 email-err-msg text-[#984848] text-[17px]  
                            leading-normal text-center"
                    ></ErrorMessage>
                    }
                    <p className="font-normal lg:text-[22px] text-[12px] leding-[30px] text-[#0000006E] text-center ">
                        By starting your quote you’re agreeing to our privacy policy</p>
                </div>
                <div className="flex justify-center new1 md:mt-10 sm:mt-8 mt-4">
                    <button type="submit"
                        className="flex items-center justify-center lg:w-[85%] sm:w-[80%] w-full lg:text-[27px] text-[20px]
                    font-bold py-2.5 sm:py-3 text-center text-[#163300] bg-[#9FE870] 
                    rounded-[6px] sm:rounded-[25px] mb-2 px-10 leading-2" data-testid="continue-btn" 
                        onClick={(e)=>continueHandler(e)}>
                        Continue
                        <span><img src="/assets/right-arrow.svg" loading="lazy" className="ml-[14px]" alt="right arrow" /></span>
                    </button>
                </div>
            </form>
        </AnimatedPage>
    )
}