import React, { useEffect ,useState} from "react"
import Header from "./header"
import About from "./about"
import HowToClaim from "./howToClaim"
import Estimate from "./estimate"
import Footer from "./footer"
import Faq from "./faq"
import { useDispatch } from "react-redux"
import { setFieldValue } from "../../redux/GlobalSlice"
import useFadeInFadeOut from "../../components/Fadeinout/Fadein"
import { useSearchParams } from "react-router-dom"

// const FadeInContainer = styled.div`
//   opacity: 0;
//   transition: opacity 0.5s ease-in-out;
// `;
export default function LandingPage(){
    const [isVisible, setIsVisible] = useState(false);
    const dispatch=useDispatch();
    const [searchParams,setSearchParams]= useState("");

    useEffect(() => {
        // Get the UTM parameters from the URL
        const searchParams = new URLSearchParams(window.location.search);

        

        const data={
            utm_source: searchParams.get('utm_source'),
            utm_medium: searchParams.get('utm_medium') ,
            utm_campaign: searchParams.get('utm_campaign') ,
            utm_term: searchParams.get('utm_term'),
            utm_content: searchParams.get('utm_content'),
        }

        

        dispatch(setFieldValue({ field: 'campaignSource', value: data }))
       
        // // Do something with the UTM parameters, e.g., send to analytics or store in state.
       
    }, [dispatch]);

    return(
        <div className="overflow-hidden" >
            <Header />
            <About />
            <HowToClaim />
            <Estimate />
            <Faq />
            <Footer />
        </div>
        
    )
}