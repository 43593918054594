import React from "react";
import Footer from "./footer";
import Header from "./header";

export default function PrivacyPolicy(){
    return(
        <form className="overflow-hidden bg-[#f4f4f4]  ">
            <Header/>
            <div className="px-[5%] overflow-hidden leading-[1.5rem] mt-3 sm:mt-6 md:px-[20%]"> 

 
                <div className="pt-[50px] mb-3"> 
                    <h2 className=" text-[24px] sm:text-[36px] mb-3 font-bold">Privacy <span 
                        className="text-[#f9556d]">&amp; Policy</span> </h2>
                    <hr className=" w-[67%] sm:w-[30%] bg-[#f9556d] h-[2px] mb-[2px]"></hr>
                    <hr className=" w-[50%] sm:w-[20%] bg-[#f9556d]  h-[2px]  mb-[2px]"></hr>
                    <hr className=" w-[30%] sm:w-[10%] bg-[#f9556d] h-[2px] mb-[2px]"></hr>

                </div>
                <div>
                    <p className="  text-[16px] sm:text-[19px]"><a href="pcp-checker.co.uk is a" className="text-[#3A34D1]">
                        pcp-checker.co.uk </a> is a
                        trading style of Fountain Finances Limited.</p>
                    <p className="pt-3 mb-3  text-[16px] sm:text-[19px] flex justify-normal">
                        This privacy policy sets out how Fountain Finances Limited (FFL), collects, uses and protects your
                        information when you use this website or our services, 
                        in accordance with the Data Protection Act 2018 and the General Data Protection Regulation 2016 (GDPR).
                    </p>
                    <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                        FFL is the controller and is responsible for your personal data. 
                        We are committed to ensuring that your privacy is protected. By using our website, 
                        you may be providing us with some of your personal information and we want to make 
                        sure that we do not use your data in a way that you would not expect. FFL assure you 
                        that your personal information will only be used in accordance with this privacy policy 
                        and in compliance with the latest privacy regulations.
                    </p>
                    <p className="pt-3 mb-3 text-[16px] sm:text-[19px]"> 
                        FFL may change this policy in future by updating this page. You should check this page from 
                        time to time to ensure that you are happy with any changes. This policy is effective from 30/05/2023.
                    </p>
                    <div className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                        <h4 className="text-[16px] sm:text-[19px] mb-2 font-bold"> Personal Information We Collect</h4>
                        <p>
                            Personal information is any information about an individual from which that person can be identified. 
                            It does not include data which has been anonymised.
                        </p>
                        <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                            We may collect, use, store and transfer different kinds of personal
                            data about you which we have grouped together as follows:
                            <ul className="pl-[20px] sm:pl-[50px] pt-3 list-disc "> 
                                <li className="mb-3 sm:mb-1 text-[16px] sm:text-[19px]">
                                    <b>Contact information: </b>Name, current and previous addresses (including postal, 
                                    email and IP addresses) and telephone numbers.
                                </li>
                                <li className="mb-3 sm:mb-1  text-[16px] sm:text-[19px]">
                                    <b>Identity Data:</b> includes first name, maiden name, last name, username or 
                                    similar identifier, 
                                    marital status, title, date of birth and gender.
                                </li>
                                <li className="mb-3 sm:mb-1 text-[16px] sm:text-[19px]">
                                    <b> Financial Data:</b> includes information on your financial circumstances, 
                                    the lenders or financial institutions used by you, the financial
                                    products you have taken out and the details and status of those financial products.
                                </li>
                                <li className="mb-3 sm:mb-1  text-[16px] sm:text-[19px]">
                                    <b> Usage Data:</b> includes information about how you use our website, products 
                                    and services. This may include your IP address, geographical location, browser 
                                    type and version, operating system, referral source, length of visit, page views 
                                    and website navigation paths, as well as information about the timing, frequency 
                                    and pattern of your service use.
                                    The source of the usage data is our analytics tracking system.
                                </li>
                                <li className="mb-3 sm:mb-1  text-[16px] sm:text-[19px]">
                                    <b> Marketing and Communications Data:</b> includes your preferences in receiving 
                                    marketing from us 
                                    and our third parties and your communication preferences.
                                </li>
                                <li className="mb-3 sm:mb-1  text-[16px] sm:text-[19px]"> 
                                    <b>Special Category Data:</b> We will only process special category data where 
                                    you have provided 
                                    this to us and given your explicit consent to store it or we have a legal/regulatory 
                                    obligation to. This includes race or ethnicity, religious or philosophical beliefs, 
                                    sex life, sexual orientation, political opinions, trade union membership, 
                                    information about your health and genetic and biometric data.
                                </li>
                            </ul>
                            This list may not include all the information we require when providing our service/s. 
                            The information we ask you for will relate to your specific enquiry and will only 
                            consist of the information we need to carry out our service. If you contact us other 
                            than via our website, 
                            we may keep a record of that correspondence and a copy of call recordings.
                        </p>
                    </div>
                    <div className="pt-3 mb-3">
                        <h4 className="text-[16px] sm:text-[19px] font-bold"> Method of collection </h4>
                        <ul className="pt-3">
                            <li className="mb-2">
                                <b className="text-[16px] sm:text-[19px]">Direct interactions:</b> By you filling in 
                                forms on our website or social media 
                                or by corresponding 
                                with us by post, phone, email or otherwise.
                            </li>
                            <li className="mb-2">
                                <b className="text-[16px] sm:text-[19px] "> Third parties or public sources:</b>We may
                                receive information about you from various 
                                reputable third-party lead providers, from our regulators or from publicly available 
                                data. We will always 
                                inform you where we have obtained your information from if requested by you.
                            </li>
                        </ul>
                    </div>
                    <div className="pt-3 mb-3">
                        <h5 className="font-bold text-xl">The basis on which we process your information</h5>
                        <p className="pt-3 mb-1 sm:mb-3 text-[16px] sm:text-[19px]">
                            The legal grounds for processing your personal information depend upon the nature of our 
                            relationship with you and the context of processing and are as follows:
                        </p>
                        <ul className="pt-3 pl-[20px] sm:pl-[50px] list-disc text-[16px] sm:text-[19px]">
                            <li>
                                Processing is necessary for the performance of a contract with you, or to take 
                                steps prior to entering into a contract with you.
                            </li>
                            <li>
                                Processing is necessary for the purposes of your legitimate interests or our 
                                legitimate interests, where your fundamental rights and interests do not 
                                override those interests. 
                                In order to determine this we shall undertake a Legitimate Interests 
                                Assessment and balancing test.
                            </li>
                            <li>
                                Processing is necessary for compliance with mandatory legal or regulatory 
                                obligations to which we are subject
                            </li>
                            <li>
                                Processing is undertaken after you have given us your express consent.
                            </li>
                        </ul>
                    </div>
                    <div className="pt-3 mb-3">
                        <h2 className="font-bold mb-3 text-[16px] sm:text-[19px]">What we use your personal information for</h2>
                        <table className="border-black border-2 mb-3 text-[16px] sm:text-[19px]">
                            <tr className="border-black " >
                                <th className="col-span-3 border-black px-3 py-2">
                                    We collect/store or use your information to…</th>
                                <th className="border-black border-2 px-3 py-2">Legal basis</th>
                            </tr>
                            <tbody className="border-black border-2 px-3 py-2">
                                <tr className="border-black border-2 px-3 py-2">
                                    <td className="border-black border-2 px-3 py-2 ">
                                        provide the service you have requested
                                    </td>
                                    <td className="border-black border-2 px-3 py-2">
                                        Contract
                                    </td>
 
                                </tr>
                                <tr className="border-black border-2 px-3 py-2">
                                    <td className="border-black border-2 px-3 py-2">
                                        send to our partner claims management companies, financial 
                                        advice firms, solicitors/law firms or insolvency firms
                                    </td>
                                    <td className="border-black border-2 px-3 py-2">
                                        Consent
                                    </td>
                                </tr>
                                <tr className="border-black border-2 px-3 py-2">
                                    <td className="border-black border-2 px-3 py-2">
                                        send to our service provider/contractor partners
                                    </td>
                                    <td className="border-black border-2 px-3 py-2"> 
                                        Contract and/or Legitimate Interests
                                    </td>
                                </tr>
                                <tr className="border-black border-2 ">
                                    <td className="border-black border-2 px-3 py-2">
                                        to enforce or apply our terms and conditions and/or other agreements
                                    </td>
                                    <td className="border-black border-2 px-3 py-2">
                                        Legitimate Interests
                                    </td>
                                </tr>
                                <tr className="border-black border-2 px-3 py-2">
                                    <td className="border-black border-2 px-3 py-2">
                                        send you information or marketing about our products and services
                                    </td>
                                    <td className="border-black border-2 px-3 py-2">
                                        Legitimate Interests and/or Consent
                                    </td>
                                </tr>
                                <tr className="border-black border-2">
                                    <td className="border-black border-2 px-3 py-2">
                                        provide annual statistics to our regulator
                                    </td>
                                    <td className="border-black border-2 px-3 py-2">
                                        Legal obligation
                                    </td>
                                </tr>
                                <tr className="border-black border-2">
                                    <td className="border-black border-2 px-3 py-2">
                                        provide annual statistics to our regulator
                                    </td>
                                    <td className="border-black border-2 px-3 py-2">
                                        Legal obligation
                                    </td>
                                </tr>
                                <tr className="border-black border-2">
                                    <td className="border-black border-2 px-3 py-2">
                                        resolve complaints against us or the establishment, exercise or defence of legal claims
                                    </td>
                                    <td className="border-black border-2 px-3 py-2">
                                        Legitimate Interests and/or Legal Obligation
                                    </td>
                                </tr>
                                <tr className="border-black border-2">
                                    <td className="border-black border-2 px-3 py-2">
                                        gather feedback to enable us to improve our website, products and services
                                    </td>
                                    <td className="border-black border-2 px-3 py-2">
                                        Legitimate Interests
                                    </td>
                                </tr>
                                <tr className="border-black border-2">
                                    <td className="border-black border-2 px-3 py-2">
                                        verify your identity where we receive requests to access or change 
                                        the information we hold about you
                                    </td>
                                    <td className="border-black border-2 px-3 py-2" >
                                        Legal obligation
                                    </td>
                                </tr>
                                <tr className="border-black border-2">
                                    <td className="border-black border-2 px-3 py-2">
                                        maintain our accounts and records
                                    </td>
                                    <td className="border-black border-2 px-3 py-2">
                                        Legal obligation
                                    </td>
                                </tr>
                                <tr className="border-black border-2">
                                    <td className="border-black border-2 px-3 py-2">
                                        comply with legal and regulatory obligations
                                    </td>
                                    <td className="border-black border-2">
                                        Legal obligation
                                    </td>
                                </tr>
                                <tr className="border-black border-2">
                                    <td className="border-black border-2 px-3 py-2"> 
                                        research and analyse trends to better understand how users are using 
                                        our website and services in order to improve them
                                    </td>
                                    <td className="border-black border-2 px-3 py-2">
                                        Legitimate Interests
                                    </td>
                                </tr>
                                <tr className="border-black border-2">
                                    <td className="border-black border-2 px-3 py-2">
                                        inform you about changes in our services and important service related 
                                        notices, such as security and fraud issues
                                    </td>
                                    <td className="border-black border-2 px-3 py-2">
                                        Legal obligation
                                    </td>
                                </tr>
                                <tr className="border-black border-2">
                                    <td className="border-black border-2 px-3 py-2">
                                        maintaining insurance coverage, managing risks, or obtaining professional advice
                                    </td>
                                    <td className="border-black border-2 px-3 py-2">
                                        Legitimate interests and/or Legal Obligation
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="pt-3 mb-3">
                        <h2 className="font-bold text-xl">
                            Providing your personal data to others
                        </h2>
                        <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                            We may disclose your data to one or more of our selected third party claims management
                            companies, financial advice firms, solicitor/law firms or insolvency firms, 
                            upon your consent to do so, for the purpose of enabling them to contact you so 
                            that they can offer, market and provide relevant services to you. Each such third 
                            party will act as a data controller in relation to the data that we supply to it; 
                            and upon contacting you, each such third party will supply to you a copy of its own privacy policy, 
                            which will govern that third party’s use of your personal data.
                        </p>
                        <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                            We may disclose your personal data to our insurers and/or professional advisers in so 
                            far as reasonably necessary for the purposes of obtaining or maintaining insurance 
                            coverage, managing risks, obtaining professional advice, or the establishment, 
                            exercise or defence of legal claims, whether in court proceedings
                            or in an administrative or out-of-court procedure.
                        </p>
                        <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                            We may disclose personal data to our suppliers or subcontractors, such as external 
                            printers, admin companies, 
                            call centres, compliance consultants and legal advisers.
                        </p>
                        <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                            Any external processors, who process your data on our behalf, are subject to
                            a data processing agreement to ensure the safety and protection of your data. 
                            None of our processors are allowed to use your 
                            data for any other purposes than instructed by us.
                        </p>
                        <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                            In addition to the specific disclosures of personal data set out in this Section, 
                            we may disclose your personal data where such disclosure is necessary for compliance with 
                            a legal obligation to which we are subject, or in order
                            to protect your vital interests or the vital interests of another natural person.
                        </p>
                        <p className="pt-3 mb-3">
                            We may also disclose your personal data where such disclosure is necessary for the 
                            establishment, exercise or defence of legal claims, whether in court proceedings 
                            or in an administrative or out-of-court procedure.
                        </p>
                    </div>
                    <div className="pt-3 mb-3">
                        <h2 className="font-bold text-xl">
                            How long do we keep your personal information
                        </h2>

                       
                        <p>
                            <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                                We retain your information for as long as is necessary for the purpose for 
                                which it was originally obtained.
                            </p>
                            <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                                We have some legal and regulatory obligations to hold certain pieces of information for
                                specific timeframes for example, we are required to keep a copy of 
                                telephone call recordings for a period of 12 months after our last contact with you.
                            </p>
                            <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                                Where we have provided a service to you, we will hold all information relevant 
                                to this for 6 years to enable us to defend any claims/complaints made about our service. 
                                Information relating to any complaints will be held for a further 3 years.
                            </p>
                            <p className="pt-3 mb-3 text-[16px] sm:text-[19px]" > 
                                We also need to keep some of your information for our accounting and reporting requirements.
                            </p>
                            <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                                Your contact details will be held for the purposes of direct marketing for 6 years,
                                this is to enable us to let you know about new products or services that may be of 
                                interest to you as detailed above.
                            </p>

                        </p>
                    </div>
                    <div className="pt-3 mb-3">
                        <h2 className="font-bold text-xl">
                            Your Rights
                        </h2>
                        <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                            You have the following rights in accordance with the GDPR and the DPA:
                        </p>
                        <ul className="pt-2 mb-3 text-[16px] sm:text-[19px]">
                            <li>To know that your data is being processed</li>
                            <li>To access your personal data free of charge</li>
                            <li>To have your information corrected if inaccurate (Including ensuring any third 
                                party puts right any inaccuracy)
                            </li>
                            <li> To request that your data be erased</li>
                            <li>To restrict processing</li>
                            <li>To request transfer of your data</li>
                            <li> To object to processing and;</li>
                            <li>To rights relating to automated decision making</li>
 
                        </ul>
                    </div>
                    <div className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                        <p>
                            You may request that we to provide you with any personal information we hold about 
                            you. Where you send us a request to access your data, this will usually be free of 
                            change and sent to you within one month (unless your request is complex). However, 
                            we may charge a reasonable fee if your request is clearly unfounded, repetitive or 
                            excessive. Alternatively,
                            we may refuse to comply with your request in these circumstances.
                        </p>
                        <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                            We may withhold personal information that you request to the extent permitted by law.
                        </p>
                        <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                            You may instruct us at any time not to process your personal information for marketing purposes.
                        </p>
                        <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                            You may instruct us at any time not to process your personal information for marketing purposes.
                        </p>
                        <p className="text-[16px] sm:text-[19px]">
                            If at any point you wish to raise a complaint regarding our data handling then 
                            you have the right to complain to the Information Commissioner’s Office (ICO) 
                            the supervisory authority for data protection issues in the UK whose information is 
                            available at ico.org.uk. However, we would appreciate to
                            opportunity to deal with any concerns directly with you in the first instance.
                        </p>
                        <p>
                        </p>
                    </div>
                    <div className="pt-3 mb-3">
                        <h2 className="font-bold text-xl">
                            Security
                        </h2>
                        <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                            FFL recognise the personal nature of the information we collect, process and store. 
                            As we are committed to ensuring that your information is secure, we have put in 
                            place suitable physical, electronic and managerial procedures to prevent loss, 
                            unauthorised access, misuse or disclosure and to make sure that your information 
                            is safe and secure. If password access is required for certain parts of our website/application,
                            you are responsible for keeping this password confidential.
                        </p>
                        <p className="pt-3 mb-3">
                            <h2 className="font-bold text-xl">
                                Security
                            </h2>
                            <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                                Our website may contain links to other websites of interest. However, once you have 
                                used these links to leave our site, you should note that we do not have any control 
                                over that other website. Therefore, we cannot be responsible for the protection 
                                and privacy of any information which may be collected or you provide whilst 
                                visiting such sites and such sites are not governed by this privacy statement. 
                                You should exercise caution and look at the privacy statement applicable to the 
                                website in question.
                            </p>
                            <p className="pt-3 mb-3">
                                <h2 className="font-bold text-xl">
                                    Security
                                </h2>
                                <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                                    Our website may contain links to other websites of interest. However, 
                                    once you have used these links to leave our site, you should note that 
                                    we do not have any control over that other website. Therefore, we cannot 
                                    be responsible for the protection and privacy of any information which may 
                                    be collected or you provide whilst visiting such sites and such sites 
                                    are not governed by this privacy statement. You should exercise caution 
                                    and look at the privacy statement applicable to the website in question.
                                </p>
                            </p>
                            <p className="pt-3 mb-3">
                                <h2 className="font-bold text-xl">
                                    Security
                                </h2>
                                <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                                    Our website may contain links to other websites of interest. However, 
                                    once you have used these links to leave our site, you should note that 
                                    we do not have any control over that other website. Therefore, we cannot 
                                    be responsible for the protection and privacy of any information which may 
                                    be collected or you provide whilst visiting such sites and such sites are 
                                    not governed by this privacy statement. You should exercise caution and look 
                                    at the privacy statement applicable to the website in question.
                                </p>
                            </p>
                            <p className="pt-3 mb-3">
                                <h2 className="font-bold text-xl">
                                    Links to other websites
                                </h2>
                                <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                                    Our website may contain links to other websites of interest. However,
                                    once you have used these links to leave our site, you should note that 
                                    we do not have any control over that other website. Therefore, we cannot be 
                                    responsible for the protection and privacy of any information which may be 
                                    collected or you provide whilst visiting such sites and such sites are not 
                                    governed by this privacy statement.
                                    You should exercise caution and look at the privacy statement applicable 
                                    to the website in question.
                                </p>
                            </p>
                            <p className="pt-3 mb-3">
                                <h2 className="font-bold text-xl">
                                    General
                                </h2>
                                <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                                    You may not transfer any of your rights under this privacy policy to 
                                    any other person. We may transfer our rights under this privacy policy 
                                    where we reasonably believe your rights will not be affected.

                                </p>
                                <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                                    If any court or competent authority finds that any provision of this 
                                    privacy policy (or part of any provision) is invalid, illegal or unenforceable, 
                                    that provision or part-provision will, to the extent required, be deemed 
                                    to be deleted, and the validity and enforceability of the other provisions 
                                    of this privacy policy will not be affected. Unless otherwise agreed, no delay, 
                                    act or omission by a party in exercising any right or remedy will be deemed a 
                                    waiver of that, or any other, right or remedy.
                                </p>
                                <p className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                                    If you have any queries relating to our Privacy Policy or the personal 
                                    data we hold about you, or you wish to invoke any of your legal rights, you can contact us by:

                                </p>
 
                                <ul className="pt-3 mb-3 text-[16px] sm:text-[19px]">
                                    <li> <b>Email Address:</b> <a className=" text-[#3A34D1]" 
                                        href="sam@myclaimgroup.co.uk">sam@myclaimgroup.co.uk</a></li>
                                    <li className="pt-2"><b>Phone Number: </b>0800 756 3986</li>
                                    <li className="pt-2">
                                        <b>Write to us at: </b> Unit 8a, Ashbrook business park, Wythenshawe, Manchester M22 5LB
                                    </li>
                                </ul>
                            </p>
                        </p>
                    </div>
                </div>
            </div>
          
            <Footer/>
        </form>
        
    );
}