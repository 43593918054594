import React from "react";

export default function ProgressBar(props){
    return(
        <div className="text-center sm:h-[13px] h-[6px]
         bg-[#EAEAEA] relative sm:w-[55%] w-full mx-auto">
            <div className={`bg-[#9FE870] sm:h-[13px] h-[6px] rounded-tr-[20px] rounded-br-[20px] ${props.barWidth}
        absolute top-0 left-0`} style={{backgroundColor:"#9FE870"}}></div>
        </div>
    )
}