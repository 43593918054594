import React, { useEffect, useRef, useState } from "react";
import ProgressBar from "../../components/progressBar";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFieldValue } from "../../redux/GlobalSlice";
import { dataService } from "../../services/data.services";
import AnimatedPage from "../../components/Animation/AnimatedPage";
import ErrorMessage from "../../components/ErrorMessage";

export default function EmailPhoneDetails(){

    const claimData=useSelector(state=>state.claimData);
    const [error,setError]=useState({emailAddress: "", phoneNumber: "", termsAccepted: ""})
    const [showVerified,setShowVerified]=useState({emailAddress: false, phoneNumber: false})
    const [loading,setLoading]=useState(false);
    const Navigate=useNavigate();
    const dispatch=useDispatch();
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []); 
    /**
     * The function `validate` is an asynchronous function that takes a value as input, sets loading to
     * true, creates a body object with the input value and an array of checks, makes an API call to
     * verify the email using the body object, sets loading to false, and returns the validity of the
     * email.
     * @returns the value of `response.data.valid`.
     */
    const validate=async(value)=>{
        const body = {
            "lookup": value,
            "checks": [
                "Auto"
            ]
        }
        const response = await dataService.VerifyEmail(body);
        return response.data.valid;
    }

    /**
     * The changeHandler function handles different input fields and updates the state accordingly.
     */
    const changeHandler=(e)=>{
        const regex=/^\S/;
        const numbeRegex=/^[0-9]+$/;
        const { id, value }=e.target;
        if(id==="emailAddress"){
            setError((prevError) => ({ ...prevError, emailAddress: "" }));
            dispatch(setFieldValue({ field: id, value: value }));
        }
        if(id==="phoneNumber"){
            if(numbeRegex.test(value) || value==""){
                setError((prevError) => ({ ...prevError, phoneNumber: "" }));
                dispatch(setFieldValue({ field: id, value: value }));
            }
        }
        if(id==="termsAccepted"){
            setError((prevError) => ({ ...prevError, termsAccepted: "" }));
            dispatch(setFieldValue({ field: "termsAccepted", value: e.target.checked }));
        }
    }

    /**
     * The `continueHandler` function is used to handle form submission and validate the email address,
     * phone number, and terms acceptance before navigating to the next page if all conditions are met.
     */
    const continueHandler=async(e)=>{
        e.preventDefault();
        setLoading(true);
        const emailValidity = await validate(claimData.emailAddress)
        const phoneValidity = await validate(claimData.phoneNumber)
        if(claimData.emailAddress===""){
            setError((prevError) => ({ ...prevError, emailAddress: "This field is mandatory." }));
            setLoading(false);
        }
        else{
            if(emailValidity!==true){
                setError((prevError) => ({ ...prevError, emailAddress: "Invalid Email Address" }));
                setLoading(false);
            }
        }
        if(claimData.phoneNumber===""){
            setError((prevError) => ({ ...prevError, phoneNumber: "This field is mandatory" }));
            setLoading(false);
        }
        else{
            if(phoneValidity!==true){
                setError((prevError) => ({ ...prevError, phoneNumber: "Invalid Phone Number." }));
                setLoading(false);
            }
        }
        if(claimData.termsAccepted===false){
            setError((prevError) => ({ ...prevError, termsAccepted: "This field is mandatory" }));
            setLoading(false);
        }
        if(claimData.emailAddress!=="" && claimData.phoneNumber!=="" && claimData.termsAccepted==true 
            && emailValidity===true && phoneValidity===true){
            setLoading(false);
            Navigate('/claim/signature')
        }
    }

    /**
     * The function `blurHandler` validates the email address and phone number in `claimData` and
     * updates the `showVerified` state accordingly.
     */
    const blurHandler=async()=>{
        const emailValidity = await validate(claimData.emailAddress)
        const phoneValidity = await validate(claimData.phoneNumber)
        if(emailValidity===true){
            setShowVerified((prevError) => ({ ...prevError, emailAddress: true }));
        }else if(emailValidity!==true) {
            setShowVerified((prevError) => ({ ...prevError, emailAddress: false }));
        }
        if(phoneValidity===true){
            setShowVerified((prevError) => ({ ...prevError, phoneNumber: true }));
        }else if(phoneValidity!==true){
            setShowVerified((prevError) => ({ ...prevError, phoneNumber: false }));

        }
    }

    return(
        <AnimatedPage>
            <form onSubmit={continueHandler} >
                <ProgressBar barWidth="w-[100%]" />
                <div className="md:px-10 px-4 mx-auto lg:w-[55%] md:w-[95%] w-full">
                    <p className="2xl:text-[65px] 2xl:leading-[70px] xl:text-[45px] xl:leading-[55px] lg:text-[41px]
                    lg:leading-[46px]  md:text-[35px] md:leading-[40px] sm:text-[36px] text-[36px] leading-[39px] new 
                    text-[#163300] font-[900] text-center my-20 sm:my-5 sm:tracking-[-0.8px] tracking-[-1.8px] ">
                        <span className="md:block hidden">WHERE SHOULD WE <br/> SEND YOUR QUOTE?</span>
                        <span className="md:hidden block">WHERE SHOULD WE SEND YOUR QUOTE?</span>
                    </p>  
                </div>
                <div className="flex justify-center mx-auto md:mt-10 new1 mt-7 md:px-0 sm:px-10 px-4 2xl:w-[75%] 
                xl:w-[66%] lg:w-[75%] md:w-[95%] w-full">
                    <div className="md:w-[66%] w-full">
                        <div className="relative">
                            <input autoComplete="off" ref={inputRef}
                                type="email" name="Name" placeholder="Email Address" className={`border 
                            w-full px-[21px] sm:px-[35px] md:text-[25px] py-2 rounded-[6px] md:rounded-[25px] text-[17px]  
                            ${error.emailAddress!=='' ?  "border-[#984848] " : 'border-gray-300 '}`} 
                                onChange={(e)=>changeHandler(e)} 
                                id="emailAddress" value={claimData.emailAddress} onBlur={()=>blurHandler()}/>
                            {showVerified.emailAddress && 
                                <span className="flex items-center absolute sm:top-2 top-1 right-7 text-[#1633006E] gap-3" >
                                    <span className="hidden sm:block" >Verified</span>
                                    <img src='/assets/verified.svg' loading="lazy" alt="verified icon"/></span>}
                        
                        </div>
                        {error.emailAddress!=="" && <ErrorMessage message={error.emailAddress}
                            className="font-normal pt-1 email-err-msg text-[#984848] text-[17px] 
                                        leading-normal">
                        </ErrorMessage>}
                        <div className="mt-3 relative" >
                            <input autoComplete="off" inputMode="decimal"
                                type="tel" name="Name" placeholder="Phone Number" className={`border 
                                w-full px-[21px] sm:px-[35px] md:text-[25px] py-2 rounded-[6px] md:rounded-[25px] text-[17px]  
                                ${error.phoneNumber!=='' ?  "border-[#984848] " : 'border-gray-300 '}`} 
                                onChange={(e)=>changeHandler(e)} 
                                id="phoneNumber" value={claimData.phoneNumber} onBlur={()=>blurHandler()}/>
                            {showVerified.phoneNumber && 
                                <span className="flex items-center absolute sm:top-2 top-1 right-7 text-[#1633006E] gap-3" >
                                    <span className="hidden sm:block" >Verified</span>
                                    <img src='/assets/verified.svg' loading="lazy" alt="verified icon" /></span>}
                        
                        </div>
                        {error.phoneNumber!=="" && <ErrorMessage message={error.phoneNumber}
                            className="font-normal pt-1 email-err-msg text-[#984848] text-[17px] 
                                        leading-normal">
                        </ErrorMessage>}
                        <div className="mt-5 flex items-center" >
                            <input type="checkbox" className="sm:w-4 sm:h-4 cursor-pointer" id="termsAccepted" 
                                onChange={(e)=>changeHandler(e)} 
                                checked={claimData.termsAccepted} />
                            <label className="text-[#9b9a9a] sm:text-[20px] text-[12px]
                             ml-5 font-[500] cursor-pointer" htmlFor="termsAccepted">
                                I confirm I have read, understand and accept the <a 
                                    className="text-[#008AC5] sm:text-[20px] text-[12px]
                                     underline font-[600]" href='/privacy-policy' 
                                    target='_blank' rel="noreferrer" >Privacy Policy</a></label>
                        </div>
                        {error.termsAccepted!=="" && <ErrorMessage message={error.termsAccepted}
                            className="font-normal pt-1 email-err-msg text-[#984848] text-[17px] 
                                        leading-normal">
                        </ErrorMessage>}
                        <div className="justify-center gap-2 handleYes sm:mt-[26px] mt-[20px]">
                            <button type="button"
                                className="flex items-center justify-center w-full text-center text-black 
                                bg-[#ECECEC] py-2 sm:py-5 mt-7 rounded-[6px] md:rounded-[25px] mb-2 px-10 
                                leading-2  text-[20px] sm:text-[27px] font-bold"
                                onClick={(e)=>{e.preventDefault(),Navigate('/claim/address')}}>
                                <span><img src="/assets/leftButton.svg" className="mr-[14px]" 
                                    alt="right arrow" loading="lazy" /></span>
                                Previous
                            </button>
                            <div className="block mx-auto text-center text-sm font-normal text-[#0000006E] sm:hidden">
                                You won&apos;t lose your information
                            </div>

                            <button type="submit"
                                className="flex items-center justify-center w-full text-center text-black 
                                bg-[#9FE870] py-2 sm:py-5 mt-2
                                rounded-[6px] md:rounded-[25px] mb-2 px-10 leading-2  text-[20px] sm:text-[27px] font-bold"
                                onClick={(e)=>continueHandler(e)}>
                                Continue
                                {loading ? <span className="ml-[14px]" ><Oval
                                    height={30}
                                    width={30}
                                    color="#FFFFFF"
                                    wrapperclassName=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#000000"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                /></span> : <span>
                                    <img src="/assets/right-arrow.svg" className="ml-[14px]" 
                                        alt="right arrow" loading="lazy" /></span>}
                            </button>
                        </div>
                    </div>
        
                </div>
            </form>
        </AnimatedPage>
    );
}