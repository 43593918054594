/* eslint-disable security/detect-object-injection */
import React, { useEffect, useRef, useState } from "react";
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFieldValue } from "../../redux/GlobalSlice";
import ProgressBar from "../../components/progressBar";
import AnimatedPage from "../../components/Animation/AnimatedPage";
import ErrorMessage from "../../components/ErrorMessage";

export default function UserInfo(){
    const claimData=useSelector(state=>state.claimData)
    const Navigate=useNavigate();
    const [error,setError]=useState({firstName: false, lastName: false, title: false})
    const [title,setTitle]=useState(claimData.title);
    const dispatch=useDispatch();
    const inputRef = useRef(null);

    // useEffect(() => {
    //     inputRef.current.focus();
    // }, []); 

    /**
     * The changeHandler function is used to handle input changes and validate the input values for
     * first name and last name fields.
     */
    const changeHandler=(e)=>{
        const regex=/^[A-Za-z][A-Za-z\s]*$/;
        const { id, value }=e.target;
        if(id==="firstName"){
            setError((prevError) => ({ ...prevError, firstName: false }));
        }
        if(id==="lastName"){
            setError((prevError) => ({ ...prevError, lastName: false }));
        }
        if(value === '' || regex.test(value)){
            dispatch(setFieldValue({ field: id, value: value }));
        }
    }


    /**
     * The `continueHandler` function is used to handle the continue button click event in a React
     * component, where it checks if certain fields are empty and sets an error state accordingly, and
     * if all fields are filled, it dispatches a Redux action to set the field value and navigates to
     * the next page.
     */
    const continueHandler=(e)=>{
        e.preventDefault();
        sessionStorage.setItem("saved", true);
        if (claimData.firstName === "") {
            setError((prevError) => ({ ...prevError, firstName: true }));
        }
        if (claimData.lastName === "") {
            setError((prevError) => ({ ...prevError, lastName: true }));
        }
        if (title === "") {
            setError((prevError) => ({ ...prevError, title: true }));
        }
        if(claimData.firstName!=="" && claimData.lastName!=="" && title!==""){
            dispatch(setFieldValue({ field: "title", value: title }));
            Navigate('/claim/date-of-birth')
        }
    }

    return(
        <AnimatedPage>
            <ProgressBar barWidth="w-[35%]" />
            <div className="text-center mx-auto md:px-16 sm:px-10 px-4 pt-10
                ">
                <p className="2xl:text-[65px] 2xl:leading-[70px] xl:text-[53px] xl:leading-[60px] lg:text-[41px]
                    lg:leading-[46px] md:text-[35px] md:leading-[40px] sm:text-[36px] text-[36px] leading-[36px] text-[#163300] 
                    font-[900] tracking-[-1.8px] md:tracking-[-0.8px] new">
                    <span className="md:block hidden"> LET US KNOW <br/>WHO YOU ARE</span>
                    <span className="md:hidden block"> LET US KNOW WHO YOU ARE</span></p>  
            </div>
            <div className="flex justify-center space-x-4 mb-3 sm:mt-8 mt-10 new1 ">
                <label>
                    <div 
                        className={`w-[64px] lg:w-[150px] sm:w-[161px] h-[44px] sm:h-[77px] rounded-[6px] sm:rounded-[25px] 
                         text-center py-2 sm:py-5 text-[20px] sm:text-[23px] font-semibold
                        ${title=="Mr" ? "bg-[#9FE870]" : "bg-gray-200"  } cursor-pointer `}
                        onClick={()=>{setTitle("Mr"); setError((prevError) => ({ ...prevError, title: false }));
                            inputRef.current.focus();}} >Mr</div>
                    <input type="radio" className="hidden" name="x" checked={title === "Mr"}>
                    </input></label>
                <label>
                    <div 
                        className={`w-[64px] lg:w-[150px] sm:w-[161px] h-[44px] sm:h-[77px]
                        rounded-[6px] sm:rounded-[25px] text-center  py-2 sm:py-5 text-[20px] sm:text-[23px] font-semibold
                        ${title=="Miss" ? "bg-[#9FE870]" : "bg-gray-200"  } cursor-pointer`}
                        onClick={()=> {setTitle("Miss"); setError((prevError) => ({ ...prevError, title: false })); 
                            inputRef.current.focus();}}>Miss</div>
                    <input type="radio" className="hidden" name="x" checked={title === "Miss"}>
                    </input></label>
                <label>
                    <div 
                        className={`w-[64px] lg:w-[150px] sm:w-[161px] h-[44px] sm:h-[77px]
                         rounded-[6px] sm:rounded-[25px] text-center py-2 sm:py-5  text-[20px] sm:text-[23px] font-semibold
                    ${title=="Mrs" ? "bg-[#9FE870]" : "bg-gray-200"  } cursor-pointer`}
                        onClick={()=>{setTitle("Mrs"); setError((prevError) => ({ ...prevError, title: false }));
                            inputRef.current.focus();}}>Mrs</div>
                    <input type="radio" className="hidden" name="x" checked={title === "Mrs"}>
                    </input></label>
            </div>
            <div className="flex justify-center new1">
                {error.title && <ErrorMessage message="This field is mandatory"
                    className="font-normal pt-1 email-err-msg text-[#984848] text-[17px] 
                                        leading-normal">
                </ErrorMessage>}
            </div>
            <form className="flex justify-center sm:mt-5 mt-4 xl:w-[45%] mx-auto" onSubmit={(e)=>continueHandler(e)}>
                <div className="md:px-14 px-4 w-full">
                    <div className="">
                        <input autoComplete="off" ref={inputRef} 
                            type="text" name="Name" placeholder="First Name" className={`border new1
                            w-full text-[17px]  sm:px-[35px] sm:text-[25px] px-[21px] py-2 rounded-[6px]  sm:rounded-[25px] 
                            ${error.firstName ?  "border-[#984848] " : 'border-gray-300 '}`} onChange={(e)=>changeHandler(e)} 
                            id="firstName" value={claimData.firstName}/>
                    </div>
                    {error.firstName && <ErrorMessage message="This field is mandatory"
                        className="font-normal pt-1 email-err-msg text-[#984848] text-[17px] new1
                                        leading-normal">
                    </ErrorMessage>}
                    <div className="mt-5">
                        <input autoComplete="off" 
                            type="text" name="Name" placeholder="Last Name" className={`border new1
                            w-full px-[21px] sm:px-[35px] text-[17px]  sm:text-[25px] py-2 rounded-[6px] sm:rounded-[25px]  
                            ${error.lastName ?  "border-[#984848] " : 'border-gray-300 '}`} onChange={(e)=>changeHandler(e)} 
                            id="lastName" value={claimData.lastName}/>
                    </div>
                    {error.lastName && <ErrorMessage message="This field is mandatory"
                        className="font-normal pt-1 email-err-msg text-[#984848] text-[17px] new1
                                        leading-normal">
                    </ErrorMessage>}
                    <div className="justify-center gap-2 handleYes sm:mt-[40px] mt-7 new1">
                        <button type="button"
                            className="flex items-center justify-center  w-full text-center text-black bg-[#ECECEC] py-2.5 sm:py-5
                            rounded-[6px] sm:rounded-[25px] mb-2 px-10 leading-2 text-[20px] sm:text-[27px] font-bold"
                            onClick={(e)=>{e.preventDefault(); Navigate('/claim/choose-vehicle-type')}}>
                            <span><img src="/assets/leftButton.svg" className="mr-[14px]" 
                                alt="right arrow" loading="lazy" /></span>
                            Previous
                        </button>
                        <div className="block mx-auto text-center text-sm font-normal text-[#0000006E] sm:hidden mb-2">
                            You won&apos;t lose your information
                        </div>

                        <button type="submit"
                            className="flex items-center justify-center  w-full text-center text-black bg-[#9FE870] py-2.5 sm:py-5
                            rounded-[6px] sm:rounded-[25px] mb-2 px-10 leading-2  text-[20px] sm:text-[27px] font-bold"
                        >
                            Continue
                            <span><img src="/assets/right-arrow.svg" className="ml-[14px]" 
                                loading="lazy" alt="right arrow" /></span>
                        </button>
                    </div>
                </div>
                
            </form>
            
        </AnimatedPage>
    )
}