/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import  { Suspense,useEffect,useState } from 'react';
import { useDispatch } from 'react-redux';

import {Route,Routes, useLocation, useNavigate,
} from 'react-router-dom';
import Claim from '../pages/claim';
import LandingPage from '../pages/landingPage';
import Layout from '../components/layout';
import Vehicle from '../pages/vehicle';
import UserInfo from '../pages/userInfo';
import DateOfBirth from '../pages/dateOfBirth/DateOfBirth';
import Confirmation from '../pages/confirmation';
import Address from '../pages/address';
import EmailPhoneDetails from '../pages/emailPhone';
import Signature from '../pages/signature';
import VehicleDetails from '../pages/vehicleDetails';
import ThankYou from '../pages/confirmation/thankyou';
import ProofOfIdentification from '../pages/proofOfIdentification';
import Success from '../pages/confirmation/success';
import Complaint from '../pages/externalPages/Complaints';
import TermsAndConditions from '../pages/externalPages/TermsAndConditions';
import PrivacyPolicy from '../pages/externalPages/PrivacyPolicy';
import Lender from '../pages/Lenders/lender';
import OtherLender from '../pages/Lenders/Otherlender';
import VehicleImage from '../pages/vehicleDetails/vehicleimage';
import Processing from '../pages/confirmation/processing';
import { clearData } from '../redux/GlobalSlice';
import ThankYouComplete from '../pages/confirmation/thankyoucomplete';

export default function NavigationRoutes(props) {

    const Navigate=useNavigate();
    const path = useLocation();
    const dispatch=useDispatch();

    useEffect(()=>{
        if(!sessionStorage.getItem("saved")){
            dispatch(clearData());
        }
    },[])
   
    return (

        <Suspense fallback={<div>Loading...</div>}>{true &&
            <>
                <Routes location={location} key={location.pathname}>
                    <Route
                        path="*"
                        element={(
                            <main style={{ textAlign: 'center', marginTop: '5rem', fontSize: '20px' }}>
                                <p>Page Not Found</p>
                                <a><Navigate to="/" replace /></a>
                            </main>
                        )}
                    />
                    <Route path="/" element={<LandingPage/>} />
                    <Route path="/claim" element={<Claim/>} />
                    <Route element={<Layout {...props} />}>
                        <Route path="/claim/choose-vehicle-type" element={<Vehicle/>} />
                        <Route path="/claim/person-details" element={<UserInfo/>} />
                        <Route path="/claim/date-of-birth" element={<DateOfBirth/>} />
                        <Route path="/claim/address" element={<Address/>} />
                        <Route path="/claim/quotation" element={<EmailPhoneDetails/>} />
                        <Route path="/claim/vehicle-details" element={<VehicleDetails/>} />
                        <Route path="/claim/identity-proof" element={<ProofOfIdentification/>} />
                        <Route path="/claim/select-lenders" element={<Lender/>} />
                        <Route path="/claim/other-lenders" element={<OtherLender/>} />
                        <Route path="/claim/view-vehicle-details" element={<VehicleImage />} />
                    </Route>
                    <Route path="/claim/personal-details-confirmation" element={<Confirmation/>} />
                    <Route path="/claim/signature-confirmation" element={<ThankYou/>} />
                    <Route path="/claim/identity-proof-confirmation" element={<Success/>} />
                    <Route path="/claim/completion" element={<ThankYouComplete/>} />
                    <Route path="/claim/signature" element={<Signature/>} />
                    <Route path="/complaints-procedure" element={<Complaint/>} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                </Routes>
            </>
        }</Suspense>

    );
}