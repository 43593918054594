/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import axios from 'axios';
import { Auth } from 'aws-amplify';

/**
 * This is an asynchronous function that retrieves data from an API endpoint using axios and returns an
 * object with a boolean error value and the retrieved data.
 * @param endpoint - The `endpoint` parameter is a string that represents the specific API endpoint
 * that the function is trying to access. It is concatenated with the `baseURL` variable to form the
 * complete URL for the API request.
 * @returns an object with two properties: "error" and "data". The "error" property is a boolean value
 * indicating whether an error occurred during the API call. The "data" property contains the response
 * data from the API call.
 */
async function GetAddress(payload) {
    try {
        const data = await axios.get(`${import.meta.env.VITE_API_GET_ADDRESS_URL}/${payload}?api-key=QFXZAKKpS0CERlZIyWeUng40601&all=true`);
        return {error: false, data: data.data};
    } catch (error) {
        return {error: true, message: error};
    }
}

/**
 * This is an asynchronous function that sends a POST request to an API endpoint with a given payload
 * and authentication headers.
 * @param body - The body parameter is the data that will be sent in the request body of the HTTP POST
 * request. It can be any valid JavaScript object or data type that can be serialized into JSON format.
 * @param endpoint - The endpoint parameter is a string that represents the specific API endpoint that
 * the function will send a POST request to. It is typically a URL that specifies the location of the
 * API resource that the function will interact with.
 * @returns an object with two properties: "error" and "data". The "error" property is a boolean value
 * indicating whether an error occurred during the API call. The "data" property contains the response
 * data from the API call.
 */



async function VerifyEmail(payload) {
    try {
        const data = await axios.post(`${import.meta.env.VITE_DATASOAP_URL}/v2`, payload);
        return {error: false, data: data.data};
    } catch (error) {
        return {error: true, message: error};
    }
}
/**
 * This is an async function that sends a PATCH request to an API endpoint with a given payload and
 * authentication headers.
 * @param body - The `body` parameter is the data that will be sent in the request body of the PATCH
 * request. It could be an object, an array, or any other data type that can be serialized into JSON.
 * @param endpoint - The endpoint parameter is a string that represents the specific API endpoint that
 * the PATCH request will be sent to. It is typically a URL that specifies the location of the resource
 * that needs to be updated.
 * @returns an object with two properties: "error" and "data". The "error" property is a boolean value
 * indicating whether an error occurred during the API call. The "data" property contains the response
 * data from the API call.
 */
async function SubmitInformation(payload) { 
    const headers = {
        Authorization: 'Basic ucuQ86S7ficbrm7A4Ak1NqetQYDLIc3qSf3Cc1V3',
    };
    try {
        const data = await axios.post(`${import.meta.env.VITE_SUBMIT_URL}/submit`, payload,{
            headers: headers,
        });
        return {error: false, data: data.data};
    } catch (error) {
        return {error: true, message: error};
    }
}


async function SubmitSignature(payload) { 
    try {
        const data = await axios.post(`${import.meta.env.VITE_BASE_URL}/create`, payload);
        return {error: false, data: data.data};
    } catch (error) {
        return {error: true, message: error};
    }
}

async function SubmitDocuments(email_address,id,payload) { 
    try {
        const data = await axios.patch(`${import.meta.env.VITE_BASE_URL}/update/${email_address}/${id}`, payload);
        return {error: false, data: data.data};
    } catch (error) {
        return {error: true, message: error};
    }
}

async function GetVehicleDetails(payload){
    try {
        const data = await axios.get(`${import.meta.env.VITE_GET_VEHICLE_DETAILS_URL}?v=2&api_nullitems=1&auth_apikey=7FD82C9B-A3CA-40A5-83A7-534F74BB8396&key_VRM=${payload}`)
        return { error: false, data: data.data };
    } catch (e) {response
        return { error: true, message: e };
    }
}

async function GetLenders(body){ 
    // const headers={
    //     "api_key": "86a0ad33-7742-49ae-89e1-ffe589e480ed",
    // }
    try {
        // const data = await axios.post(`https://leads.tslmedia.co.uk/credit-check/mcg`,body);
        const data = await axios.post(`${import.meta.env.VITE_GET_CHECK_MCG}`,body);
        return {error: false, data: data.data};
    } catch (error) {
        return {error: true, message: error};
    }
}

// async function GetLenders(body){ 
//     // const headers={
//     //     "api_key": "86a0ad33-7742-49ae-89e1-ffe589e480ed",
//     // }
//     try {
//         const data = await axios.post(`https://leads.tslmedia.co.uk/credit-check/mcg`,body);
//         return {error: false, data: data.data};
//     } catch (error) {
//         return {error: true, message: error};
//     }
// }

async function GetModel(make) { 
    try {
        const data = await axios.get(`${import.meta.env.VITE_BASE_URL}/model/${make}`);
        return {error: false, data: data.data};
    } catch (error) {
        return {error: true, message: error};
    }
}

async function TrustPilot() { 
    try {
        const data = await axios.get(``);
        return {error: false, data: data.data};
    } catch (error) {
        return {error: true, message: error};
    }
}


export const dataService = {
    VerifyEmail,
    GetAddress,
    SubmitInformation,
    SubmitSignature,
    SubmitDocuments,
    GetVehicleDetails,
    GetLenders,
    GetModel,
    TrustPilot,
}
