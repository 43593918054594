/* eslint-disable max-len */
import React, { useState } from "react";
import { Listbox } from '@headlessui/react'
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFieldValue } from "../../redux/GlobalSlice";
import AnimatedPage from "../../components/Animation/AnimatedPage";
import ErrorMessage from "../../components/ErrorMessage";
import { dataService } from "../../services/data.services";
import ProgressBar from "../../components/progressBar";

export default function VehicleDetails() {

    const claimData=useSelector(state=>state.claimData)
    const [open, setIsOpen] = useState(false);
    const [openModel, setIsOpenModel] = useState(false);
    const [openMake,setOpenMake]=useState(false);
    const [loading,setLoading]=useState(false);
    const [vehicleNumber,setVehicleNumber]=useState("");
    const [lender,setLender]=useState(claimData.lenders[claimData.index])
    const [vehicleMake, setVehicleMake]=useState("");
    const [selectedVehicleModel, setSelectedVehicleModel] = useState("")
    const [vehicleModels,setVehicleModels]=useState([]);
    const [filteredModel,setFilteredModel]=useState([])
    const [selectedVehicleYear, setSelectedVehicleYear] = useState("")
    const [loader,setLoader]=useState(false);
    const [showDetails,setShowDetails]=useState(false);
    const [vehicleRange, setVehicleRange]=useState("");
    const [selectedVehicleRange, setSelectedVehicleRange] = useState("")
    const dispatch=useDispatch();
    const [vehicleInfo, setVehicleInfo]=useState({
        vehicleNumber: '',
        vehicleMake: '',
        vehicleRange:'',
        vehicleModel: '',
        vehicleYear: '',
        lender: "",
        colour: "",
        owner: "",
        registrationStatus: "",

    })
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 50 }, (_, index) => currentYear - index);
    const [error,setError]=useState({vehicleNumber: "", vehicleMake: "",vehicleRange: "",vehicleModel: "" , vehicleYear: ""})

    const Navigate=useNavigate();

    const submitHandler=async(e)=>{
        e.preventDefault();
        dispatch(setFieldValue({field: "lenderInfo", value: [...claimData.lenderInfo, {
            vehicleNumber: "",
            vehicleMake: vehicleMake,
            vehicleModel: selectedVehicleModel,
            vehicleRange:selectedVehicleRange,
            vehicleYear: selectedVehicleYear,
            lender: claimData.lenders[claimData.index],
            colour: "",
            owner: "",
            registrationStatus: "",
    
        }]}))
        dispatch(setFieldValue({field: 'index', value: claimData.index+1}))
        setLender(claimData.lenders[claimData.index+1])
        setVehicleMake("")
        setSelectedVehicleModel("")
        setSelectedVehicleRange("")
        setSelectedVehicleYear("")
        if(claimData.index+1===claimData.lenders.length){
            Navigate('/claim/signature-confirmation')
        }

        
    }

    console.log(claimData.lenders, 'lenders')
    console.log(claimData.credit)
    /*&******/
    

    // const targetRange = 'GLC-CLASS'; // Replace this with the range you want to search for
    // const filteredArray = filterByRange(data, targetRange);

    // console.log(filteredArray); // This will display the filtered array of objects

    /**
     * The changeHandler function handles changes in input fields and updates state variables
     * accordingly.
     */
    const changeHandler=(e)=>{
        const { id, value }=e.target;
        if(id==="vehicleMake"){
            setError((prevError) => ({ ...prevError, vehicleMake: "" }));
            setVehicleMake(value);
            setSelectedVehicleModel("");
            setSelectedVehicleRange("");
        }
        if(id==="vehicleNumber"){
            setVehicleNumber(value);
        }
        if(id==="vehicleNumber"){
            setError((prevError) => ({ ...prevError, vehicleNumber: "" }));
        }
    }

    const vehicleDetailsHandler=async(e)=>{
        setLoader(true);
        setError((prevError) => ({ ...prevError, vehicleMake: "" }));
        setError((prevError) => ({ ...prevError, vehicleRange: "" }));
        setError((prevError) => ({ ...prevError, vehicleModel: "" }));
        setError((prevError) => ({ ...prevError, vehicleYear: "" }));
        const response=await dataService.GetVehicleDetails(vehicleNumber);
        if(response.data.Response.StatusCode!=='Success'){
            setError((prevError) => ({ ...prevError, vehicleNumber: "Records for this Vehicle Number was not found." }));
            setLoader(false);
            return;
        }
        setVehicleInfo({
            vehicleNumber: vehicleNumber,
            vehicleMake: response.data.Response.DataItems.ClassificationDetails.Dvla.Make,
            vehicleRange: response.data.Response.DataItems.VehicleRegistration.Range,
            vehicleModel: response.data.Response.DataItems.VehicleRegistration.Model,
            vehicleYear: response.data.Response.DataItems.VehicleRegistration.YearOfManufacture,
            lender: "",
            colour: response.data.Response.DataItems.VehicleRegistration.Colour,
            owner: "",
            registrationStatus: "",

        })
        dispatch(setFieldValue({field: "lenderInfo", value: [...claimData.lenderInfo, {
            vehicleNumber: vehicleNumber,
            vehicleMake: response.data.Response.DataItems.ClassificationDetails.Dvla.Make,
            vehicleRange: response.data.Response.DataItems.VehicleRegistration.Range,
            vehicleModel: response.data.Response.DataItems.VehicleRegistration.Model,
            vehicleYear: response.data.Response.DataItems.VehicleRegistration.YearOfManufacture,
            lender: claimData.lenders[claimData.index],
            colour: response.data.Response.DataItems.VehicleRegistration.Colour,
            owner: "",
            registrationStatus: "",

        }]}))
        // setError((prevError) => ({ ...prevError, vehicleNumber: "" }));
        // setLoader(false);
        // dispatch(setFieldValue({ field: "vehicleMake", 
        //     value: response.data.Response.DataItems.ClassificationDetails.Dvla.Make }));
        // dispatch(setFieldValue({ field: "vehicleYear", 
        //     value: response.data.Response.DataItems.VehicleRegistration.YearOfManufacture }));
        // dispatch(setFieldValue({ field: "vehicleModel", 
        //     value: response.data.Response.DataItems.VehicleRegistration.Model }));
        Navigate('/claim/view-vehicle-details')
    }

    /**
     * The `searchHandler` function is an asynchronous function that handles the search for vehicle
     * details based on a vehicle number input.
     * @returns The function `searchHandler` does not have a return statement.
     */
    const searchHandler=async(e)=>{
        e.preventDefault();
        setLoader(true);
        const response=await dataService.GetVehicleDetails(vehicleNumber);
        if(response.data.Response.StatusCode!=='Success'){
            setError((prevError) => ({ ...prevError, vehicleNumber: "Records for this Vehicle Number was not found." }));
            setLoader(false);
            return;
        }
        setVehicleInfo({
            vehicleNumber: vehicleNumber,
            vehicleMake: response.data.Response.DataItems.ClassificationDetails.Dvla.Make,
            vehicleModel: response.data.Response.DataItems.VehicleRegistration.Model,
            vehicleRange: response.data.Response.DataItems.VehicleRegistration.Range,
            vehicleYear: response.data.Response.DataItems.VehicleRegistration.YearOfManufacture,
            lender: "",
            colour: response.data.Response.DataItems.VehicleRegistration.Colour,
            owner: "",
            registrationStatus: "",

        })
        dispatch(setFieldValue({field: "lenderInfo", value: [...claimData.lenderInfo, {
            vehicleNumber: vehicleNumber,
            vehicleMake: response.data.Response.DataItems.ClassificationDetails.Dvla.Make,
            vehicleModel: response.data.Response.DataItems.VehicleRegistration.Model,
            vehicleRange: response.data.Response.DataItems.VehicleRegistration.Range,
            vehicleYear: response.data.Response.DataItems.VehicleRegistration.YearOfManufacture,
            lender: claimData.lenders[claimData.index],
            colour: response.data.Response.DataItems.VehicleRegistration.Colour,
            owner: "",
            registrationStatus: "",

        }]}))
        setVehicleNumber("");
        setLender(claimData.lenders[claimData.index+1])
        dispatch(setFieldValue({field: 'index', value: claimData.index+1}))
        if(claimData.index+1===claimData.lenders.length){
            Navigate('/claim/signature-confirmation')
        }
    }

    /**
     * This function fetches vehicle models based on the selected vehicle make and displays them if
     * they exist, otherwise it shows an error message.
     * @returns The function `fetchModel` does not have a return statement.
     */
    const fetchModel=async(e)=>{
        e.preventDefault()
        if(openModel){
            setIsOpenModel(false);
            return;
        }
        if(vehicleMake===""){
            setError((prevError) => ({ ...prevError, vehicleMake: "This field is mandatory" }));
            return;
        }
        setLoading(true);
        const response = await dataService.GetModel(vehicleMake);
        setLoading(false);
        if(response.data.data.length===0){
            setError((prevError) => ({ ...prevError, vehicleMake: "Enter valid Vehicle Make" }));
        }else{
            setVehicleModels(response.data.data);
            setVehicleRange(response.data.data);
            setIsOpenModel(true);
        }
    }

    const fetchRange=async(e)=>{
        e.preventDefault()
        if(open){
            setIsOpen(false);
            return;
        }
        setLoading(true);
        const response = await dataService.GetModel(vehicleMake);
        console.log("responseGetmodel", response)
        if(response.data.data.length===0){
            setError((prevError) => ({ ...prevError, vehicleMake: "Enter valid Vehicle Make" }));
        }else{
            setVehicleModels([...new Set(response.data.data.map(item => item.Range))]);
            setVehicleRange(response.data.data);
            setLoading(false);
            setIsOpen(true)
        }
    }

    const continueHandler=()=>{
        setShowDetails(!showDetails);
    }


    return (
        <AnimatedPage>
            <ProgressBar barWidth="w-[100%]" />
            <div onClick={()=>setIsOpen(false)} >
                
                {/* new */}
                <div className="new w-[60%] mx-auto">
                    <p className="xl:text-[45px] xl:leading-[55px] lg:text-[41px] lg:leading-[46px] md:text-[35px] 
                    md:leading-[40px] sm:text-2xl text-[22px] leading-[27px] text-[#163300] font-[900] text-center mt-8 mb-4 mx-auto 
                     sm:tracking-[-0.8px] tracking-[-0.5px]  ">
                        <span className="lg:block hidden uppercase">ENTER YOUR VEHICLE <br/> DETAILS FOR {lender} <br/> FINANCE</span>
                        <span className="lg:hidden block uppercase">ENTER YOUR VEHICLE DETAILS FOR {lender} FINANCE</span> 
                    </p>   
                </div>
                <div className="flex justify-center mt-5 md:px-10 sm:px-7 px-4 2xl:w-[75%] xl:w-[66%] new1
                    lg:w-[90%] md:w-[95%] w-full mx-auto">
                    <div className="relative md:w-[60%] w-full">
                        <div className="relative">
                            <img src="/assets/reg1.svg" alt="register" loading="lazy" className="absolute left-[-8px] top-[51%] h-[116%]
                        transform -translate-y-1/2 
                        " />
                            <input autoComplete="off"
                                type="text" name="Name" placeholder="VEHICLE NUMBER" 
                                className={`border text-center uppercase
                                w-full text-[18px] md:text-[24px] lg:text-[34px] font-[700] px-[21px] pl-[90px] md:pr-[30px] py-3
                                 rounded-[6px] md:rounded-[6px] bg-[#FFCD26] tracking-[-0.27px] ${vehicleNumber!=="" ? "text-[#163300]" : "text-[#1633005C]" }
                                ${error.vehicleNumber!=='' ?  "border-[#984848] " : 'border-[#434343] '}`} 
                                onChange={(e)=>changeHandler(e)} 
                                id="vehicleNumber" value={vehicleNumber}/>
                        </div>
                        {error.vehicleNumber!=="" && <ErrorMessage message={error.vehicleNumber}
                            className="font-normal pt-1 email-err-msg text-[#984848] text-[17px] 
                                        leading-normal">
                        </ErrorMessage>}
                        {/* twobutton added check with that */}


                        <button 
                            className=" hidden sm:flex items-center justify-center w-full text-center text-black mt-4
                                bg-[#9FE870] py-2.5 sm:py-[12px] disabled:bg-[#EAEAEA]
                          rounded-[6px] md:rounded-[6px] mb-2 px-10 leading-2 text-[20px] sm:text-[30px] font-bold"
                            onClick={(e)=>vehicleDetailsHandler(e)} disabled={vehicleNumber===""}>
                            Search
                            {loader && <span className="ml-[14px]" ><Oval
                                height={30}
                                width={30}
                                color="#FFFFFF"
                                wrapperclassName=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#000000"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            /></span>}
                        </button>
                        {/* this button for mobile */}


                        <button 
                            className="sm:hidden  flex items-center justify-center w-full text-center text-black mt-4
                                bg-[#9FE870] py-2.5 sm:py-3 disabled:bg-[#EAEAEA] new1
                          rounded-[6px] md:rounded-[6px] mb-2 px-10 leading-2 text-[20px] sm:text-[30px] font-bold"
                            onClick={(e)=>searchHandler(e)} disabled={vehicleNumber===""}>
                            Search
                            {loader && <span className="ml-[14px]" ><Oval
                                height={30}
                                width={30}
                                color="#FFFFFF"
                                wrapperclassName=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#000000"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            /></span>}
                        </button>
                        <p className="text-center text-[20px] text-[#16330078] font-[400]">or</p>
                      
                        <button 
                            className="flex items-center justify-center w-full text-center text-black mt-4
                                bg-[#9FE870] py-2.5 sm:py-[12px]
                          rounded-[6px] md:rounded-[6px] mb-2 px-10 leading-2 text-[20px] sm:text-[30px] font-bold"
                            onClick={(e)=>continueHandler(e)}>
                            I Don&apos;t Know
                        </button>

     
                        {showDetails && <div className="mt-3">
                            <button 
                                className={`border text-left flex justify-between
                                    w-full text-[17px] md:text-[18px] px-[21px] md:px-[30px] sm:px-[51px] py-3
                                    sm:py-2 rounded-[6px] md:rounded-[25px] lg:mt-2.5 mt-5
                                    ${vehicleMake=="" ?  "text-gray-400 " : 'text-black '}`}
                                onClick={(e)=>{setOpenMake(true)}}                   
                            >{vehicleMake=="" ? 'Vehicle Make' : vehicleMake}
                                <img src="/assets/dropDown.svg" loading="lazy"
                                    className="md:mt-2 mt-2 md:w-auto w-5" />
                            </button>
                            {openMake && <ul className={`border absolute bg-white max-h-[200px] overflow-auto
                                    w-full text-[17px] md:text-[18px] px-[21px] md:px-[30px] sm:px-[51px] py-3 z-[20]
                                    sm:py-2 rounded-[6px] md:rounded-[15px] lg:mt-2.5 mt-5 customminiScrollbar`} >
                                <li className="cursor-pointer"
                                    onClick={()=>{setVehicleMake("Audi"),
                                    //setFilteredModel(vehicleRange.filter(item => item.Range === range))
                                    setOpenMake(false)}}
                                >
                                    Audi
                                </li>
                                <li className="cursor-pointer"
                                    onClick={()=>{setVehicleMake("BMW"),
                                    //setFilteredModel(vehicleRange.filter(item => item.Range === range))
                                    setOpenMake(false)}}
                                >
                                    BMW
                                </li>
                            </ul>}

                            {/* {error.vehicleMake!=="" && <ErrorMessage message={error.vehicleMake}
                                className="font-normal pt-1 email-err-msg text-[#984848] text-[17px] 
                                        leading-normal">
                            </ErrorMessage>} */}

                            {/* range */}
                            <div className="mt-3"> 
                                <button 
                                    className={`border text-left flex justify-between
                                    w-full text-[17px] md:text-[18px] px-[21px] md:px-[30px] sm:px-[51px] py-3
                                    sm:py-2 rounded-[6px] md:rounded-[25px] lg:mt-2.5 mt-5
                                    ${selectedVehicleRange=="" ?  "text-gray-400 " : 'text-black '}`}
                                    onClick={(e)=>{fetchRange(e)}} >
                                    {selectedVehicleRange=="" ? 'Vehicle Range' : selectedVehicleRange}
                                    {loading ? <span ><Oval
                                        height={30}
                                        width={30}
                                        color="#FFFFFF"
                                        wrapperclassName=""
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#000000"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                    /></span> : <img src="/assets/dropDown.svg" loading="lazy"
                                        className="md:mt-2 mt-2 md:w-auto w-5" />}
                                </button>
                                {open && <ul className={`border absolute bg-white max-h-[200px] overflow-auto
                                    w-full text-[17px] md:text-[18px] px-[21px] md:px-[30px] sm:px-[51px] py-3 z-[20]
                                    sm:py-2 rounded-[6px] md:rounded-[15px] lg:mt-2.5 mt-5 customminiScrollbar`} >
                                    {vehicleModels.map((range)=>
                                        <li  key={range.Range} className="cursor-pointer"
                                            onClick={()=>{setSelectedVehicleRange(range),
                                            setFilteredModel(vehicleRange.filter(item => item.Range === range))
                                            setIsOpen(false)}}
                                        >
                                            {range}
                                        </li>)}
                                </ul>}
                            </div>
                            {error.vehicleModel!=="" && <ErrorMessage message={error.vehicleRange}
                                className="font-normal pt-1 email-err-msg text-[#984848] text-[17px] 
                                        leading-normal">
                            </ErrorMessage>}

                            <div className="mt-3"> 
                                <button 
                                    className={`border text-left flex justify-between
                                    w-full text-[17px] md:text-[18px] px-[21px] md:px-[30px] sm:px-[51px] py-3
                                    sm:py-2 rounded-[6px] md:rounded-[25px] lg:mt-2.5 mt-5
                                    ${selectedVehicleModel=="" ?  "text-gray-400 " : 'text-black '}`}
                                    onClick={(e)=>{fetchModel(e)}} >
                                    {selectedVehicleModel=="" ? 'Vehicle Model' : selectedVehicleModel}
                                    {/* {loading ? <span ><Oval
                                        height={30}
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#000000"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                    /></span> : <img src="/assets/dropDown.svg" loading="lazy"
                                        className="md:mt-2 mt-2 md:w-auto w-5" />} */}
                                    <img src="/assets/dropDown.svg" loading="lazy"
                                        className="md:mt-2 mt-2 md:w-auto w-5" />
                                </button>
                                {openModel && <ul className={`border absolute bg-white max-h-[200px] overflow-auto
                                    w-full text-[17px] md:text-[18px] px-[21px] md:px-[30px] sm:px-[51px] py-3 z-[20]
                                    sm:py-2 rounded-[6px] md:rounded-[15px] lg:mt-2.5 mt-5 customminiScrollbar`} >
                                    {filteredModel.map((model)=>
                                        <li  key={model.Model} className="cursor-pointer"
                                            onClick={()=>{setSelectedVehicleModel(model.Model),
                                            setIsOpenModel(false)}}
                                        >
                                            {model.Model!=="Unspecified" && model.Model}
                                        </li>)}
                                </ul>}
                            </div>
                            {error.vehicleModel!=="" && <ErrorMessage message={error.vehicleModel}
                                className="font-normal pt-1 email-err-msg text-[#984848] text-[17px] 
                                        leading-normal">
                            </ErrorMessage>}

                            <Listbox as='div' value={selectedVehicleYear} className={`mt-3`}
                                onChange={setSelectedVehicleYear}> 
                                <Listbox.Button className={`border text-left flex justify-between
                                    w-full text-[17px] md:text-[18px] px-[21px] md:px-[30px] sm:px-[51px] py-3
                                    sm:py-2 rounded-[6px] md:rounded-[25px] lg:mt-2.5 mt-5   
                                    ${selectedVehicleYear=="" ?  "text-gray-400 " : 'text-black '}`} >
                                    {selectedVehicleYear=="" ? 'Vehicle Year' : selectedVehicleYear}
                                    <img src="/assets/dropDown.svg" className="md:mt-2 mt-2 md:w-auto w-5" loading="lazy" />
                                </Listbox.Button>
                                <Listbox.Options className={`border absolute bg-white max-h-[200px] overflow-auto
                                    w-full text-[17px] md:text-[18px] px-[21px] md:px-[30px] sm:px-[51px] py-3
                                    sm:py-2 rounded-[6px] md:rounded-[15px] lg:mt-2.5 mt-5 customminiScrollbar `}      >
                                    {years.map((year)=><Listbox.Option key={year} value={year} className="cursor-pointer" >
                                        <p>{year}</p>
                                    </Listbox.Option>)}
                                </Listbox.Options>
                            </Listbox>

                            {error.vehicleYear!=="" && <ErrorMessage message={error.vehicleYear}
                                className="font-normal pt-1 email-err-msg text-[#984848] text-[17px]  
                                        leading-normal">
                            </ErrorMessage>}

                        </div>}
                        <div className="mt-[20px]" >
                            {/* <button 
                                className="flex items-center justify-center w-full text-center text-black bg-[#9FE870] py-2.5
                         sm:py-3 rounded-[6px] md:rounded-[25px] mb-2 px-10 leading-2 sm:text-[22px] text-[18px] font-bold" 
                                onClick={(e)=>vehicleDetailsHandler(e)}>
                                Find  Vehicle
                                {loader && <span className="ml-[14px]" ><Oval
                                    height={30}
                                    width={30}
                                    color="#FFFFFF"
                                    wrapperclassName=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#000000"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                /></span>}
                            </button> */}
                            <button 
                                className="flex items-center justify-center w-full text-center text-black 
                                bg-[#9FE870] sm:py-3 py-2.5 disabled:bg-[#EAEAEA]
                            rounded-[6px] md:rounded-[25px] mb-2 px-10 leading-2 sm:text-[27px] text-[20px] mt-[5px] font-bold"
                                onClick={(e)=>submitHandler(e)}
                                disabled={vehicleMake==="" || selectedVehicleModel==="" || 
                                    selectedVehicleYear===""}>
                                Submit
                                <span><img src="/assets/right-arrow.svg" className="ml-[14px]" alt="right arrow" loading="lazy" /></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </AnimatedPage>
    );
}