import React from "react";
import { Link } from "react-scroll";

export default function Footer(){

    return(
        <footer className="bg-[#00330B] text-white sm:px-[10px] new1 fade">
            <div className="flex justify-center items-center xl:py-[40px] md:py-10 sm:py-5 py-4">
                <img src="/assets/footerLogo.png" alt="Calimeo logo" className="w-[160px]" loading="lazy"/>
            </div>
            <div className="px-[14px] xl:px-[75px] lg:px-[50px] sm:px-10 pb-5">
                <div className=" text-center ">
                    <p className="mb-[23px] font-normal sm:text-[17px] sm:leading-[26px] text-[15px] leading-[18px]
                         text-[#fff]">
                        <span className="lg:block hidden">
                            Claimeo is a registered trading name of RM Legal Solutions Ltd. RM Legal 
                            Solutions Ltd is registered in the United Kingdom under licence
                            <br /> number 14113873 with its registered office located at 17 Duke Street, Formby, 
                            Liverpool, Merseyside, L37 4AN.   
                        </span>
                        <span className="lg:hidden block">
                            Claimeo is a registered trading name of RM Legal Solutions Ltd. RM Legal Solutions
                            Ltd is registered in the United Kingdom under licence
                            number 14113873 with its registered office located at 17 Duke Street, Formby, 
                            Liverpool, Merseyside, L37 4AN.

                        </span>
                    </p>
                    <p className="md:mb-[32px] sm:mb-6 mb-4 text-center sm:text-lg text-[15px]">
                        © 2023 - present, RM Legal Solutions Ltd. All rights
                        reserved.
                    </p>
                </div>
                <div className="flex justify-center flex-wrap  px-[41px] ">
                
                    <ul className="text-center sm:flex justify-center">
                        <li className="underline mb-3 px-6 text-white text-sm leading-5  ">
                            <a href="/privacy-policy" target='_blank' rel="noreferrer">Privacy Policy</a>
                        </li>
                        <li className="underline  px-6 text-white mb-3 text-sm   ">
                            <a href="/terms-and-conditions" target='_blank' rel="noreferrer">Term of use</a>
                            
                        </li>
                        <li className="underline px-6 text-white mb-3 text-sm cursor-pointer">
                            <Link
                                to="about"
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                About
                            </Link>
                        </li>
                    </ul>
                    <ul className="text-center sm:flex justify-center">
                        <li className="underline  px-6 text-white mb-3 text-sm leading-5 cursor-pointer">
                            <Link
                                to="faq"
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                FAQs
                            </Link>
                        </li>

                        <li className="underline  px-6 text-white mb-3 text-sm  cursor-pointer">
                            <Link
                                to="howToClaim"
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                How To Claim
                            </Link>
                        </li>
                        <li className="underline  px-6 text-white mb-3 text-sm  cursor-pointer">
                            <Link
                                to="issues"
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                Eligibility
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}
