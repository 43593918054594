import React from "react";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import AnimatedPage from "../../components/Animation/AnimatedPage";
import { useDispatch } from "react-redux";

export default function Processing(){

    const Navigate=useNavigate();
    const dispatch=useDispatch();
    //const isSmallScreen = window.matchMedia('(min-width:641px)').matches;

    return(
        <AnimatedPage>
            <div className="max-h-screen min-h-screen overflow-auto md:pb-10 sm:pb-8 py-4 md:px-14 sm:px-10 px-4 bg-[#9FE87054]">
                <div className="flex justify-center flex-col">
                    <div className="flex justify-center">
                        <img src="/assets/logo.png" alt="Claeimo logo" 
                            loading="lazy" className="mx-auto h-[25px] md:mt-2"/>
                    </div>
                    <div className="md:mt-10 mt-5">
                        <div className="text-center xl:text-[85px] xl:leading-[87px] lg:text-[48px] lg:leading-[53px] 
                        md:text-[35px] md:leading-[40px] lg:tracking-[-0.8px] tracking-[-0.135px]
                         sm:text-[30px] text-[27px] leading-[31px] text-[#163300] font-[900] uppercase new" >
                         
                            <span className="md:block hidden">
                                Please wait while  <br/> we process your request</span>
                            <span className="md:hidden block">
                                Please wait while  <br/> we process your request</span>
                            
                        </div>
                        <div className="loader-container md:mt-10 xl:mt-14 mt-2" >
                            <Oval
                                height={200}
                                width={200}
                                color="#163300"
                                wrapperclassName=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#163100"
                                strokeWidth={6}
                                strokeWidthSecondary={5}
                            />
                        </div>

                        {/* <div className="w-full mx-auto 2xl:w-[75%] xl:w-[66%] lg:w-[90%] md:w-[95%]">
                            <p className="mt-5 text-center leading-normal sm:px-0 px-[40px]  md:text-[25px] 
                        text-[18px] uppercase font-medium">
                                <span className="md:block hidden">
                                    Please wait while  <br/> we process your request</span>
                                <span className="md:hidden block">
                                    Please wait while  <br/> we process your request</span></p>
                        </div> */}
                   
                    </div>
                    {/* <div className=" md:mb-[45px] mb-6 flex justify-center mt-3">
                
                        <img src="/assets/star.png" className="md:w-[160px] sm:w-[130px] w-[90px] md:h-[160px] 
                        sm:h-[130px] h-[90px]" alt="Star" />
            
                    </div>
                    <div className="xl:w-[60%] md:w-[80%] mx-auto w-full ">
                        <button 
                            className="flex justify-center items-center w-full text-center text-[#FFF] bg-[#163300] md:py-3 py-2.5
                        rounded-[6px] sm:rounded-[25px] mb-2 px-10 leading-2 text-[22px] font-bold" 
                            onClick={(e)=>submitHandler(e)}>
                            Done
                        </button>
                    </div> */}
                    <div className="flex justify-center pt-[24px] sm:pt-[25px] pb-[103px] mt-[350px]">
                        <img src="/assets/trustpilot.png" alt="trustPilot logo" loading="lazy" />
                    </div>
                </div>
            </div>
        </AnimatedPage>
    );
}
