import './App.css';
import React from 'react';
import {motion} from 'framer-motion'
import NavigationRoutes from './routes';
import ErrorHandler from './components/ErrorBoundary';
import LandingPage from './pages/landingPage';
function App(props) {
    const navigationPath = NavigationRoutes(props);
    return (
        <>
            {navigationPath}
        </>
    );
}

export default App;
