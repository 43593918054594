import React, { useState } from "react";
import ProgressBar from "../../components/progressBar";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "../../components/Animation/AnimatedPage";
import { useDispatch, useSelector } from "react-redux";
import { setFieldValue } from "../../redux/GlobalSlice";
import ErrorMessage from "../../components/ErrorMessage";

export default function OtherLender(){
    const Navigate=useNavigate();
    const dispatch=useDispatch();
    const claimData=useSelector((state)=>state.claimData);
    const [error,setError]=useState(false);
    const [lenders,setLenders]=useState(claimData.lenders)
    const [lenderObject, setLenderObject]=useState([])

    const clickHandler=(e)=>{
        const value=e.target.value;
        setError(false);
        if(lenders?.includes(value)){
            const filteredLenders=lenders?.filter((item)=>item!==value)
            const filetredSelectedLenders = lenderObject?.filter((item)=>item!==value)
            setLenderObject(filetredSelectedLenders)
            setLenders(filteredLenders);
        }else{
            setLenders((prev)=>([...prev,value]))
            setLenderObject((prev)=>([...prev,value]))
        }
        
    }

    const continueHandler=async(e)=>{
        e.preventDefault();
        if(lenders.length==0){
            setError(true);
        }else{
            dispatch(setFieldValue({field: 'lenders',value: lenders }))
            let objectoflenders=[];
            lenderObject.map((lender, index)=>{
                objectoflenders=[...objectoflenders,{ name : lender } ]
            })
            dispatch(setFieldValue({field: 'credit',value: [...claimData.credit, ...objectoflenders] }))

            Navigate('/claim/vehicle-details')
        }
    }

    return(
        <AnimatedPage>
            <ProgressBar barWidth="w-[100%]" />
            <div className="md:px-[17%] lg:px-[23%] xl:px-[17%] px-[30px]">
               
                <p className="xl:text-[55px] xl:leading-[60px] lg:text-[60px] sm:flex flex-col
                    lg:leading-[52px]  md:text-[55px] sm:tracking-[-0.8px]  md:leading-[47px] sm:text-[46px] 
                    sm:leading-[42px] text-[27.5px] new tracking-[-1.8px]
                    text-[#163300] font-[900] uppercase text-center mt-[60px] ">
                    <span>OTHER LENDERS</span>
               
                </p> 
                <p className="xl:text-[23px] xl:leading-[27px] lg:text-[25px] new1
                    lg:leading-[39px]  md:text-[20px] md:leading-[18px] sm:text-[17px] 
                    text-[17.5px] leading-[18px] text-[#163300] font-[500] text-center mt-2 font-rregular
                    sm:tracking-[-0.19px] tracking-[-0.09px]"
                >
                    You  can pick one or multiple  lenders.
                </p> 
                
                <div className="xl:w-[80%] w-full mx-auto">
                    <div class="flex flex-col justify-center mx-auto gap-2 
            handleYes mt-6">
                        <div className="customh1 overflow-auto overflow-x-hidden customScrollbar overflow-y-auto">
                            <div className="flex mb-0.5 flex-wrap lg:-mx-3 -mx-1.5 px-[15px] ">
                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Advantage') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                   
                                            <img src="/assets/AF.jpg" loading="lazy"
                                                className=" mx-auto xl:w-[130px] w-auto" alt="AdvantageFinance" />
                                        </div>
                                        <input type="checkbox" className="hidden" value="Advantage" />
                                    </label>
                                </div>
                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Aldermore') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/Aldermore.jpg " 
                                                className="xl:w-[130px] w-auto mx-auto" alt="Aldermore" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Aldermore"  />
                                    </label>
                                </div>
                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Alphabet') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/Alphabet.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto " alt="Alphabet" />
                                       
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Alphabet"  />
                                    </label>
                                </div>
                               
                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Audi') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/audiF.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="AudiFinance" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Audi"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Barclay') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/Barclay.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="Barclay" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Barclay"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Billing') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/BillingFinance.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="BillingFinance" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Billing"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Blackhorse') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/blackhorse.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto " alt="Blackhorse" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Blackhorse"  />
                                    </label>
                                </div>


                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Bluemotor') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/Bluedark.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="BlueMotor" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Bluemotor"  />
                                    </label>
                                </div>


                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('BMW') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/bmw2.svg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="BMW" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="BMW"  />
                                    </label>
                                </div>


                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('CloseBrothers') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/CloseBrothers.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="CloseBrothers" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="CloseBrothers"  />
                                    </label>
                                </div>


                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('ClydesdaleBank') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/ClydesdaleBank.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="ClydesdaleBank" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="ClydesdaleBank"  />
                                    </label>
                                </div>


                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('creation') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/creation.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="creation" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="creation"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('FCA') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/FCAutomobiles.jpg  " 
                                                className="xl:w-[130px] w-auto mx-auto" alt="FCA" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="FCA"  />
                                    </label>
                                </div>


                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Ford') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/Ford.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto xl:h-[100px]" alt="Ford" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Ford"  />
                                    </label>
                                </div>


                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('firstplus') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/firstplus.svg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="firstplus" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="firstplus"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Hitachi') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/Hitachi.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="Hitachi" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Hitachi"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Honda') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/HONDA.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="HONDA" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Honda"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Capital Hyundai') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/HYUNDAI.jpg " 
                                                className="xl:w-[130px] w-auto mx-auto" alt="Capital Hyundai" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Capital Hyundai"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Hyundai') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/HYUNDAIII.jpg " 
                                                className="xl:w-[130px] w-auto mx-auto" alt="Hyundai" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Hyundai"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('JAGUAR') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/JAGUAR.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="JAGUAR" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="JAGUAR"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('LandRover') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/LANDROVER.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="LandRover" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="LandRover"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Lex Autolease') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/LEX.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="Lex" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Lex Autolease"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Lexus') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/LEXUS.jpg " 
                                                className="xl:w-[130px] w-auto mx-auto" alt="Lexus" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Lexus"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Benz') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/BENZ.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="Benz" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Benz"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('MoneyBorn') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/MONEYBARN.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="MoneyBorn" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="MoneyBorn"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('NORTHBRIDGE') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/NORTHBRIDGE.jpg " 
                                                className="xl:w-[130px] w-auto mx-auto" alt="NORTHBRIDGE" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="NORTHBRIDGE"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('OODLE') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/OODLE.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="OODLE" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="OODLE"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('PCF BANK') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/PCF.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="PCF" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="PCF BANK"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('PSA') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/PSA.jpg  " 
                                                className="xl:w-[130px] w-auto mx-auto" alt="PSA" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="PSA"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('RATESETTER') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/RATESETTER.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="RATESETTER" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="RATESETTER"  />
                                    </label>
                                </div>


                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('RCI') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/RCI.jpg " 
                                                className="xl:w-[130px] w-auto mx-auto" alt="RCI" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="RCI"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('SATANDAR') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/SATANDAR.jpg " 
                                                className="xl:w-[130px] w-auto mx-auto" alt="SATANDAR" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="SATANDAR"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('SECURETRUST') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/SECURETRUST.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="SECURETRUST" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="SECURETRUST"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('SHAWBROOK') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/SHAWBROOK.jpg " 
                                                className="xl:w-[130px] w-auto mx-auto" alt="SHAWBROOK" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="SHAWBROOK"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('SHOGUN') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/SHOGUN.jpg " 
                                                className="xl:w-[130px] w-auto mx-auto xl:h-[100px] " alt="SHOGUN" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="SHOGUN"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('Specialist Motor') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/SMF2.svg" 
                                                className="xl:w-[130px] w-[115px] mx-auto" alt="SMF" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="Specialist Motor"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('STELLANTIS') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/STELLANTIS.jpg  " 
                                                className="xl:w-[130px] w-auto mx-auto" alt="STELLANTIS" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="STELLANTIS"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('SUZUKI') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/SUZUKI.jpg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="SUZUKI" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="SUZUKI"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('TOYOTA') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/Vector.svg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="TOYOTA" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="TOYOTA"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('VAUXHALL') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/vaxxhell.svg" 
                                                className="xl:w-[130px] w-auto mx-auto" alt="VAUXHALL" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="VAUXHALL"  />
                                    </label>
                                </div>
                                <div className="lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3 xl:block hidden"></div>
                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('GMAC') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/GMAC.jpg  " 
                                                className="xl:w-[130px] w-auto mx-auto" alt="GMAC" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="GMAC"  />
                                    </label>
                                </div>

                                <div className="flex lg:px-3 px-1.5 xl:w-[25%] w-[50%] sm:mb-4 mb-3">
                                    <label className={`w-full md:p-8 sm:p-6 p-5 h-[120px] 
                                    ${lenders?.includes('VOLVE') ? 'bg-[#9FE870]' : "bg-[#ECECEC] "}
                                        lg:rounded-[25px] rounded-[6px]`} onChange={(e)=>{clickHandler(e)}}>
                                        <div className={` w-full xl:w-full h-full flex justify-center 
                                        items-center  
                                            mx-auto lg:rounded-[25px] rounded-[6px] cursor-pointer
                                        `}>
                                            <img src="/assets/VOLVE.jpg " 
                                                className="xl:w-[130px] w-auto mx-auto" alt="VOLVE" />
                                        </div>
                                        <input type="checkbox" className="hidden"  value="VOLVE"  />
                                    </label>
                                </div>
                            </div>
                        
                        </div>
                    
                    </div>

                </div>
                {error && <ErrorMessage message="This field is mandatory" 
                    className="font-normal mt-4 email-err-msg text-[#984848] text-[17px]
                    new1 text-center
                        leading-normal" ></ErrorMessage>}
                <div className="flex justify-center md:mt-8 sm:mt-6 mt-4">
                    <button 
                        className=" sticky  flex items-center justify-center lg:w-[60%]
                 sm:w-[80%] w-full lg:text-[27px] text-[20px] new1
            font-bold sm:h-[67px] h-[40px] text-center text-[#163300] bg-[#9FE870] 
            rounded-[6px] sm:rounded-[25px] mb-2 px-10 leading-2" data-testid="continue-btn" 
                        onClick={(e)=>continueHandler(e)}>
                        Continue
                        <span><img src="/assets/right-arrow.svg" className="ml-[14px]" alt="right arrow" /></span>
                    </button>
                </div>
            </div>
        </AnimatedPage>
    );
}