/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from 'react-webcam';
import { useDispatch, useSelector } from "react-redux";
import { setFieldValue } from "../../redux/GlobalSlice";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "../../components/Animation/AnimatedPage";
import ErrorMessage from "../../components/ErrorMessage";
import { dataService } from "../../services/data.services";
import { pushProfilePhotoToS3 } from "../../redux/helper";

export default function ProofOfIdentification(){

    const claimData=useSelector(state=>state.claimData)
    const [files,setFiles]=useState([]);
    const [images,setImages]=useState([]);
    const Navigate=useNavigate();
    const webcamRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [size,setSize]=useState("");
    const [image,setImage]=useState(false);
    const [fileError,setFileError]=useState("")
    const dispatch=useDispatch();
    let filesarray=[];
    const [proof, setProof]= useState('')

    useEffect(()=>{
        dispatch(setFieldValue({field: "files", value: files}))
    },[])

    /**
     * The function `handleFileUpload` is used to handle the upload of files, specifically for
     * validating the number and size of selected files and updating the state accordingly.
     * @returns The function is not returning anything.
     */

    const handleImageUpload = async (event) => {
        const selectedImages = Array.from(event.target.files);
        const allowedFileTypes = [
            'image/jpeg',
            'image/png',
            'application/pdf',
        ];
        if(selectedImages.length>5 || claimData.files.length+selectedImages.length>5){
            setFileError("You can only upload maximum of 5 attachments")
            return;
        }
        const uploadedImages = [];
        //setImageLoader(true)
        const uploadPromises = selectedImages.map(async (selectedImage) => {
            if(!allowedFileTypes.includes(selectedImage.type.toLowerCase())){
                setFileError("Unsupported file format")
                //setImageLoader(false);
            }
            else if (selectedImage.size <= 10 * 1024 * 1024) {
                setFileError("")
                dispatch(setFieldValue({field: "files", value: [...claimData.files, {name: selectedImage.name,size: (selectedImage.size / (1024 * 1024)).toFixed(2), url: URL.createObjectURL(selectedImage), }]}))
                setFiles([...files, selectedImage]);
            } else {
                setFileError("File size exceeds 10MB. Please upload a smaller file.")
                //setImageLoader(false);
            }
        });
    };


    /**
     * The `proceedHandler` function checks if the `proofOfIdentification` field in `claimData` is
     * empty, sets an error message if it is, and navigates to a confirmation page if it is not.
     * @returns The function `proceedHandler` returns nothing (undefined).
     */
    const proceedHandler=async(e)=>{
        e.preventDefault();
        if(files.length==0){
            setFileError("This field is mandatory")
            return;
        }
        if(files.length > 0) {
            files.map(async (file,index)=>{
                let body={};
                if(index===files.length-1){
                    body={
                        "email_address": claimData.emailAddress,
                        "id": claimData.clientReference,
                        "id_proof": [
                            {
                                "file_name": file.name,
                                "file_path": `identity-proof/${claimData.clientReference}`
                            }
                        ],
                        "response": claimData.response,
                        "title": claimData.title,
                        "vehicleType": claimData.vehicle,
                        "firstName": claimData.firstName,
                        "lastName": claimData.lastName,
                        "mobile": claimData.phoneNumber,
                        "email": claimData.emailAddress,
                        "dateOfBirth": claimData.dateOfBirth,
                        "postCode": claimData.postalCode,
                        "address" : claimData.address,
                        "vehicleInformation": claimData.lenderInfo,

                    }
                }else{
                    body={
                        "id_proof": [
                            {
                                "file_name": file.name,
                                "file_path": `identity-proof/${claimData.clientReference}`
                            }
                        ]
                                   
                    }
                }
                const response = await dataService.SubmitDocuments(claimData.emailAddress,claimData.clientReference,body);
                filesarray.push(response.data.get_url[0])
                setProof([...proof, response.data.url[0]])
                const urlP= response.data.get_url
                const res = await pushProfilePhotoToS3(response.data.url, file);
               
                if(res.status === 200){
                    dispatch(setFieldValue({ field: "proofOfIdentification", 
                        value:[filesarray]
                    }));
                    

                }
            })
        }
        Navigate("/claim/identity-proof-confirmation")
    }

    /**
     * The function captures an image from a webcam and adds it to an array of images in the claimData
     * object.
     */
    const captureImage = (e) => {
        e.preventDefault();
        const imageSrc = webcamRef.current.getScreenshot();
        setImageSrc(imageSrc);
    };

    /**
     * The `uploadHandler` function updates the `proofOfIdentification` field in the `claimData` object
     * with the selected files and resets the image source and visibility.
     */
    const uploadHandler=async(e)=>{
        e.preventDefault();
        setImage(false);
        const base64Data = imageSrc.split(',')[1];
        const blob = await fetch(`data:image/jpeg;base64,${base64Data}`).then((res) =>
            res.blob()
        );
        setSize((blob.size / (1024 * 1024)).toFixed(2));
        const allFiles={image:imageSrc, size: (blob.size / (1024 * 1024)).toFixed(2)}
        setImages([...images,allFiles])
        // const allFiles=[...claimData.proofOfIdentification]
        // dispatch(setFieldValue({ field: "proofOfIdentification", value: allFiles }));
        setImageSrc(null)
    }

    /**
     * The function `cancelFile` is used to remove a specific file from an array of files and update
     * the state with the filtered files.
     */
    const cancelFile=(e,file)=>{
        e.preventDefault();
        const filteredFiles=claimData.files.filter((files)=>files.name!==file)
        dispatch(setFieldValue({field: "files", value: filteredFiles}))
        setFiles(filteredFiles);
        setFileError("")
    }


    return(
        <AnimatedPage>
            <div className="sm:mt-0 mt-5">
                <div className="lg:mt-[92px] text-center h-[13px] bg-[#EAEAEA] relative lg:w-[72%] sm:hidden w-full mx-auto">
                    <div className={`bg-[#9FE870] h-[13px] rounded-tr-[20px] rounded-br-[20px] w-[100%] sm:hidden
                     absolute top-0 left-0`} style={{backgroundColor:"#9FE870"}}></div>
                </div>
                <div className="md:px-10 sm:px-7 px-4 mx-auto lg:w-[75%] md:w-[95%] w-full">
                    <p className="xl:text-[45px] xl:leading-[55px] lg:text-[41px] lg:leading-[46px] md:text-[35px] md:leading-[40px] sm:text-2xl 
                    text-[27px] leading-[31px] new
                     text-[#163300] font-[900] text-center mt-4 mb-4 tracking-[-1.8px] sm:tracking-[-0.8px]">
                        <span className="md:block hidden">UPLOAD YOUR PROOF<br/> OF IDENTIFICATION</span>
                        <span className="md:hidden block">UPLOAD YOUR PROOF<br/> OF IDENTIFICATION</span>
                    </p>
                    
                    {/* <div className="mt-3 w-[85%] mx-auto"> */}
        
                    {/* <div className="relative flex justify-between outline-0 border border-[#224F00] border-dashed 
                        bg-[#FFFFFF] w-full h-[233px] md:h-[202px]  text-center  rounded-lg text-xs 
                        font-normal text-[#000103] py-7"> */}
                    {image ? <div className="mt-6 mx-5" >
                        {imageSrc ? (
                            <div>
                                <img src={imageSrc} alt="Captured" loading="lazy" />
                                <button onClick={(e)=>uploadHandler(e)} 
                                    className="flex items-center justify-center new1 w-full text-center text-black bg-[#9FE870] py-5
                                        rounded-[6px] sm:rounded-[25px] mb-2 px-10 leading-2 text-[20px] sm:text-[27px] mt-5"
                                >Upload</button>
                                <button onClick={(e)=>{e.preventDefault();setImage(false);setImageSrc(null)}} 
                                    className="flex items-center justify-center w-full text-center text-black bg-[#9FE870] py-5
                                            rounded-[6px] mb-2 px-10 new1 leading-2 text-[20px]  sm:text-27px] mt-5"
                                >Cancel</button>
                            </div>
                        ) : (
                            <div>
                                <button onClick={handleClick}>Switch camera</button>
                                <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={{
                                        ...videoConstraints,
                                        facingMode,
                                    }}
                                />
                                <button onClick={(e)=>captureImage(e)} 
                                    className="flex items-center justify-center w-full text-center text-black bg-[#9FE870] py-5
                                            rounded-[6px] mb-2 px-10 leading-2 new1 text-[20px] mt-5"
                                >Capture Image</button>
                                <button onClick={(e)=>{e.preventDefault();setImage(false)}} 
                                    className="flex items-center justify-center w-full text-center text-black bg-[#9FE870] py-5
                                            rounded-[6px] mb-2 px-10 leading-2 new1 text-[20px] mt-5"
                                >Cancel</button>
                            </div>
                        )}
                    </div> : <div className="mt-6 xl:w-[60%] md:w-[80%] w-full mx-auto">
        
                        <div className={`relative flex flex-wrap justify-between outline-0 border border-[#224F00] border-dashed 
                        bg-[#FFFFFF] w-full  text-center  rounded-lg text-xs font-normal text-[#000103] py-7 
                        `}>
                        
                            <div className="sm:border-r sm:mt-0 sm:w-[50%] flex justify-center  border-[#AAAAAA]  relative items-center flex-col md:py-[50px] py-6 mx-auto">
                                <div className=" flex justify-center items-center">
                                    <input type="file" id="uploadFile" 
                                        className={` ${files.length >0 || images.length > 0 ? 'hidden sm:block' : ''} 
                                        outline-0 bg-[#f8f7f7] w-full h-full text-center  text-xs opacity-0
                                        font-normal text-[#000103] cursor-pointer px-4  absolute top-0 left-0`}
                                        multiple onChange={(e)=>handleImageUpload(e)}/>
                                    <div  className={`${files.length >0 || images.length > 0 ? 'hidden sm:block' : ''} `}>
                                        <img src="/assets/cloud.svg" alt="upload" loading="lazy" />
                                    </div>
                            
                                </div>
                                <p htmlFor="uploadFile" className={`${files.length >0 || images.length > 0 ? 'hidden sm:block' : ''} 
                                text-[15px] text-center font-medium text-[#1633005C] pt-5 new1 `}>
                                    Drag And Drop Your Files Here</p>
                            </div>
                            <div className="md:w-[50%] w-full flex justify-center items-center flex-col">
                                {claimData.files.map((file,key)=><div className="px-[10px] w-full" 
                                    key={key}>
                                    <div className="flex gap-4 items-center px-[10px] py-[7px] bg-[#B8E29D59] mt-3 
                                    rounded-[12px] new1 w-full relative">
                                        <span>
                                            <img src='/assets/filee.png' alt="file Image" className="w-[31px]" loading="lazy" />
                                        </span>
                                        <div className="pt-2 ">
                                            <div className="flex justify-between gap-4 text-[13px]">
                                                <p className="">{file.name}</p>
                                                <p>{file.size} MB</p>
                                            </div>
                                            {/* <div className="h-2 relative bg-white w-full">
                                                <div className="absolute left-0 bg-[#9FE870] h-2 "></div>
                                            </div> */}
                                            <button onClick={(e)=>cancelFile(e,file.name)} 
                                                className="absolute top-[0] right-[0] bg-[#D9D9D9] 
                                                w-[15px] h-[15px] rounded-full cursor-pointer" >
                                                <img src='/assets/cancel.svg' alt="cancel Image" width={8} loading="lazy"
                                                    className="m-auto cursor-pointer"/></button>
                                        </div>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    
                        {fileError!=="" && <ErrorMessage message={fileError}
                            className=" pt-1 text-[#984848] text-[17px] new1
                                            leading-normal">
                        </ErrorMessage>}
                        <div className="mt-[28px] text-center cursor-pointer new1">
                            <div className="flex items-center justify-center w-full text-white font-bold
                            bg-[#163300] lg:py-3 py-4 rounded-[6px] md:rounded-[25px] lg:mb-2 mb-[20px] px-10 text-[20px]  sm:text-[27px]" >
                                <input type="file" id="uploadFile" 
                                    className=" outline-0 bg-[#141414] w-full h-full text-center  text-xs 
                                        font-normal text-[#000103] cursor-pointer opacity-0 z-[10]" 
                                    multiple onChange={(e)=>handleImageUpload(e)}/>
                                <p className="absolute" >Choose Files</p>
                            </div>

                            <div className="sm:hidden  flex items-center justify-center w-full text-white font-bold new1
                            bg-[#163300] lg:py-3 py-4 rounded-[6px] md:rounded-[25px] lg:mb-2 mb-[20px] px-10 text-[20px]  sm:text-[27px]" >
                                <input accept="image/*" id="icon-button-file" type="file" capture="environment" className=" outline-0 bg-[#141414] w-full h-full text-center  text-xs 
                                        font-normal text-[#000103] cursor-pointer opacity-0 z-[10]" onChange={(e)=>handleImageUpload(e)}  />
                                <p className="absolute" >Camera</p>
                            </div>
                        
                            {/* <button 
                                className="sm:hidden  flex items-center justify-center w-full text-center text-white bg-[#163300] py-3
                            rounded-[6px] md:rounded-[25px] mb-[37px] px-10 text-lg mt-[5px] font-bold"
                                onClick={(e)=>handleCamera(e)}>
                                Camera
                            </button> */}

                            <button 
                                className="flex items-center justify-center w-full text-center text-black bg-[#9FE870] py-2.5
                            rounded-[6px] md:rounded-[25px] mb-2 px-10 text-[20px]  sm:text-[27px] font-bold" 
                                onClick={(e)=>proceedHandler(e)}>
                                Proceed
                            </button>
                        </div>
                    </div>}
                </div> 
            </div>
        </AnimatedPage>
    );
}