import React from "react";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "../../components/Animation/AnimatedPage";
import { useSelector } from "react-redux";

export default function ThankYou(){

    const Navigate=useNavigate();
    const claimData=useSelector(state=>state.claimData);

    

    /**
     * The submitHandler function prevents the default form submission behavior and navigates to the
     * '/claim/identity-proof' route.
     */
    const submitHandler=(e)=>{
        e.preventDefault();
        Navigate('/claim/identity-proof')
    }

    return(
        <AnimatedPage>
            <div className="bg-[#9FE87054] max-h-screen min-h-screen overflow-auto md:pb-10 sm:pb-8 py-4 md:px-14 sm:px-10 px-4">
                <div className="flex justify-center flex-col">
                    <div className="flex justify-center ">
                        <img src="/assets/logo.png" alt="Claeimo" className="mx-auto md:mt-2 h-[25px]" loading="lazy"/>
                    </div>
                    <div className="mt-8">
                        <div className="xl:text-[45px] xl:leading-[55px] lg:text-[41px] lg:leading-[46px] 
                            md:text-[35px] md:leading-[40px] sm:text-2xl text-[27px] text-[#163300] font-[900]
                             tracking-[-0.135px]
                             sm:tracking-[-0.8px]
                            text-center new" >THANK YOU</div>
                        <div className="w-full mx-auto 2xl:w-[75%] xl:w-[66%] lg:w-[55%] md:w-[95%] new1">
                            <p className="mt-7 text-center sm:leading-[35px] md:text-[30px] tracking-[-0.09px]
                        text-[15px] leading-[20px] font-medium mb-2">
                                <span className="lg:block hidden">Thank you for submitting the vehicle details.<br/>
                                    Your information have been successfully saved.</span>
                                <span className="lg:hidden block">Thank you for submitting the vehicle details.<br/>
                                    Your information have
                                    been successfully saved.</span></p>
                        </div>
                   
                    </div>
                    <div className="md:mb-[45px] mb-6 flex justify-center mt-5">
                
                        <img src="/assets/star.png" className="md:w-[160px] sm:w-[130px] w-[90px] md:h-[160px] 
                        sm:h-[130px] h-[90px]" alt="Star" loading="lazy" />
            
                    </div>
                    <div className="mx-auto sm:w-1/2 w-full new1">
                        <button 
                            className="flex justify-center items-center w-full text-center text-[#163300]] bg-white md:py-3 py-2.5
                           rounded-[6px] sm:rounded-[25px] mb-3 px-10 leading-2 text-[20px] sm:text-[27px] font-bold"
                            onClick={(e)=>{e.preventDefault(); Navigate('/claim/select-lenders')}}>
                            <span><img src="/assets/leftButton.svg" className="mr-[14px]" 
                                alt="right arrow" loading="lazy"/></span>
                            Previous
                        </button>
                        <button 
                            className="flex justify-center items-center w-full text-center text-[#FFF] bg-[#163300] md:py-3 py-2.5
                        rounded-[6px] sm:rounded-[25px] mb-2 px-10 leading-2 text-[20px] sm:text-[27px] font-bold" 
                            onClick={(e)=>submitHandler(e)}>
                            Next
                            <span><img src="/assets/whiteArrow.svg" className="ml-[14px]" 
                                alt="right arrow" loading="lazy" /></span>
                        </button>
                    </div>
                    <div className="flex justify-center pt-[24px] sm:pt-[30px] pb-[103px]">
                        <img src="/assets/trustpilot.png" alt="trustPilot" loading="lazy" />
                    </div>
                </div>
            </div>
        </AnimatedPage>
    );
}
