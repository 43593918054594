import React from "react";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "../../components/Animation/AnimatedPage";
import { useSelector } from "react-redux";

export default function ThankYouComplete(){
    return(
        <AnimatedPage>
            <div className="bg-[#9FE87054] max-h-screen min-h-screen overflow-auto md:pb-10 sm:pb-8 py-4 md:px-14 sm:px-10 px-4">
                <div className="flex justify-center flex-col">
                    <div className="flex justify-center ">
                        <img src="/assets/logo.png" alt="Claeimo" className="mx-auto md:mt-2 h-[25px]" loading="lazy"/>
                    </div>
                    <div className=" md:mb-[45px] mb-6 flex justify-center mt-14 sm:mt-16">
                
                        <img src="/assets/star.png" className="md:w-[200px] sm:w-[130px] w-[120px] md:h-[200px] 
                        sm:h-[130px] h-[120px]" alt="Star image" loading="lazy" />
                    </div>
                    <div className=" mt-8 sm:mt-8">
                        <div className="text-center xl:text-[85px] xl:leading-[87px] lg:text-[48px] lg:leading-[53px] 
                        md:text-[35px] md:leading-[40px] tracking-[-0.5px]  sm:tracking-[-0.8px] 
                         sm:text-2xl text-[27px] text-[#163300] font-[900] uppercase new " >
                            THANK YOU
                        </div>
                        <div className="w-full mx-auto 2xl:w-[75%] xl:w-[66%] lg:w-[55%] md:w-[95%] new1">
                            <p className="mt-4 text-center md:leading-[40px] md:text-[30px] tracking-[-0.09px]
                        text-[15px] leading-[20px] font-medium mb-2">
                                <span className="lg:block hidden">We have received your information and we will be
                                    <br/> in contact shortly.
                                </span>
                                <span className="lg:hidden block">We have received your information and we will be
                                    in contact shortly.</span></p>
                        </div>
                   
                    </div>
                </div>
            </div>
        </AnimatedPage>
    );
}
