/* eslint-disable security/detect-object-injection */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    clientReference: "",
    response: "yes",
    vehicle: '',
    title: '',
    firstName: "",
    lastName: "",
    dateOfBirth: '',
    postalCode: '',
    address: '',
    emailAddress: '',
    phoneNumber: '',
    country: "",
    termsAccepted: true,
    signature: '',
    lenders: [],
    index: 0,
    lenderInfo: [],
    houseName: "",
    houseNumber: "",
    street:"",
    street2:"",
    proofOfIdentification: [],
    files: [],
    campaignSource: "",
    signatureUrl:"",
    trustPilot: [],
    credit:[]
}
/*   Slice is used to store the data in redux   */

const GlobalSlice = createSlice({

    name: 'claimData',
    initialState,
    reducers: {
        
        setFieldValue: (state, action) => {
            const { field, value } = action.payload;
            state[field] = value;
        },
        clearData: (state) => {
            Object.assign(state, initialState);
        }
    },
})
export const { setFieldValue, clearData } = GlobalSlice.actions;
export default GlobalSlice.reducer