import React, { useRef } from "react"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, Element } from 'react-scroll';
import AnimatedButton from "../../components/Animation/AnimatedButton";
import AnimatedDiv from "../../components/Animation/AnimationDiv";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFieldValue } from "../../redux/GlobalSlice";

export default function HowToClaim(){

    const deviceType = window.innerWidth > 1786 ? 'desktop' : window.innerWidth > 1250 ? 'tablet' : 'mobile';
    const carouselRef = useRef(null);
    const dispatch=useDispatch();
    const Navigate=useNavigate();
    const claimData=useSelector((state)=>state.claimData);


    const moveCarouselRight = (e) => {
        e.preventDefault();
        carouselRef.current.next();
    };
    
    const moveCarouselLeft = (e) => {
        e.preventDefault();
        carouselRef.current.previous();
    };

    const resp = {
        desktop: {
            breakpoint: { max: 3000, min: 1786 },
            items: 2,
            paritialVisibilityGutter: 60
        },
        tablet: {
            breakpoint: { max: 1786, min: 0 },
            items: 1.5,
            paritialVisibilityGutter: 50
        },
    };

    const vehicleCheckHandler=async(e)=>{
        e.preventDefault();
        
        const searchParams = new URLSearchParams(window.location.search);

       
        dispatch(setFieldValue({ field: 'campaignSource', value: {
            utm_source: searchParams.get('utm_source'),
            utm_medium: searchParams.get('utm_medium') ,
            utm_campaign: searchParams.get('utm_campaign') ,
            utm_term: searchParams.get('utm_term'),
            utm_content: searchParams.get('utm_content'),
        } }))

        

        Navigate('/claim/choose-vehicle-type',{state:{isNavigated:true}});
    }

    const vehicleCheckStartHandler=async(e)=>{
        e.preventDefault();
        
        const searchParams = new URLSearchParams(window.location.search);

       
        dispatch(setFieldValue({ field: 'campaignSource', value: {
            utm_source: searchParams.get('utm_source'),
            utm_medium: searchParams.get('utm_medium') ,
            utm_campaign: searchParams.get('utm_campaign') ,
            utm_term: searchParams.get('utm_term'),
            utm_content: searchParams.get('utm_content'),
        } }))

        

        Navigate('/claim',{state:{isNavigated:true}});
    }


    return(
        <Element name="howToClaim" className="element">
            <div className="flex items-start pt-[350px] fade fadeOut">
                <div className="lg:w-[50%]">
                    <div className="w-full justify-end">
                        <div className="text-left xl:pl-[130px] lg:pl-[100px] md:pl-[50px] sm:pl-10 pl-6"> 
                            <p className="xl:text-[60px] xl:leading-[65px] lg:text-[45px] lg:leading-[50px] md:tracking-[-0.8px] 
                            md:text-[38px] md:leading-[45px] sm:text-3xl text-2xl text-[#163300] font-[900] uppercase">
                                Join over <br/>2,000+ people</p>
                            <p className="text-[#163300] xl:text-[28px] xl:leading-[32px] md:text-2xl 
                            text-base md:pt-4 pt-2 lg:w-[80%] new1">
                                who have joined the class action worth £3b against Jaguar and Land Rover</p>
                        </div>
                    </div>
                    <div 
                        className="mb-[124px] xl:mt-[80px] lg:mt-[60px] xl:pl-[130px] lg:pl-[100px] 
                        md:pl-[50px] sm:pl-10 pl-6 lg:block hidden" >
                        <div className="flex justify-start items-center">
                            <button style={{width: 90, height: 90, borderRadius: 9999}}>
                                <img src='/assets/leftArrow.png' className="" loading="lazy" 
                                    onClick={(e)=>moveCarouselLeft(e)} /></button>
                            <button style={{width: 90, height: 90, background: '#E8E8E8', borderRadius: 9999, marginLeft: "24px"}}
                                onClick={(e)=>moveCarouselRight(e)} >
                                <img src='/assets/Vector.png' className="ml-7" loading="lazy"  /></button>
                        </div>
                           
                    </div>
                </div>
                <div className="w-[50%] lg:block hidden">
                    <div className="flex overflow-auto gap-7">
                        <Carousel ref={carouselRef}
                            ssr
                            deviceType={deviceType}
                            itemClass="image-item"
                            responsive={resp}
                        >
                            <div className="2xl:min-w-[444px] 2xl:max-w-[444px] h-full min-w-[326px]
                              bg-[#9FE870] rounded-3xl xl:px-[60px] px-[40px] xl:py-[39px] py-6 ml-7">
                                <div className="xl:w-[105px] xl:h-[105px] w-[100px] h-[100px] bg-white rounded-full 
                                flex justify-center items-center" >
                                    <img src='/assets/search.png' className="" loading="lazy" alt="Search image" />
                                </div>
                                <h1 className="xl:text-3xl lg:text-2xl text-xl lg:font-bold new font-semibold
                                 text-[#163300]  mt-4">
                                    Find Your Vehicle</h1>
                                <p className=" text-lime-950 xl:text-2xl lg:text-lg new1 text-base font-normal md:pt-3 pt-2" >
                                    Just enter registration and we&apos;ll find and check your vehicle.
                                </p>
                                <AnimatedButton handleClick={(e)=>vehicleCheckHandler(e)}
                                    buttonName="Get Started"
                                    className="w-[201px] lg:h-[50px] bg-lime-950 hover:opacity-[70%] 
                                rounded-3xl text-white xl:text-xl text-lg font-bold new1
                                leading-[35pxpx] mt-[27px]">
                                </AnimatedButton>
                            </div>
                            <div className="2xl:min-w-[444px] 2xl:max-w-[444px] h-full min-w-[326px]
                              bg-[#00330B] rounded-3xl xl:px-[60px] px-[40px] xl:py-[39px] py-6 ml-7">
                                <div className="xl:w-[105px] xl:h-[105px] w-[100px] h-[100px] bg-white rounded-full 
                                flex justify-center items-center" >
                                    <img src='/assets/file.png' loading="lazy" alt="file image" />
                                </div>
                                <h1 className="text-[#9FE870] xl:text-3xl lg:text-2xl text-xl lg:font-bold font-semibold mt-4">
                                    Simple Application</h1>
                                <p className=" text-white xl:text-2xl lg:text-lg text-base font-normal md:pt-3 pt-2 new1" >
                                    Complete the short form answering questions about your ownership.
                                </p>
                            </div>
                            <div className="2xl:min-w-[444px] 2xl:max-w-[444px] h-full min-w-[326px]
                              bg-[#9FE870] rounded-3xl xl:px-[60px] px-[40px] xl:py-[39px] py-6 ml-7">
                                <div className="xl:w-[105px] xl:h-[105px] w-[100px] h-[100px] bg-white rounded-full 
                                flex justify-center items-center" >
                                    <img src='/assets/worldimage.png' className="w-auto" loading="lazy" alt="web image" />
                                </div>
                                <h1 className="xl:text-3xl lg:text-2xl text-xl lg:font-bold font-semibold text-[#163300]  mt-4">
                                    Decision Online</h1>
                                <p className=" text-lime-950 xl:text-2xl lg:text-lg text-base font-normal md:pt-3 pt-2 new1" >
                                    Get your decision online within 30 seconds and join the claim against DPF failures.
                                </p>
                            </div>
                        </Carousel>
                            
                    </div>
                </div>
            </div>
            <div className="lg:hidden block lg:px-0 md:px-[53px] px-6 lg:pb-0 md:pb-[53px] pb-7">
                <div className="lg:w-[294px] w-full lg:h-[274px] h-auto bg-[#9FE870] rounded-3xl
                 md:px-[31px] md:py-[28px] p-6 mx-auto mt-[16px] fade fadeOut">
                    <div className="w-[62px] h-[62px] flex justify-center bg-white rounded-full lg:mb-0 mb-2.5" >
                        <img src='/assets/search.png' className="p-3.5" loading="lazy" alt="search image"/>
                    </div>
                    <h1 className="text-xl font-semibold text-[#163300]">
                        Find Your Vehicle</h1>
                    <p className=" text-lime-950 text-sm font-normal new1" >
                        Just enter registration and we&apos;ll find and check your vehicle.
                    </p>
                    <AnimatedButton  handleClick={(e)=>vehicleCheckHandler(e)}
                        buttonName="Get Started"
                        className="w-[149px] sm:py-2.5 py-1 bg-lime-950 rounded-3xl text-white text-base font-bold  new1
                             mt-[16px]"></AnimatedButton>
                </div>
                <div className="lg:w-[294px] w-full lg:h-[274px] h-auto bg-[#00330B] rounded-3xl 
                md:px-[31px] md:py-[28px] p-6 mx-auto mt-[16px] fade fadeOut">
                    <div className="w-[62px] h-[62px] flex justify-center bg-white rounded-full lg:mb-0 mb-2.5" >
                        <img src='/assets/app.png' className="p-3.5" loading="lazy" alt="application image"/>
                    </div>
                    <h1 className="text-[#9FE870] text-xl font-semibold">
                        Simple Application</h1>
                    <p className=" text-white text-sm font-normal new1" >
                        Complete the short form answering questions about your ownership.
                    </p>
                </div>
                <div className="lg:w-[294px] w-full lg:h-[274px] h-auto bg-[#9FE870] rounded-3xl px-[31px] 
                py-[28px] mx-auto mt-[16px] fade fadeOut">
                    <div className="w-[62px] h-[62px] flex justify-center bg-white rounded-full lg:mb-0 mb-2.5" >
                        <img src='/assets/online.png' className="p-3.5" loading="lazy" alt="web image" />
                    </div>
                    <h1 className="text-xl font-semibold text-[#163300]">
                        Decision Online</h1>
                    <p className=" text-lime-950 text-sm font-normal new1" >
                        Get your decision online within 30 seconds and join the claim against DPF failures.
                    </p>
                </div>
            </div>
            <AnimatedDiv className="text-left 2xl:p-[120px] xl:p-[80px] md:p-[50px] sm:p-10 p-6 bg-[#00330B] fade fadeOut">
                <div className="text-[#9FE870] sm:flex hidden xl:text-[45px] xl:leading-[55px] lg:text-[41px] lg:leading-[46px] 
                    md:text-[35px] md:leading-[40px] sm:text-2xl text-xl tracking-[-2.15px] sm:tracking-[-0.8px] 
                    mb-5 font-[900] lg:w-[65%] w-auto fade fadeOut">
                    OWNED A VEHICLE IN THE LAST 10 YEARS THAT YOU BOUGHT ON FINANCE?
                </div>
                <div className="text-[#9FE870]  flex sm:hidden
                   text-[26px] leading-[30px] uppercase tracking-[-0.55px]  mb-5 font-[900]  w-auto new1 fade fadeOut">
                    you may be eligible to receive compensation ranging from £3,000 to £16,000
                </div>
                <div className="flex justify-end fade fadeOut">
                    <p className="text-white xl:text-2xl lg:text-xl sm:text-xl text-base font-medium new1
                    lg:w-[50%] w-auto xl:mb-10 lg:mb-8 mb-5">
                        Based on our investigations, we have discovered that the
                        design of the vehicles is flawed, resulting in potential safety and performance
                        problems for drivers while on the road.
                    </p>
                </div>
                <div className="rounded-2xl mb-5 fade fadeOut">
                    <img className="rounded-2xl w-full" src='/assets/claimImage-min.png' alt="claim" loading="lazy" />
                </div>
                <div className="fade fadeOut">
                    <div className=" flex-wrap lg:flex justify-between xl:mb-5 lg:mb-6 lg:mt-[60px] mt-6 ">
                        <div className="lg:w-[52%] text-white xl:text-2xl lg:text-xl new1
                    sm:text-xl text-base font-medium w-auto">
                            Additionally, these defects may lead to increased engine wear,
                            which could require customers to incur additional expenses for servicing,
                            including the possibility of engine replacement. It is important to note
                            that this issue is distinct from the previous “Diesel Gate” scandal.
                        </div>
                        <div className="text-[#FFF] font-bold flex justify-center lg:mt-0 mt-7 new1">
                            <AnimatedButton  handleClick={(e) => vehicleCheckStartHandler(e)} 
                                buttonName='Start Your Claim'
                                className="lg:w-[300px] lg:h-[70px] bg-[#9FE870] hover:bg-[#aafc74] text-[#163300]
                        py-2 px-6 rounded-full lg:text-2xl sm:text-xl text-base font-bold"
                            ></AnimatedButton>
                        </div>
                    </div>
                </div>
            </AnimatedDiv>
        </Element>
    )
}