import { motion } from 'framer-motion';
import React,{useState} from 'react'

const ErrorMessage = ({ message,className }) => {
    return (
        <motion.p
            transition={{ duration: 0.5 }}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className={className}
        >
            {message}
        </motion.p>
    );
};
export default ErrorMessage;
