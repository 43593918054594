import React from "react";

export default function Header(){
    return(
        <nav className="flex justify-between items-center px-[3%] sm:px-[5%] py-5 bg-[#fff] 
        shadow-md  ">
            <div>
                <img src="/assets/logo.png" alt="claimeo logo" loading="lazy" />
            </div>
            <div className="hidden sm:flex justify-end sm:mt-6 mt-3">
                <img src="/assets/secure-site.png" alt="secute-site image" loading="lazy" />
            </div> 
        </nav>
    );
}
